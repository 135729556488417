import {useMutation, useQueryClient} from 'react-query'
import {userApi} from '../../api-interface'
import {UserFullName} from '../../models'
import {currentUserInfoKey} from '../queries'

type UpdateUsersPayload = {
  userName: UserFullName
}

export function useUpdateUserName() {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: UpdateUsersPayload) => userApi.updateUserName(payload.userName),
    {
      onSettled() {
        queryClient.invalidateQueries(currentUserInfoKey)
      },
    }
  )
}
