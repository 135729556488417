import { Collapse } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { useEffect, useRef, useState } from 'react'
import { ProfileAndAppReg } from '../../../admin/tabs/reports/add-report-form/types'
import {
  StatusMessageInfo,
  WorkspaceDatasetInfo,
  WorkspaceInfo,
} from '../../../../models'
import { useAddProfileToWorkspace } from '../../../../hooks/mutations/useAddProfileToWorkspace'
import { useGetAppRegs, useGetProfileWorkspaces } from '../../../../hooks'
import { AccessLoadingBar, SkeletonFormField } from '../../../admin/tabs'
import { workspacesApi } from '../../../../api-interface/workspace-api'
import { FormField, StatusMessage } from '../../../admin/shared'

export type SelectAndAuthenticateReportProps = {
  profile: ProfileAndAppReg | null
  setProfile: (profile: ProfileAndAppReg) => void
  name: string
  setName: (value: string) => void
  description: string
  setDescription: (value: string) => void
  workspace: WorkspaceInfo | null
  setWorkspace: (workspace: WorkspaceInfo) => void
  status: StatusMessageInfo | null
  setStatus: (status: StatusMessageInfo) => void
  isGrantingAccess: boolean
  setIsGrantingAccess: (value: boolean) => void
  setIsLoading: (value: boolean) => void
  dataset: WorkspaceDatasetInfo | null
  setDataset: (dataset: WorkspaceDatasetInfo) => void
  setIsSaving: (value: boolean) => void
  onClose: () => void
  checkingDataset: boolean
  perspectives: string[]
  setPerspective: (value: string) => void
  perspective: string
}

const useStyles = makeStyles(
  () =>
    createStyles({
      statusMessage: {
        width: '100%',
      },
      rlsSection: {
        width: '100%',
      },
    }),
  { name: 'add-report-form' }
)

export function SelectAndAuthenticateReport(
  props: SelectAndAuthenticateReportProps
) {
  const {
    profile,
    setProfile,
    workspace,
    setWorkspace,
    status,
    setStatus,
    isGrantingAccess,
    checkingDataset,
    dataset,
    setDataset,
    setIsGrantingAccess,
    setIsLoading,
    setIsSaving,
    name,
    description,
    setName,
    perspectives,
    setDescription,
    setPerspective,
    perspective,
  } = props

  const classes = useStyles()

  const {
    mutateAsync: addProfileToWorkspace,
    isLoading: isAddingProfile,
    isError,
  } = useAddProfileToWorkspace()

  const { data: existingAppRegistrations, isLoading: loadingAppRegs } =
    useGetAppRegs()

  const [workspaceDatasets, setWorkspaceDatasets] = useState<
    WorkspaceDatasetInfo[]
  >([])
  const [loadingWorkspaceDatasets, setLoadingWorkspaceDatasets] =
    useState(false)
  const [profiles, setProfiles] = useState<ProfileAndAppReg[]>([])

  const {
    data: workspaces,
    isLoading: loadingWorkspaces,
    refetch: refetchWorkspaces,
    isRefetching: isRefetchingWorkspaces,
  } = useGetProfileWorkspaces(profile?.appRegNodeId)

  const addProfileAttempts = useRef(0)

  async function testConnectionToWorkspace() {
    await getWorkspaceDatasets()
  }

  useEffect(() => {
    addProfileAttempts.current = 0
  }, [profile?.id, workspace?.id])

  useEffect(() => {
    let tempProfiles: ProfileAndAppReg[] = []
    if (existingAppRegistrations) {
      existingAppRegistrations.forEach(appReg => {
        if (appReg.profiles) {
          appReg.profiles.forEach(profile => {
            tempProfiles.push({
              ...profile,
              appRegId: appReg.appRegistrationId,
              appRegName: appReg.name,
              appRegNodeId: appReg.id,
              tenantId: appReg.tenantId,
            })
          })
        }
      })
      setProfiles(tempProfiles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingAppRegistrations])

  useEffect(() => {
    if (!loadingAppRegs && !loadingWorkspaceDatasets && !loadingWorkspaces) {
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAppRegs, loadingWorkspaceDatasets, loadingWorkspaces])

  useEffect(() => {
    if ((!isAddingProfile && !isGrantingAccess) || checkingDataset || isError) {
      setIsSaving(false)
      return
    }
    setIsSaving(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingProfile, isGrantingAccess])

  useEffect(() => {
    if (!profile) return
    refetchWorkspaces()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  const getWorkspaceDatasets = async () => {
    setLoadingWorkspaceDatasets(true)
    try {
      const workspaceDatasetsResponse =
        await workspacesApi.getWorkspaceDatasets(
          profile.appRegNodeId,
          profile.appRegId,
          profile.tenantId,
          profile.id,
          workspace.id
        )

      setWorkspaceDatasets(workspaceDatasetsResponse)
      setStatus(null)
    } catch (e) {
      setStatus({
        title: 'Unauthorized',
        message:
          'The app registration and/or profile does not have access to the workspace',
        type: 'error',
      })
    } finally {
      setLoadingWorkspaceDatasets(false)
    }
  }

  useEffect(() => {
    if (!workspace) return

    getWorkspaceDatasets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace])

  useEffect(() => {
    if (workspace && profile) {
      testConnectionToWorkspace()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, workspace])

  const ConnectionProfileFormField = () => {
    if (loadingAppRegs) {
      return <SkeletonFormField />
    }

    return (
      <FormField
        label={
          !profiles || profiles.length === 0
            ? 'No profiles found'
            : 'Select Connection Profile'
        }
        value={profile?.name || ''}
        onTextChange={value => {
          setStatus(null)
          let selectedProfile = profiles?.find(
            profile => profile?.name === value
          )
          if (!selectedProfile) return
          setProfile(selectedProfile)
        }}
        disabled={!profiles || profiles.length === 0}
        selectOptions={profiles?.map(profile => profile?.name) || []}
      />
    )
  }

  const DatasetsFormField = () => {
    if (loadingWorkspaceDatasets) {
      return <SkeletonFormField />
    }

    const availableDatasets =
      !workspace || !profile || status?.title === 'Unauthorized'
        ? []
        : workspaceDatasets

    return (
      <FormField
        label={
          !availableDatasets || availableDatasets.length === 0
            ? 'No datasets found'
            : 'Select Dataset'
        }
        value={dataset?.name || ''}
        onTextChange={value => {
          setStatus(null)
          let selectedDataset = availableDatasets?.find(
            dataset => dataset?.name === value
          )
          if (!selectedDataset) return
          setDataset(selectedDataset)
        }}
        disabled={
          !profiles ||
          profiles.length === 0 ||
          !workspace ||
          status?.title === 'Unauthorized'
        }
        selectOptions={availableDatasets?.map(dataset => dataset?.name) || []}
      />
    )
  }

  const PerspectivesFormField = () => {
    return (
      <FormField
        label={'Perspectives'}
        value={perspective || ''}
        onTextChange={value => {
          setStatus(null)
          setPerspective(value)
        }}
        disabled={
          !profiles ||
          profiles.length === 0 ||
          !workspace ||
          status?.title === 'Unauthorized'
        }
        selectOptions={perspectives || []}
      />
    )
  }

  const addProfile = async () => {
    setIsGrantingAccess(true)
    setStatus(null)

    await addProfileToWorkspace({
      profileId: profile.id,
      workspaceId: workspace.id,
      appRegistrationNodeId: profile.appRegNodeId,
      appClientId: profile.appRegId,
      appRegistrationTenantId: profile.tenantId,
    })
    setIsGrantingAccess(false)
    addProfileAttempts.current++
    await testConnectionToWorkspace()
  }

  const WorkspaceFormField = () => {
    if (loadingWorkspaces || isRefetchingWorkspaces) {
      return <SkeletonFormField />
    }

    return (
      <FormField
        label='Workspace'
        disabled={!profile}
        value={workspace?.name || ''}
        helperText=''
        selectOptions={workspaces?.map(ws => ws.name) || []}
        onTextChange={value => {
          const selectedWorkspace = workspaces?.find(ws => ws.name === value)
          if (!selectedWorkspace) return
          setWorkspace({
            id: selectedWorkspace.id,
            name: selectedWorkspace.name,
            isReadOnly: selectedWorkspace.isReadOnly,
          })
        }}
      />
    )
  }

  const apiLoading = checkingDataset || isGrantingAccess || isAddingProfile

  return (
    <>
      <FormField
        label={'Name'}
        value={name}
        onTextChange={value => {
          setName(value)
        }}
      />
      <FormField
        label={'Description'}
        value={description}
        onTextChange={value => {
          setDescription(value)
        }}
      />
      <ConnectionProfileFormField />
      <WorkspaceFormField />
      <DatasetsFormField />
      {perspectives?.length > 0 && <PerspectivesFormField />}
      {!isError && (
        <AccessLoadingBar
          isGrantingAccess={isGrantingAccess}
          isTestingConnection={false}
          isAddingProfile={isAddingProfile}
          checkingDataset={checkingDataset}
        />
      )}
      {profile && workspace && !apiLoading && status && (
        <Collapse
          className={classes.statusMessage}
          in={status?.type === 'error' || isError}
        >
          <StatusMessage
            status={
              status || {
                type: 'error',
                message:
                  'The app registration and/or profile does not have access to the workspace',
                title: 'Unauthorized',
              }
            }
            selfFix={{
              isError: isError,
              callback:
                status?.title === 'No data found' ||
                addProfileAttempts.current > 0
                  ? null
                  : addProfile,
              isLoading: isAddingProfile,
            }}
          />
        </Collapse>
      )}
    </>
  )
}
