import {atom} from 'recoil'
import {createSessionStorageEffect} from './util'

export const licenseAtom = atom<string | null>({
  key: 'current-license-id',
  default: null,
  effects_UNSTABLE: [
    createSessionStorageEffect(
      'current-license-storage-id',
      window.localStorage
    ),
  ],
})
