import { Stack, Switch, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import CustomDialog from '../../pages/admin/shared/dialog/dialog'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'

function ReportSettingsDialog(props: ReportSettingsDialogProps) {
  const {
    open,
    onClose,
    filterPaneEnabled,
    showPageNavigation,
    setReportSettings,
    saving,
  } = props

  const [tempFilterPaneEnabled, setTempFilterPaneEnabled] =
    useState<boolean>(filterPaneEnabled)
  const [tempShowPageNavigation, setTempShowPageNavigation] =
    useState<boolean>(showPageNavigation)

  useEffect(() => {
    setTempFilterPaneEnabled(filterPaneEnabled)
    setTempShowPageNavigation(showPageNavigation)
  }, [filterPaneEnabled, showPageNavigation])

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={`Report View Settings`}
      loading={saving}
      primaryButtonProps={{
        children: 'Confirm',
        onClick: async () => {
          await setReportSettings({
            filterPaneEnabled: tempFilterPaneEnabled,
            showPageNavigation: tempShowPageNavigation,
          })
          onClose()
        },
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      <Stack
        direction='column'
        alignItems={'flex-start'}
        gap={2}
        sx={{ width: '100%' }}
        justifyContent={'flex-start'}
        ml={6}
      >
        <ToggleSetting
          icon={<FilterListOutlinedIcon />}
          title='Show Filter Pane'
          setState={setTempFilterPaneEnabled}
          state={tempFilterPaneEnabled}
        />
        <ToggleSetting
          icon={<DescriptionOutlinedIcon />}
          title='Show Page navigation'
          setState={setTempShowPageNavigation}
          state={tempShowPageNavigation}
        />
      </Stack>
    </CustomDialog>
  )
}

type ToggleSettingProps = {
  icon: React.ReactNode
  title: string
  setState: (state: boolean) => void
  state: boolean
}

function ToggleSetting(props: ToggleSettingProps) {
  const { icon, title, setState, state } = props

  return (
    <Stack
      direction='row'
      gap={6}
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{ width: '84%' }}
    >
      <Stack
        direction='row'
        gap={1}
        alignItems={'center'}
        justifyContent={'flex-start'}
      >
        {icon}
        <Typography fontSize={18} sx={{ fontWeight: 500 }} mt={0.5}>
          {title}
        </Typography>
      </Stack>
      <Switch checked={state} onChange={() => setState(!state)} />
    </Stack>
  )
}

type ReportSettingsDialogProps = {
  open: boolean
  onClose: () => void
  filterPaneEnabled: boolean
  showPageNavigation: boolean
  setReportSettings: (settings: any) => Promise<void>
  saving: boolean
}

export default ReportSettingsDialog
