import React from 'react'
import * as MaterialDesignIcons from '@meronex/icons/md'
import * as FontAwesomeIcons from '@meronex/icons/fa'
import * as WeatherIcons from '@meronex/icons/wi'
import {renderToStaticMarkup} from 'react-dom/server'

export type Icon = {
  name: string
  url: string
  element?: JSX.Element
  svgString: string
  color: string
  library?: string
}

export function getIcons() {
  const iconLibraries = [MaterialDesignIcons, WeatherIcons, FontAwesomeIcons]

  let icons: Icon[] = []

  iconLibraries.forEach(iconLibrary => {
    Object.keys(iconLibrary).forEach((iconName, index) => {
      // @ts-ignore
      const element = React.createElement(iconLibrary[iconName]) as any
      const svgString = renderToStaticMarkup(element)
      const url = formatSvgUrl(`data:image/svg+xml;utf8,${svgString}`)
      if (iconName.toLowerCase().includes('twotone')) return
      let formattedIconName = iconName.substring(2)
      formattedIconName = formattedIconName.replace(
        /([a-z])([A-Z0-9]+)|(\d+)([a-zA-Z]+)/g,
        '$1$3 $2$4'
      )

      icons.push({
        name: formattedIconName,
        url,
        element,
        svgString,
        color: '',
        library:
          iconLibrary === MaterialDesignIcons
            ? 'Material Design'
            : 'Weather Icons',
      })
    })
  })

  // filter out any icons that are not unique
  // const uniqueIcons = icons.filter(
  //   (icon, index, self) => index === self.findIndex((t) => t.name === icon.name)
  // )

  return icons
}

function formatSvgUrl(url: string) {
  // Check if the URL is a data URL with SVG content
  if (url.startsWith('data:image/svg+xml;utf8,')) {
    // Extract the SVG content from the URL
    let svgContent = url.slice('data:image/svg+xml;utf8,'.length)

    // Encode special characters in SVG content
    svgContent = encodeURIComponent(svgContent)

    // Return the formatted URL
    return `data:image/svg+xml;utf8,${svgContent}`
  }

  // Return the original URL if it's not a data URL with SVG content
  return url
}

export function filterIcons(
  icons: Icon[],
  searchString: string,
  filters: string[]
): Icon[] {
  const filteredIcons = handleFilter(icons, filters)
  if (searchString.length < 2) {
    return filteredIcons
  }
  const lowerCaseSearchString = searchString.toLowerCase()

  return filteredIcons.filter(icon =>
    icon.name.toLowerCase().includes(lowerCaseSearchString)
  )
}

export function handleFilter(icons: Icon[], filters: string[]) {
  if (filters.length === 0) return icons
  if (filters.length === 2) return icons
  // filter options are filled and outlined
  // check if the the name of the icon contains outlined, if it does it is an outlined icon otherwise it is a filled icon
  return icons.filter(icon => {
    if (filters.includes('outlined')) {
      return icon.name.toLowerCase().includes('outline')
    } else {
      return !icon.name.toLowerCase().includes('outline')
    }
  })
}
