import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { folderApi } from '../../api-interface'
import { Folder, FolderItem } from '../../models'
import { licenseAtom } from '../../state'
import { getFoldersKey } from '../queries'

type CreateFolderContext = {
  previousFolders: Folder[]
}

type CreateFolderPayload = {
  folderName: string
  folderDescription?: string
  items?: FolderItem[]
  isFavorites?: boolean
}

export function useCreateFolder() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (payload: CreateFolderPayload) =>
      folderApi.createFolder({ ...payload, licenseId }),
    {
      async onMutate(
        newFolder: CreateFolderPayload
      ): Promise<CreateFolderContext> {
        await queryClient.cancelQueries([getFoldersKey, licenseId])

        let previousFolders: Folder[] = queryClient.getQueryData([
          getFoldersKey,
          licenseId,
        ])

        queryClient.setQueryData(
          [getFoldersKey, licenseId],
          (old: Folder[]) => {
            if (old)
              return [
                ...old,
                {
                  ...newFolder,
                  name: newFolder.folderName,
                  id: newFolder.folderName,
                },
              ]

            return old
          }
        )

        return { previousFolders }
      },
      onError(_err, _newFolder, context: CreateFolderContext) {
        queryClient.setQueryData(
          [getFoldersKey, licenseId],
          context.previousFolders
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getFoldersKey, licenseId])
      },
    }
  )
}
