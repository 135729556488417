import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {paypalApi} from '../../api-interface'
import {licenseAtom} from '../../state/licenseAtom'
import {getLicenseQueryKey} from '../queries'

type SetPaypalSubscriptionPayload = {
  status: 'SUSPENDED' | 'ACTIVE'
}
export function useSetPaypalSubscription() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (payload: SetPaypalSubscriptionPayload) =>
      paypalApi.setPaypalLicenseStatus(licenseId, payload.status),
    {
      onSettled() {
        queryClient.invalidateQueries([getLicenseQueryKey, licenseId])
      },
    }
  )
}
