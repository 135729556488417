import {createTheme} from '@mui/material'
import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {themeApi} from '../../api-interface'
import {PowerBIShareTheme} from '../../models'
import {licenseAtom} from '../../state'
import {getThemeKey} from '../queries'

type CreateThemeContext = {
  previousTheme: PowerBIShareTheme
}

export function useCreateTheme() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(themeApi.createTheme, {
    async onMutate(newTheme): Promise<CreateThemeContext> {
      await queryClient.cancelQueries([getThemeKey, licenseId])

      let previousTheme: PowerBIShareTheme = queryClient.getQueryData([
        getThemeKey,
        licenseId,
      ])

      queryClient.setQueryData([getThemeKey, licenseId], () =>
        createTheme(newTheme.theme)
      )

      // Return the context passed on onError
      return {previousTheme}
    },
    onError(_err, _newTheme, context: CreateThemeContext) {
      queryClient.setQueryData([getThemeKey, licenseId], context.previousTheme)
    },
    onSettled() {
      queryClient.invalidateQueries([getThemeKey, licenseId])
    },
  })
}
