import {useEffect, useState} from 'react'
import LinearProgress from '@mui/material/LinearProgress'

type ProgressBarProps = {
  userCount: number
  reports: boolean
  loading: boolean
}

const SECONDS_PER_USER_WITH_REPORTS = 0.03
const SECONDS_PER_USER_WITHOUT_REPORTS = 0.02

export function ExportUsersProgressBar(props: ProgressBarProps) {
  const {userCount, reports, loading} = props
  const [progress, setProgress] = useState(0)
  const [loadingStarted, setLoadingStarted] = useState(false)

  useEffect(() => {
    let totalTime

    if (reports) {
      totalTime = SECONDS_PER_USER_WITH_REPORTS * userCount
    } else {
      totalTime = SECONDS_PER_USER_WITHOUT_REPORTS * userCount
    }
    if (totalTime < 15) {
      totalTime = 15
    }
    if (!loading) {
      return
    }
    let timeLeft = totalTime
    const interval = setInterval(() => {
      timeLeft -= 100
      let newProgress = (totalTime - timeLeft) / totalTime
      if (newProgress >= 95) {
        newProgress = 95
      }
      setProgress(newProgress)
    }, 500)

    return () => clearInterval(interval)
  }, [userCount, reports, loading])

  useEffect(() => {
    if (loading && !loadingStarted) {
      setLoadingStarted(true)
      setProgress(0)
    }

    // When loading switches from true to false, set progress to 100 to show completion over the span of a second
    if (!loading && loadingStarted) {
      const progressLeft = 100 - progress
      const progressIncrements = progressLeft / 5
      let newProgress = progress

      const interval = setInterval(() => {
        newProgress += progressIncrements
        setProgress(newProgress)
      }, 200)

      setTimeout(() => {
        clearInterval(interval)
        setProgress(100)
        setTimeout(() => {
          setLoadingStarted(false)
        }, 500)
      }, 1300)

      return () => clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <>
      {(loading || loadingStarted) && (
        <LinearProgress
          variant='determinate'
          value={progress}
          sx={{
            mb: 1,
            p: 1,
            borderRadius: '4px',
            transform: 'scaleY(0.3)',
          }}
          color='secondary'
        />
      )}
    </>
  )
}
