import {TabLayout} from '../../shared'
import {SubscriptionList} from './subscription-list'

export function SubscriptionPage() {
  //Display Current License plan
  //Allow user to upgrade/downgrade plan (#of users and/or monthly/yearly)
  return (
    <TabLayout tabTitle={'Subscriptions'}>
      <SubscriptionList />
    </TabLayout>
  )
}
