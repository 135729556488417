import {Group, User} from '../models'
import {getAxios, endpoints, getUrl} from './util'

export const groupApi = {
  async createGroup(params: {
    groupName: string
    licenseId: string
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.groups,
      data: params,
    })
  },

  async editGroup(params: {
    groupId: string
    groupName: string
    licenseId: string
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'patch',
      url: endpoints.groups,
      data: params,
    })
  },

  async deleteGroup(groupId: string, licenseId: string): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.groups,
      data: {groupId, licenseId},
    })
  },

  async getGroups(licenseId: string): Promise<Group[]> {
    const axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: getUrl(endpoints.groups, {licenseId}),
    })

    return response.data.payload as Group[]
  },

  async getGroupUsers(groupId: string, licenseId: string): Promise<User[]> {
    const axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: getUrl(endpoints.groupUsers, {groupId, licenseId}),
    })

    return response.data.payload as User[]
  },

  async addGroupUsers(
    groupId: string,
    userIds: string[],
    licenseId: string
  ): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.groupUsers,
      data: {groupId, userIds, licenseId},
    })
  },

  async addUserToGroups(
    groupIds: string[],
    userId: string,
    licenseId: string
  ): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.userGroups,
      data: {groupIds, userId, licenseId},
    })
  },

  async removeGroupUser(
    groupId: string,
    userId: string,
    licenseId: string
  ): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.groupUsers,
      data: {groupId, userId, licenseId},
    })
  },
}
