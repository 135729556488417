//TODO: Figure out what the other data types are and add them to the switch statement
export const dataTypeToString = (dataType: number) => {
  switch (dataType) {
    case 2:
      return 'text'
    case 6:
      return 'wholeNumber'
    case 9:
      return 'dateTime'
    default:
      return 'text'
  }
}


export const getAdvancedFilterOptionsByDataType = (dataType: number) => {
  switch (dataType) {
    case 2:
      return [
        'contains',
        'does not contain',
        'starts with',
        'ends with',
        'is',
        'is not',
        'is blank',
        'is not blank',
        'is empty',
        'is not empty',
      ]
    case 6:
      return [
        'is less than',
        'is greater than',
        'is less than or equal to',
        'is greater than or equal to',
        'is',
        'is not',
        'is blank',
        'is not blank',
      ]
    case 8:
      return [
        'is less than',
        'is greater than',
        'is less than or equal to',
        'is greater than or equal to',
        'is',
        'is not',
        'is blank',
        'is not blank',
      ]
    case 9:
      return [
        'is before',
        'is after',
        'is on or before',
        'is on or after',
        'is',
        'is not',
        'is blank',
        'is not blank',
      ]
  }
}
