import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {userApi} from '../../api-interface'
import {License, NewUser, User} from '../../models'
import {getCurrentUserEmail} from '../../components/auth/util'
import {licenseAtom} from '../../state'
import {getLicenseQueryKey, getOtherUsersOnLicenseKey} from '../queries'

type CreateUserContext = {
  previousUsers: User[]
}

type CreateUsersPayload = {
  users: NewUser[]
  reportIds?: string[]
}

export function useCreateUser() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (payload: CreateUsersPayload) =>
      userApi.createUser(payload.users, licenseId, payload.reportIds || []),
    {
      async onMutate(newUser: CreateUsersPayload): Promise<CreateUserContext> {
        await queryClient.cancelQueries([getOtherUsersOnLicenseKey, licenseId])

        let currentUserEmail = await getCurrentUserEmail()
        let previousUsers: User[] = queryClient.getQueryData([
          getOtherUsersOnLicenseKey,
          licenseId,
        ])

        const currentDate = new Date().toISOString()

        let newUsers =
          newUser.users?.map((user: NewUser) => ({
            ...user,
            id: user.email,
            adminEmail: currentUserEmail,
            createdDate: currentDate,
          })) || []

        queryClient.setQueryData(
          [getOtherUsersOnLicenseKey, licenseId],
          (old: User[]) => [
            ...old,
            ...newUsers.filter((user: NewUser) => {
              return !old?.some((u: User) => u.email === user.email)
            }),
          ]
        )

        queryClient.setQueryData(
          [getLicenseQueryKey, licenseId],
          (old: License) => ({
            ...old,
            currentUserCount: old?.currentUserCount + 1 || 1,
          })
        )

        return {previousUsers}
      },
      onError(_err, _newUser, context: CreateUserContext) {
        queryClient.setQueryData(
          [getOtherUsersOnLicenseKey, licenseId],
          context.previousUsers
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getLicenseQueryKey, licenseId])
        queryClient.invalidateQueries([getOtherUsersOnLicenseKey, licenseId])
      },
    }
  )
}
