import { FC } from 'react'
import {
  useGetWorkspaceUsers,
  useGetOtherUsersOnLicense,
} from '../../../../hooks'
import { useAddUsersToWorkspace } from '../../../../hooks/mutations/useAddUsersToWorkspace'
import { Workspace, User } from '../../../../models'
import { LinkItemsMenu } from '../../shared'
import { Stack, Switch, Typography } from '@mui/material'
import { parseBoolean } from '../../../../utils/parseBoolean'

type LinkUsersMenuProps = {
  workspace: Workspace
  shareWithLicensedUsers: boolean
  onShareWithLicensedUsersChange: (value: boolean) => void
}

export const LinkUsersMenu: FC<LinkUsersMenuProps> = (
  props: LinkUsersMenuProps
) => {
  const { workspace, shareWithLicensedUsers, onShareWithLicensedUsersChange } =
    props
  const { data: users } = useGetOtherUsersOnLicense()
  const { data: linkedUsers } = useGetWorkspaceUsers(workspace.id)
  const { mutate: addUsers } = useAddUsersToWorkspace()

  const linkedUsersNotLoaded = linkedUsers
    ?.map((u: User) => {
      return !!(u?.firstName && u?.lastName && u?.email)
    })
    .some((u: boolean) => u === false)

  const linkedUserIds =
    (linkedUsers &&
      !linkedUsersNotLoaded &&
      linkedUsers.map((u: User) => u.email)) ||
    []
  const usersAvailableToAdd =
    (users &&
      linkedUserIds &&
      users.filter((u: User) => !linkedUserIds.includes(u.email))) ||
    []

  const linkUsers = (userIds: string[]) => {
    addUsers({
      workspaceId: workspace.id,
      userIds: userIds,
    })
  }

  

  let isSharedWithLicense = false
  try {
    isSharedWithLicense = !!parseBoolean(shareWithLicensedUsers)
  } catch (e) {
    console.error(e)
  }
  

  return (
    <Stack
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      <Stack
        direction='row'
        alignItems='center'
        sx={{
          ml: 1,
        }}
      >
        <Typography fontSize={'14px'}>Share to all users</Typography>
        <Switch
          checked={isSharedWithLicense}
          onChange={e => {
            e.preventDefault()
            onShareWithLicensedUsersChange(e.target.checked)
          }}
        />
      </Stack>
      {!isSharedWithLicense && (
        <LinkItemsMenu
          onConfirm={linkUsers}
          addButtonLabel='Add Users'
          placeHolderText='Search users'
          availableItems={
            (usersAvailableToAdd &&
              usersAvailableToAdd.map((u: User) => {
                return {
                  id: u.email,
                  name: `${u.firstName} ${u.lastName}`,
                  username: u.email,
                }
              })) ||
            []
          }
        />
      )}
    </Stack>
  )
}
