import {useState} from 'react'
import {
  Box,
  CircularProgress,
  Stack,
  Theme,
  Typography,
  Divider,
} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'
import {LinkedItemChip, LinkedItemChips} from '../../shared'
import {ReportData} from '../../../../models'
import {NoResultsText} from '../../../../components'
import {
  useRemoveViewer,
  useGetAppSettings,
  useGetReportViewers,
} from '../../../../hooks'
import {SearchAndPage} from '../../shared/existing-items/search-and-page'

const PAGE_SIZE = 10

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

const UsersList = ({users, onDelete}) => {
  return (
    <LinkedItemChips>
      {users.map(user => (
        <LinkedItemChip
          key={user.id}
          label={`${user.firstName} ${user.lastName}${
            user.email ? ` (${user.email})` : ''
          }`}
          onDelete={() => onDelete(user.id)}
        />
      ))}
    </LinkedItemChips>
  )
}

const GroupsList = ({groups, onDelete}) => {
  return (
    <LinkedItemChips>
      {groups.map(group => (
        <LinkedItemChip
          key={group.id}
          label={group.name}
          onDelete={() => onDelete(group.id)}
        />
      ))}
    </LinkedItemChips>
  )
}

export function LinkedViewers(props: LinkedViewersProps) {
  const {report} = props
  const classes = useStyles()
  const [userSearchQuery, setUserSearchQuery] = useState('')
  const [groupSearchQuery, setGroupSearchQuery] = useState('')
  const [userPage, setUserPage] = useState(1)
  const [groupPage, setGroupPage] = useState(1)

  const {data: appSettings} = useGetAppSettings()
  const {mutate: removeViewer} = useRemoveViewer()
  const {
    data: {users: linkedUsers, groups: linkedGroups} = {users: [], groups: []},
    isLoading: loadingViewers,
  } = useGetReportViewers(report.id)

  const filteredUsers =
    linkedUsers?.filter(
      user =>
        user.firstName.toLowerCase().includes(userSearchQuery.toLowerCase()) ||
        user.lastName.toLowerCase().includes(userSearchQuery.toLowerCase()) ||
        (user.email &&
          user.email.toLowerCase().includes(userSearchQuery.toLowerCase()))
    ) || []

  const filteredGroups =
    linkedGroups?.filter(group =>
      group.name.toLowerCase().includes(groupSearchQuery.toLowerCase())
    ) || []

  const usersTotalPages = Math.ceil(filteredUsers.length / PAGE_SIZE)
  const groupsTotalPages = Math.ceil(filteredGroups.length / PAGE_SIZE)

  const paginatedUsers = filteredUsers.slice(
    (userPage - 1) * PAGE_SIZE,
    userPage * PAGE_SIZE
  )
  const paginatedGroups = filteredGroups.slice(
    (groupPage - 1) * PAGE_SIZE,
    groupPage * PAGE_SIZE
  )

  function handleDelete(_type, id) {
    removeViewer({reportId: report.id, viewerId: id})
  }

  function handleUserNextPage() {
    setUserPage(prevPage => prevPage + 1)
  }

  function handleUserPrevPage() {
    setUserPage(prevPage => Math.max(prevPage - 1, 1))
  }

  function handleGroupNextPage() {
    setGroupPage(prevPage => prevPage + 1)
  }

  function handleGroupPrevPage() {
    setGroupPage(prevPage => Math.max(prevPage - 1, 1))
  }

  if (loadingViewers) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    )
  }

  const handleUserSearch = searchText => {
    setUserSearchQuery(searchText)
    setUserPage(1) // Reset page to 1 when searching
  }

  const handleGroupSearch = searchText => {
    setGroupSearchQuery(searchText)
    setGroupPage(1) // Reset page to 1 when searching
  }

  const renderUserSearchAndPage = () => {
    return (
      <SearchAndPage
        show={linkedUsers.length > 0}
        pageNumber={userPage}
        totalPagesAvailable={usersTotalPages}
        placeHolderText='Search Users'
        onSearch={handleUserSearch}
        onPrevPage={handleUserPrevPage}
        onNextPage={handleUserNextPage}
      />
    )
  }

  const renderGroupSearchAndPage = () => {
    return (
      <SearchAndPage
        show={linkedGroups.length > 0}
        pageNumber={groupPage}
        totalPagesAvailable={groupsTotalPages}
        placeHolderText='Search Groups'
        onSearch={handleGroupSearch}
        onPrevPage={handleGroupPrevPage}
        onNextPage={handleGroupNextPage}
      />
    )
  }

  return (
    <Stack
      justifyContent='space-between'
      direction='column'
      sx={{width: '100%'}}
    >
      <Box sx={{px: 2, mt: 1.5}}>
        <Typography variant='subtitle1' sx={{fontWeight: 700}}>
          Users
        </Typography>
        <Divider sx={{mb: 1}} />
        {renderUserSearchAndPage()}
        {linkedUsers.length === 0 ? (
          <NoResultsText>
            This report has not been shared with any users
          </NoResultsText>
        ) : (
          <UsersList
            users={paginatedUsers}
            onDelete={handleDelete.bind(null, 'user')}
          />
        )}
      </Box>

      {appSettings.groupsEnabled && (
        <Box sx={{px: 2, mt: 1.5}}>
          <Typography variant='subtitle1' sx={{fontWeight: 700}}>
            Groups
          </Typography>
          <Divider sx={{mb: 1}} />
          {renderGroupSearchAndPage()}
          {linkedGroups.length === 0 ? (
            <NoResultsText>
              This report has not been shared with any groups
            </NoResultsText>
          ) : (
            <GroupsList
              groups={paginatedGroups}
              onDelete={handleDelete.bind(null, 'group')}
            />
          )}
        </Box>
      )}
    </Stack>
  )
}

export type LinkedViewersProps = {
  report: ReportData
}
