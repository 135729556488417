import { Stack, Typography } from '@mui/material'
// import Grid from '@mui/material/Grid2'

import { ReportTiles } from './report-tiles'
import {
  useGetLicense,
  useCurrentUserInfo,
  useGetReports,
  useGetFolders,
} from '../../../hooks'
import { Redirect } from 'react-router-dom'
import { routes } from '../../../routes'
import { isOnlyOneUniqueItem } from '../util'
import { Layout } from '../../../components/layout'
import { FullPageLoader } from '../../../components/common'
import { getLicenseState } from '../../../models'
import { useEffect, useState } from 'react'
import { ReportDashboardSkeleton } from './report-dashboard-skeleton'
import { useSetRecoilState } from 'recoil'
import { bookmarkAtom } from '../../../state'
import { Folders } from './folders'

export function ReportDashboard() {
  const { data: license, isLoading: licenseLoading } = useGetLicense()
  const { data: reports, isLoading: reportsLoading } = useGetReports()
  const {
    data: {
      user: { email: currentUserEmail },
    },
  } = useCurrentUserInfo()
  const { data: folders } = useGetFolders()

  const [skeletonNumber, setSkeletonNumber] = useState(6)

  const myReports = reports?.ownedReports || []
  const sharedReports = reports?.sharedReports || []
  const defaultReports = reports?.defaultReports || []
  const allReports = [...myReports, ...sharedReports, ...defaultReports]

  const setBookmarkState = useSetRecoilState(bookmarkAtom)

  const defaultNotOwnedReports = defaultReports.filter(
    report =>
      myReports.find(myReport => myReport.id === report.id) === undefined
  )
  const sharedWithMeReports = [
    ...new Map(
      [...sharedReports, ...defaultNotOwnedReports].map(item => [
        item['name'],
        item,
      ])
    ).values(),
  ]

  // Check License validity
  const validLicense = getLicenseState(license).valid

  useEffect(() => {
    //Check if we have the number of reports in local storage
    const reportsInLocalStorage = localStorage.getItem('dashboardReports')
    if (reportsInLocalStorage) {
      setSkeletonNumber(parseInt(reportsInLocalStorage))
    }

    setBookmarkState({
      panelOpen: false,
      resetReportFilters: false,
      selectedBookmark: null,
      selectedBookmarkFilters: null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (reportsLoading || !allReports) return
    localStorage.setItem('dashboardReports', JSON.stringify(allReports.length))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsLoading])

  if (licenseLoading) {
    return <FullPageLoader />
  }

  // We are done loading
  if (isOnlyOneUniqueItem(allReports.map(r => r.id)) && validLicense) {
    return (
      <Redirect
        to={routes.reports.report({
          id: allReports[0].id,
        })}
      />
    )
  }

  return (
    <Layout>
      {reportsLoading ? (
        <ReportDashboardSkeleton numberOfItems={skeletonNumber} />
      ) : (
        <Stack
          sx={{
            m: 'auto',
            mb: 8,
            width: '100%',
            px: { xs: 3.5, sm: 4.5 },
          }}
          gap={8}
          alignItems='center'
        >
          {!validLicense && (
            <>
              <Typography sx={{ mt: 10 }} variant='h6'>
                {`Your License expired on: ${license.subscriptionEndDate}. Please contact your license owner to reactivate the license.`}
              </Typography>
            </>
          )}
          {validLicense && (
            <>
              {myReports.length === 0 && sharedWithMeReports.length === 0 && (
                <Typography sx={{ mt: 10 }} variant='h6'>
                  No reports to display
                </Typography>
              )}
              {myReports.length > 0 && (
                <ReportTiles
                  title='My Reports'
                  subtitle={currentUserEmail}
                  isLoading={reportsLoading}
                  reports={myReports}
                  folders={folders}
                />
              )}
            </>
          )}

          <Folders folders={folders} />
        </Stack>
      )}
    </Layout>
  )
}
