import {useMemo} from 'react'
import {routes} from '../routes'
import {useUrlParams, urlParamNames, UrlParams} from './use-url-params'

/**
 * Get the full, correct URL for a particular route in our app which includes
 * the company name if applicable.
 */
export function useRoutes() {
  const params = useUrlParams()
  return useMemo(() => createRoutesWithCurrentUrlParams(params), [params])
}

/**
 * Take all routes in baseRoutes and fill in any and all URL param
 * placeholders that they contain.
 */
function createRoutesWithCurrentUrlParams(urlParamValues: UrlParams) {
  return Object.entries(routes).reduce((accumRoot, [rootKey, paths]) => {
    accumRoot[rootKey] = Object.entries(paths).reduce(
      (accumInner, [innerKey, path]) => {
        if (typeof path === 'string') {
          accumInner[innerKey] = fillInUrlPlaceholders(path, urlParamValues)
        } else if (typeof path === 'function') {
          accumInner[innerKey] = arg =>
            fillInUrlPlaceholders(path(arg), urlParamValues)
        }
        return accumInner
      },
      {}
    )

    return accumRoot
  }, {}) as typeof routes
}

function fillInUrlPlaceholders(
  urlWithPlaceholders: string,
  urlParamValues: UrlParams
) {
  let path = urlWithPlaceholders

  urlParamNames.forEach(
    paramName =>
      (path = path.replace(':' + paramName, urlParamValues[paramName]))
  )

  return path
}
