import {
  Box,
  CircularProgress,
  LinearProgress,
  Stack,
  Theme,
  Typography,
  Divider,
} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'
import {LinkedItemChip, LinkedItemChips, LinkItemsMenu} from '../../shared'
import {ApiGroupReport, ApiReport, Group, User} from '../../../../models'
import {NoResultsText} from '../../../../components'
import {
  useRemoveReportFromViewer,
  useGetReportsByUser,
  useGetAppSettings,
  useRemoveGroupFromUser,
  useAddReportsToUser,
  useAddGroupsToUser,
  useGetGroups,
  useGetReports,
} from '../../../../hooks'
import {v4 as uuidv4} from 'uuid'

import {useGetUserGroups} from '../../../../hooks/queries/useGetUserGroups'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

export type LinkedUserItemsProps = {
  user: User
}

export function LinkedUserItems(props: LinkedUserItemsProps) {
  const {user} = props
  const classes = useStyles()

  /* ADD */
  const {mutateAsync: addReportsToUser, isLoading: addReportsToUserLoading} =
    useAddReportsToUser()
  const {mutateAsync: addGroup, isLoading: addGroupsToUserLoading} =
    useAddGroupsToUser()

  /* REMOVE */
  const {mutateAsync: removeViewer, isLoading: isRemovingReportLoading} =
    useRemoveReportFromViewer()
  const {
    mutateAsync: removeUserFromGroup,
    isLoading: isRemoveGroupUserLoading,
  } = useRemoveGroupFromUser()

  /* GET */
  const {data: appSettings} = useGetAppSettings()
  const {data: userReports, isLoading: loadingReports} = useGetReportsByUser(
    user.id
  )
  const {data: userGroups, isLoading: loadingGroups} = useGetUserGroups(user.id)
  const {data: allGroups} = useGetGroups()
  const {data: reports} = useGetReports()

  /*SHOW LOADING */
  const showReportsLoading =
    loadingReports || isRemovingReportLoading || addReportsToUserLoading
  const showGroupsLoading =
    loadingGroups || isRemoveGroupUserLoading || addGroupsToUserLoading

  /*LINKED ITEMS */
  const reportsFromGroups = userReports?.reportsFromGroups || []
  const reportsFromUser = userReports?.reportsFromUser || []
  const allReports = reports?.licenseReports || []
  const reportsAvailableToAdd =
    allReports?.filter(r => !reportsFromUser.map(r => r.id).includes(r.id)) ||
    []

  const linkedGroupIds = userGroups?.map(g => g.id) || []
  const groupsAvailableToAdd =
    allGroups?.filter(g => !linkedGroupIds.includes(g.id)) || []

  async function handleDelete(reportId: string) {
    await removeViewer({reportId, viewerId: user.id})
  }

  async function linkReports(reportIds: string[]) {
    await addReportsToUser({reportIds, viewerId: user.id})
  }

  async function linkGroups(groupIds: string[]) {
    await addGroup({
      userId: user.id,
      groupIds: groupIds,
    })
  }

  if (loadingGroups || loadingReports) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <Stack
      justifyContent='space-between'
      direction='column'
      sx={{width: '100%'}}
      key={user.id + uuidv4()}
    >
      <UserLinkedItems
        title='Reports'
        notFoundText='This user has not been given access to any reports directly'
        items={reportsFromUser}
        menu={
          <LinkItemsMenu
            onConfirm={linkReports}
            addButtonLabel='Add Reports'
            placeHolderText='Search reports'
            availableItems={
              reportsAvailableToAdd &&
              reportsAvailableToAdd.map(r => {
                return {
                  id: r.id,
                  name: r.name,
                  username: r.name,
                }
              })
            }
          />
        }
        handleDelete={(report: ApiReport) => handleDelete(report.id)}
        isLoading={showReportsLoading}
      />

      {appSettings?.groupsEnabled && (
        <>
          <UserLinkedItems
            title='Reports from Groups'
            notFoundText='This user is not a member of any groups that have shared reports'
            items={reportsFromGroups}
            isLoading={showGroupsLoading}
          />

          <UserLinkedItems
            title='Groups'
            notFoundText='This user is not a member of any groups'
            items={userGroups}
            menu={
              <LinkItemsMenu
                onConfirm={linkGroups}
                addButtonLabel='Add Groups'
                placeHolderText='Search groups'
                availableItems={
                  groupsAvailableToAdd &&
                  groupsAvailableToAdd.map(g => {
                    return {
                      id: g.id,
                      name: g.name,
                    }
                  })
                }
              />
            }
            handleDelete={(group: Group) =>
              removeUserFromGroup({groupId: group.id, userId: user.id})
            }
            isLoading={showGroupsLoading}
          />
        </>
      )}
    </Stack>
  )
}

export type UserItemsProps = {
  title: string
  notFoundText: string
  items: ApiReport[] | Group[] | ApiGroupReport[]
  handleDelete?: (item: any) => void
  isLoading: boolean
  menu?: JSX.Element
}

function UserLinkedItems(props: UserItemsProps) {
  const {title, handleDelete, notFoundText, items, isLoading, menu} = props

  return (
    <Box
      sx={(theme: Theme) => ({
        px: theme.spacing(2),
        mt: theme.spacing(1.5),
      })}
    >
      <Stack direction='row' gap={1} alignItems='baseline'>
        <Typography variant='subtitle1' sx={{fontWeight: 700}}>
          {title}
        </Typography>
        {menu}
      </Stack>

      {isLoading && (
        <Box sx={{mb: (theme: Theme) => theme.spacing(1), w: '100%'}}>
          <LinearProgress />
        </Box>
      )}
      {!isLoading && <Divider sx={{mb: (theme: Theme) => theme.spacing(1)}} />}
      {items.length === 0 && <NoResultsText>{notFoundText}</NoResultsText>}
      <LinkedItemChips>
        {items.map((item: ApiReport | Group) => (
          <LinkedItemChip
            key={`${item.id} ${uuidv4()}`}
            // if the item is a report from a group, we need to show the group name
            label={
              (item as ApiGroupReport).groupName
                ? `${item.name} | ${(item as ApiGroupReport).groupName}`
                : `${item.name}`
            }
            onDelete={
              handleDelete
                ? () => {
                    handleDelete(item)
                  }
                : undefined
            }
          />
        ))}
      </LinkedItemChips>
    </Box>
  )
}
