import {useMutation, useQueryClient} from 'react-query'
import {reportApi} from '../../api-interface'
import {AllReports} from '../../models'
import {getReportsKey} from '../queries'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'

type CreateReportContext = {
  previousReports: AllReports
}

export function useUpdateReport() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(reportApi.updateReport, {
    async onMutate(newReport): Promise<CreateReportContext> {
      await queryClient.cancelQueries([getReportsKey, licenseId])

      let previousReports: AllReports = await queryClient.getQueryData(
        [getReportsKey, licenseId]
      )
      // Have to destructure the ApiReport out of the ReportToCreate
      const {appRegistrationNodeId, viewers, ...newApiReport} = newReport
      queryClient.setQueryData([getReportsKey, licenseId], (old: AllReports) => {
        if (!old) {
          return {
            ownedReports: [],
            sharedReports: [],
            defaultReports: [],
            licenseReports: [],
          }
        }
        return {
          ownedReports: [...old.ownedReports, newApiReport],
          sharedReports: old.sharedReports,
          defaultReports: old.defaultReports,
          licenseReports: old.licenseReports,
        }
      })

      // Return the context passed on onError
      return {previousReports}
    },
    onError(err, newUser, context: CreateReportContext) {
      queryClient.setQueryData([getReportsKey, licenseId], context.previousReports)
    },
    onSettled() {
      queryClient.invalidateQueries([getReportsKey, licenseId])
    },
  })
}
