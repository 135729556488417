import { Box, Stack, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useParams, useHistory } from 'react-router-dom'
import { PbiReport, SaveEvent } from './pbi-report'
import {
  useGetBookmarks,
  useGetLicense,
  useGetReports,
  useCloneReport,
  // useGetAppRegs,
} from '../../../hooks'
import { isOnlyOneUniqueItem } from '../util'
import { FullPageLoader, Layout } from '../../../components'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { BookmarkSelector } from '../bookmarks/bookmark-selector'
import { useRecoilState } from 'recoil'
import { bookmarkAtom } from '../../../state'
import { ErrorPage } from './error-page'
import BookmarkDialog from '../bookmarks/bookmark-dialog'
import { ReportOptionsBar } from './report-options-bar'
import {
  ResizableDrawer,
  ResizeState,
} from '../../admin/shared/resizable-drawer'
import { CustomReportTable } from '../create-custom-report/custom-report-table'

const useStyles = makeStyles((theme: Theme) => ({
  toolBar: { display: 'flex', justifyContent: 'space-between' },

  drawerPaper: {
    zIndex: 8,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  },

  embedWrapper: {
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 40px)',
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 64px)',
    },
  },

  embeddedReport: {
    '& iframe': {
      border: 'none',
    },

    flex: 1,
  },
  disablePointerEvents: {
    pointerEvents: 'none',
    flex: 1,
    filter: 'blur(2px)',
    '& iframe': {
      border: 'none',
    },
  },
}))

export function ReportView() {
  const classes = useStyles()
  const routeParams = useParams<any>()
  const reportId = routeParams.id
  const bookmarkId = routeParams.bookmark
  const { data: reports, isLoading, refetch: reloadReports } = useGetReports()
  const { data: bookmarks, isLoading: bookmarksLoading } =
    useGetBookmarks(reportId)
  const { data: license, isLoading: licenseLoading } = useGetLicense()
  const [bookmarkState, setBookmarkState] = useRecoilState(bookmarkAtom)

  const ownedReports = reports?.ownedReports || []
  const sharedReports = reports?.sharedReports || []
  const defaultReports = reports?.defaultReports || []
  const allReports = [...ownedReports, ...sharedReports, ...defaultReports]

  const [bookmarkToEdit, setBookmarkToEdit] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  // const { data: appRegs, isLoading: loadingAppRegs } = useGetAppRegs()
  // const [selectedAppReg, setSelectedAppReg] = useState(null)

  const [editMode, setEditMode] = useState(false)
  const toggleEditMode = () => {
    setEditMode(o => !o)
  }

  const selectedReport =
    allReports?.find(report => report.id === reportId) || null

  const [showCopyDialog, setShowCopyDialog] = useState(false)
  const [copyName, setCopyName] = useState(null)

  const [isCloning, setIsCloning] = useState(false)

  const [resizableState, setResizableState] = useState<ResizeState>({
    isResizing: false,
    lastDownX: 0,
    newWidth: { width: 260 },
  })

  const [isResizing, setIsResizing] = useState(false)

  useEffect(() => {
    setIsResizing(true)

    setTimeout(() => {
      setIsResizing(false)
    }, 300)
  }, [bookmarkState?.panelOpen])

  useEffect(() => {
    //if resizableState.isResizing is true then set isResizing to true if it is false wait 1 second then set to false
    if (resizableState.isResizing) {
      setIsResizing(true)
    } else {
      setTimeout(() => {
        setIsResizing(false)
      }, 400)
    }
  }, [resizableState.isResizing])

  const { mutateAsync: cloneReport } = useCloneReport()
  const history = useHistory()

  useEffect(() => {
    if (bookmarksLoading || !bookmarks) return
    const allBookmarks = [
      ...bookmarks.licenseBookmarks,
      ...bookmarks.sharedBookmarks,
      ...bookmarks.ownedBookmarks,
    ]

    if (!bookmarkId || bookmarkId === bookmarkState?.selectedBookmark?.id)
      return
    const bookmark = allBookmarks.find(b => b.id === bookmarkId)
    if (!bookmark) return
    setBookmarkState({
      ...bookmarkState,
      selectedBookmark: bookmark,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmarks, bookmarksLoading, bookmarkId])

  const toggleDialog = useCallback(() => {
    setIsDialogOpen(o => !o)
  }, [])
  const showBackButton = !isOnlyOneUniqueItem(allReports)

  const reportEmbedSettings = useMemo(
    () => ({
      filterPaneEnabled: selectedReport?.filterPaneEnabled,
      showPageNavigation: selectedReport?.showPageNavigation,
      defaultPageName: selectedReport?.defaultPageName,
    }),
    [selectedReport]
  )
  const onSave = async (savedReport: SaveEvent) => {
    if (!savedReport.saveAs) return

    setIsCloning(true)
    const newReport = await cloneReport({
      sourceReportId: reportId,
      newPbiReportId: savedReport.reportObjectId,
      newName: savedReport.reportName,
      licenseNodeId: license.id,
    })

    // refetch getAllReports query
    await reloadReports()
    setEditMode(false)
    setCopyName(null)
    setIsCloning(false)
    history.push(`/reports/${newReport.id}`)
  }

  if (isLoading || bookmarksLoading || licenseLoading || isCloning) {
    return <FullPageLoader />
  }

  if (!selectedReport) {
    return (
      <ErrorPage>
        Error: You do not have access to this report or the report does not
        exist
      </ErrorPage>
    )
  }

  const isCustom = selectedReport?.type === 'custom'

  const isPaginatedReport = selectedReport?.type === 'paginated'

  return (
    <Layout
      title={selectedReport.name}
      showBackButton={showBackButton}
      hideLogo
      hideBackground
    >
      {isCustom || (isPaginatedReport && editMode) ? (
        <Box
          className={classes.embedWrapper}
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <CustomReportTable
            currentReportId={reportId}
            name={selectedReport?.name}
            report={selectedReport}
          />
        </Box>
      ) : (
        <Stack
          direction='column'
          className={classes.embedWrapper}
          sx={{
            display: 'flex',
            flex: 1,
            marginRight: bookmarkState.panelOpen
              ? `${resizableState.newWidth.width}px`
              : 0,
            width: bookmarkState.panelOpen
              ? `calc(100vw - ${resizableState.newWidth.width}px)`
              : '100%',
            transition: theme =>
              theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
          }}
        >
          <ReportOptionsBar
            reportId={reportId}
            isOwner={ownedReports?.some(report => report.id === reportId)}
            isAdmin={license.isAdmin}
            editMode={editMode}
            toggleEditMode={toggleEditMode}
            showCopyDialog={showCopyDialog}
            setShowCopyDialog={setShowCopyDialog}
            setCopyName={setCopyName}
            reportType={selectedReport?.type || ''}
          />

          <PbiReport
            reportId={reportId}
            reportEmbedSettings={reportEmbedSettings}
            embedType='report'
            className={
              isResizing ? classes.disablePointerEvents : classes.embeddedReport
            }
            editMode={editMode}
            setShowCopyDialog={setShowCopyDialog}
            copyName={copyName}
            onSave={onSave}
            isResizing={isResizing}
          />
        </Stack>
      )}
      {bookmarkState.panelOpen && (
        <ResizableDrawer
          open={bookmarkState.panelOpen}
          resizeState={resizableState}
          setResizeState={setResizableState}
          minWidth={100}
          maxWidth={1000}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <BookmarkSelector
              bookmarks={bookmarks}
              bookmarksLoading={bookmarksLoading}
              reportId={reportId}
              licenseId={license.id}
              onAddClick={() => {
                setBookmarkToEdit(null)
                toggleDialog()
              }}
              onEdit={bookmark => {
                setIsDialogOpen(true)
                setBookmarkToEdit(bookmark)
              }}
              drawerWidth={resizableState?.newWidth?.width}
            />
          </div>
        </ResizableDrawer>
      )}
      <BookmarkDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        reportId={reportId}
        bookmark={bookmarkToEdit}
      />
    </Layout>
  )
}
