import {useMutation, useQueryClient} from 'react-query'
import {reportApi} from '../../api-interface'
import {getReportsKey} from '../queries'

type MakeDefaultPayload = {reportId: string; licenseId: string}

/** Add viewers to a PBI report */
export function useAddDefaultReport() {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: MakeDefaultPayload) =>
      reportApi.addDefault(payload.reportId, payload.licenseId),
    {
      onSettled() {
        queryClient.invalidateQueries(getReportsKey)
      },
    }
  )
}
