import { DataColumn } from '../../../../models'
import { FILTER_OPTIONS } from '../custom-report-table'
import { formatName, replaceSpacesWithUnderscores } from './util'

export const generateFieldSectionsObject = (columns: DataColumn[]): string => {
  const fieldSections = columns
    .map(
      column => `
      <wa:FieldSelection>
            <wa:EntityName>${column.table}</wa:EntityName>
            <wa:PropertyName>${column.name}</wa:PropertyName>
            <wa:Aggregate>None</wa:Aggregate>
      </wa:FieldSelection>
    `
    )
    .join('')

  return `<wa:FieldSelections>${fieldSections}</wa:FieldSelections>`
}

const textBoxStyles = `
                            <Style>
                            <Border>
                              <Style>None</Style>
                            </Border>
                            <TopBorder>
                              <Style>None</Style>
                            </TopBorder>
                            <BottomBorder>
                              <Color>=Variables!Style_OutlineColor.Value</Color>
                              <Style>=If(Variables!Style_HeaderEnableBorderBottom.Value = "true", "Solid", "None")</Style>
                              <Width>=If(Variables!Style_HeaderBorderBottomWeight.Value = "",             Variables!Style_GridHorizontalWeight.Value, Variables!Style_HeaderBorderBottomWeight.Value)</Width>
                            </BottomBorder>
                            <LeftBorder>
                              <Color>=Variables!Style_BackColorHeaders.Value</Color>
                              <Style>=If(Variables!Style_GridVerticalEnabledTable.Value = "true", "Solid", "None")</Style>
                              <Width>=Variables!Style_GridVerticalWeight.Value</Width>
                            </LeftBorder>
                            <BackgroundColor>=Variables!Style_BackColorHeaders.Value</BackgroundColor>
                            <PaddingLeft>2pt</PaddingLeft>
                            <PaddingRight>2pt</PaddingRight>
                            <PaddingTop>4pt</PaddingTop>
                            <PaddingBottom>2pt</PaddingBottom>
                          </Style>`

export const generateReportSectionsObject = (columns: DataColumn[]): string => {
  const tablixColumns = columns
    .map(
      () => `
    <TablixColumn>
      <Width>1.875in</Width>
    </TablixColumn>
  `
    )
    .join('')

  const headerCells = columns
    .map(
      (column, index) => `
    <TablixCell>
      <CellContents>
        <Textbox Name="TextboxHeader${index}">
          <CanGrow>true</CanGrow>
          <UserSort>
            <SortExpression>=Fields!${formatName(column.table)}_${formatName(
        column.name
      )}.Value</SortExpression>
          </UserSort>
          <KeepTogether>true</KeepTogether>
          <Paragraphs>
            <Paragraph>
              <TextRuns>
                <TextRun>
                  <Value>${column.name}</Value>
                 <Style>
                   <FontFamily>Segoe UI Semibold</FontFamily>
                   <FontSize>11pt</FontSize>
                   <Format>0.00</Format>
                   <Color>=Variables!Style_FontColorHeaders.Value</Color>
                 </Style>
                </TextRun>
              </TextRuns>
            </Paragraph>
          </Paragraphs>
          ${textBoxStyles}
        </Textbox>
      </CellContents>
    </TablixCell>
  `
    )
    .join('')

  const fieldSections = generateFieldSectionsObject(columns)

  const dataCellStyles = `<Style>
                            <Border>
                              <Style>None</Style>
                            </Border>
                            <TopBorder>
                              <Style>None</Style>
                            </TopBorder>
                            <BottomBorder>
                              <Color>=Variables!Style_GridColor.Value</Color>
                              <Style>=If(Variables!Style_GridHorizontalEnabledTable.Value = "true", "Solid", "None")</Style>
                              <Width>=Variables!Style_GridHorizontalWeight.Value</Width>
                            </BottomBorder>
                            <LeftBorder>
                              <Color>=Variables!Style_GridColor.Value</Color>
                              <Style>=If(Variables!Style_GridVerticalEnabledTable.Value = "true", "Solid", "None")</Style>
                              <Width>=Variables!Style_GridVerticalWeight.Value</Width>
                            </LeftBorder>
                            <BackgroundColor>=If(RowNumber(Nothing) Mod 2 &lt;&gt; 0, Variables!Style_BackColorValues1.Value, Variables!Style_BackColorValues2.Value)</BackgroundColor>
                            <PaddingLeft>2pt</PaddingLeft>
                            <PaddingRight>5pt</PaddingRight>
                            <PaddingTop>=Variables!Style_RowPadding.Value</PaddingTop>
                            <PaddingBottom>=Variables!Style_RowPadding.Value</PaddingBottom>
                          </Style>`

  const dataCells = columns
    .map(
      (column, index) => `
    <TablixCell>
      <CellContents>
        <Textbox Name="Textbox${index}">
          <CanGrow>true</CanGrow>
          <KeepTogether>true</KeepTogether>
          <Paragraphs>
            <Paragraph>
              <TextRuns>
                <TextRun>
                  <Value>=Fields!${formatName(column.table)}_${formatName(
        column.name
      )}.Value</Value>
                   <Style>
                    <FontFamily>Segoe UI</FontFamily>
                    <FontSize>11pt</FontSize>
                    ${getType(column.dataType)}
                    <Color>=Variables!Style_FontColorHeaders.Value</Color>
                  </Style>
                </TextRun>
              </TextRuns>
            </Paragraph>
          </Paragraphs>
          ${dataCellStyles}
          <DataElementName>${replaceSpacesWithUnderscores(
            column.table
          )}_${replaceSpacesWithUnderscores(column.name)}</DataElementName>
        </Textbox>
      </CellContents>
    </TablixCell>
  `
    )
    .join('')

  return `<ReportSections>
    <ReportSection>
      <Body>
        <ReportItems>
          <Tablix Name="Tablix1">
            <TablixBody>
              <TablixColumns>
                ${tablixColumns}
              </TablixColumns>
              <TablixRows>
                <TablixRow>
                  <Height>0.25in</Height>
                  <TablixCells>
                    ${headerCells}
                  </TablixCells>
                </TablixRow>
                <TablixRow>
                  <Height>0.25in</Height>
                  <TablixCells>
                    ${dataCells}
                  </TablixCells>
                </TablixRow>
              </TablixRows>
            </TablixBody>
            ${fieldSections}
            <wa:ShowGrandTotals>false</wa:ShowGrandTotals>
            <TablixColumnHierarchy>
              <TablixMembers>
                ${columns.map(() => '<TablixMember />').join('')}
              </TablixMembers>
            </TablixColumnHierarchy>
            <TablixRowHierarchy>
              <TablixMembers>
                <TablixMember>
                  <KeepWithGroup>After</KeepWithGroup>
                  <RepeatOnNewPage>true</RepeatOnNewPage>
                </TablixMember>
                <TablixMember>
                  <Group Name="Details" />
                </TablixMember>
              </TablixMembers>
            </TablixRowHierarchy>
            <DataSetName>DataSet1</DataSetName>
            <Top>0mm</Top>
            <Height>0.5in</Height>
            <Width>${columns.length * 1.875}in</Width>
          </Tablix>
        </ReportItems>
        <Height>2.25in</Height>
      </Body>
      <Width>${columns.length * 1.875 + 2.25}in</Width>
      <Page>
        <LeftMargin>0.5in</LeftMargin>
        <RightMargin>0.5in</RightMargin>
        <TopMargin>0.5in</TopMargin>
        <BottomMargin>0.5in</BottomMargin>
      </Page>
    </ReportSection>
  </ReportSections>`
}

export function getType(type: number): string {
  let dataType = ''
  switch (type) {
    case FILTER_OPTIONS.INT: {
      dataType = '0'
      break
    }
    case FILTER_OPTIONS.DOUBLE: {
      dataType = '0.00'
      break
    }
    case FILTER_OPTIONS.DATE_TIME: {
      dataType = 'D'
      break
    }
    default:
      dataType = ''
  }

  if (!dataType || dataType?.length === 0) {
    return ''
  }
  return `<Format>${dataType}</Format>`
}
