import React from 'react'
import { Theme, Button, Stack, Box } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { LoadingButton } from '../../../components'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      flexGrow: 1,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    saveButton: {
      marginTop: theme.spacing(1),
    },
    cancelButton: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    formContent: {
      width: '100%',
      maxWidth: '1000px',
    },
    error: {
      width: '100%',
      color: 'red',
    },
    formBox: {
      minWidth: '300px',
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

export function Form(props: FormProps) {
  const {
    children,
    onCancel,
    onSave,
    isSaving,
    error,
    disableSave,
    tertiaryComponent,
  } = props
  const classes = useStyles()

  return (
    <Box className={classes.formBox}>
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault()
          onSave()
        }}
      >
        <div className={classes.formContent}>
          {children}
          {error && <div className={classes.error}>{error}</div>}
        </div>
      </form>
      <Stack
        direction='row'
        justifyContent={!!tertiaryComponent ? 'space-between' : 'flex-end'}
        alignItems={'center'}
        sx={{ mt: 4 }}
      >
        {tertiaryComponent && <>{tertiaryComponent}</>}
        <Stack direction={'row'}>
          <Button
            className={classes.cancelButton}
            onClick={onCancel}
            disabled={isSaving}
          >
            Cancel
          </Button>

          <LoadingButton
            className={classes.saveButton}
            type='submit'
            onClick={onSave}
            color='secondary'
            variant='contained'
            isLoading={isSaving}
            disabled={disableSave}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  )
}

export type FieldObject = {
  label: string
  value: string
  helperText: string
}

export type FormProps = {
  children: React.ReactNode
  isSaving?: boolean
  confirmationButtonLabel?: string
  onSave: () => void
  onCancel: () => void
  error?: string
  disableSave?: boolean
  tertiaryComponent?: React.ReactNode
  minWidth?: string
}
