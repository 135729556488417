import {
  CreateWorkspacePayload,
  GetCustomReportItemsParams,
  PostDaxQueryPayload,
} from '../hooks'
import {
  CustomReportItemsInfo,
  WorkspaceDatasetInfo,
  WorkspaceInfo,
  WorkspaceReportInfo,
} from '../models/workspaces'
import { getAxios, endpoints, getUrl } from './util'

export const workspacesApi = {
  async getWorkspaceReports(
    appRegNodeId: string,
    appClientId: string,
    appRegTenantId: string,
    workspaceId: string,
    workspaceName?: string
  ): Promise<WorkspaceReportInfo[]> {
    if (!appRegNodeId || !workspaceId) {
      return []
    }

    const axios = await getAxios()

    const url = getUrl(endpoints.workspaceReports, {
      appRegNodeId,
      workspaceId,
      workspaceName,
      appClientId,
      appRegTenantId,
    })
    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data?.payload
  },

  async getPerspectives(
    params: GetCustomReportItemsParams,
    licenseId: string
  ): Promise<string[]> {
    const { appRegNodeId, datasetId, workspaceId, reportId } = params

    if (!appRegNodeId || !datasetId || !workspaceId) {
      return []
    }

    const axios = await getAxios()

    const url = getUrl(endpoints.perspectives, {
      appRegNodeId,
      datasetId,
      workspaceId,
      reportId,
      licenseId,
    })

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data?.payload
  },

  async getCapacities(
    licenseId: string,
    appRegNodeId: string,
    appClientId: string,
    appRegTenantId: string,
    profileId: string
  ): Promise<any> {
    if (
      !licenseId ||
      !appRegNodeId ||
      !appClientId ||
      !appRegTenantId ||
      !profileId
    ) {
      return []
    }

    const axios = await getAxios()

    const url = getUrl(endpoints.capacities, {
      appRegNodeId,
      appClientId,
      appRegTenantId,
      profileId,
      licenseId,
    })

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data?.value
  },

  async editWorkspace(params: {
    workspaceId: string
    name: string
    licenseId: string
    description?: string
    sharedWithLicense?: boolean
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'patch',
      url: endpoints.workspaces,
      data: params,
    })
  },

  async deleteWorkspace(workspaceId: string, licenseId: string): Promise<any> {
    if (!workspaceId || !licenseId) {
      return null
    }

    const axios = await getAxios()

    const deleteData = {
      workspaceId,
      licenseId,
    }

    const response = await axios({
      method: 'delete',
      url: endpoints.workspaces,
      data: deleteData,
    })

    return response
  },

  async getWorkspaceUsers(
    workspaceId: string,
    licenseId: string
  ): Promise<any> {
    if (!workspaceId || !licenseId) {
      return []
    }

    const axios = await getAxios()

    const url = getUrl(endpoints.workspaceUsers, {
      workspaceId,
      licenseId,
    })

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data?.payload
  },

  async addWorkspaceUsers(
    workspaceId: string,
    userIds: string[],
    licenseId: string
  ): Promise<any> {
    if (!workspaceId || !licenseId) {
      return []
    }

    const axios = await getAxios()

    const data = {
      workspaceId,
      licenseId,
      userIds,
    }

    const response = await axios({
      method: 'post',
      url: endpoints.workspaceUsers,
      data,
    })

    return response?.data?.payload
  },

  async removeWorkspaceUser(
    workspaceId: string,
    userId: string,
    licenseId: string
  ): Promise<any> {
    if (!workspaceId || !userId || !licenseId) {
      return null
    }

    const axios = await getAxios()

    const url = getUrl(endpoints.workspaceUsers, {
      workspaceId,
      userId,
      licenseId,
    })

    const response = await axios({
      method: 'delete',
      url,
    })

    return response
  },

  async getWorkspaceDatasets(
    appRegNodeId: string,
    appClientId: string,
    appRegTenantId: string,
    profileId: string,
    workspaceId: string
  ): Promise<WorkspaceDatasetInfo[]> {
    if (!appRegNodeId || !workspaceId) {
      return []
    }

    const axios = await getAxios()

    const url = getUrl(endpoints.workspaceDatasets, {
      appRegNodeId,
      workspaceId,
      appClientId,
      profileId,
      appRegTenantId,
    })

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data?.payload
  },

  async postDaxQuery(params: PostDaxQueryPayload, licenseId: string) {
    const { appRegNodeId, datasetId, groupId, reportId, query } = params

    const axios = await getAxios()

    const response = await axios({
      method: 'post',
      data: {
        appRegNodeId,
        datasetId,
        workspaceId: groupId,
        reportId,
        query,
        licenseId,
      },
      url: endpoints.queries,
    })

    return response
  },

  async getCustomReportItems(
    params: GetCustomReportItemsParams,
    licenseId: string
  ): Promise<CustomReportItemsInfo> {
    const { appRegNodeId, datasetId, workspaceId, reportId } = params

    const axios = await getAxios()

    const url = getUrl(endpoints.customReportItems, {
      appRegNodeId,
      datasetId,
      workspaceId,
      reportId,
      licenseId,
    })

    try {
      const response = await axios({
        method: 'get',
        url,
      })

      return response?.data?.payload
    } catch (e) {
      return null
    }
  },

  async createWorkspace(payload: CreateWorkspacePayload, licenseId: string) {
    const { appRegNodeId, name, description, profileId, capacityId } = payload

    if (!appRegNodeId || !name) {
      return null
    }

    const axios = await getAxios()

    const response = await axios({
      method: 'post',
      data: {
        appRegNodeId,
        name,
        description,
        profileId,
        licenseId,
        capacityId,
      },
      url: endpoints.workspaces,
    })

    return response
  },

  async getProfileWorkspaces(licenseId: string): Promise<WorkspaceInfo[]> {
    if (!licenseId) {
      return []
    }
    const axios = await getAxios()

    const url = getUrl(endpoints.workspaceProfiles, {
      licenseId,
    })

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data?.payload
  },

  async getWorkspaces(licenseId: string): Promise<WorkspaceInfo[]> {
    if (!licenseId) {
      return []
    }
    const axios = await getAxios()

    const url = getUrl(endpoints.workspaces, {
      licenseId,
    })

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data?.payload
  },

  async getWorkspacesForAppReg(
    appRegNodeId: string,
    licenseId: string
  ): Promise<WorkspaceInfo[]> {
    if (!appRegNodeId) {
      return []
    }
    const axios = await getAxios()

    const url = getUrl(endpoints.workspaceProfiles, {
      appRegNodeId,
      licenseId,
    })

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data?.payload
  },

  async getWorkspaceById(
    appRegNodeId: string,
    workspaceId: string
  ): Promise<WorkspaceInfo> {
    if (!appRegNodeId || !workspaceId) {
      return null
    }

    const axios = await getAxios()

    const url = getUrl(endpoints.workspaces, {
      appRegNodeId,
      workspaceId,
    })

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data?.payload?.[0]
  },
}
