import { Group } from './group'
import { User } from './user'

export type ReportData = {
  id?: string
  name: string
  description?: string
  url?: string
  default?: boolean
}

export type RlsValue = {
  roles: string[]
  username: 'email'
}

export type ReportPage = {
  name: string
  displayName: string
  order: number
}

export type NewReportEmbedRequest = {
  licenseId: string
  pbiReportId: string
  appRegNodeId: string
  appClientId: string
  appRegTenantId: string
  workspaceId: string
  profileId: string
  rlsRoles?: string[]
  username?: string
}

export type PaginatedReportToCreate = {
  id?: string
  name: string
  description: string
  selectedColumns: string[]
  filters: string
  appRegNodeId: string
  workspaceId: string
  licenseId: string
  profileId: string
  file: string
  datasetId: string
}

export type ReportToUpdate = ReportData & {
  appRegistrationNodeId: string
  licenseNodeId: string
  viewers?: string[]
  rls?: RlsValue
  name?: string
  filterPaneEnabled?: boolean
  showPageNavigation?: boolean
  defaultPageName?: string
}

export type ReportToCreate = ReportData & {
  pbiReportId?: string
  pbiReportName?: string
  pbiWorkspaceId?: string
  appRegistrationNodeId: string
  appRegistrationName: string
  profileId: string
  profileName: string
  licenseNodeId: string
  viewers?: string[]
  datasetId?: string
  rls?: RlsValue
  filterPaneEnabled?: boolean
  showPageNavigation?: boolean
  defaultPageName?: string
  type?: string
  perspective?: string
}

export type ReportSettings = {
  displayName: string
  filterPaneEnabled: boolean
  showPageNavigation: boolean
  defaultPageName?: string
}

export type ReportSettingsToUpdate = ReportSettings & {
  id: string
  licenseNodeId: string
}

export type AllReports = {
  ownedReports: ApiReport[]
  sharedReports: ApiReport[]
  defaultReports: ApiReport[]
  licenseReports: ApiReport[]
}

export type ApiReport = ReportData & {
  owner: string
  pbiWorkspaceId: string
  datasetId?: string
  pbiReportId: string
  pbiReportName?: string
  appRegistrationNodeId: string
  appRegistrationName: string
  profileId?: string
  profileName?: string
  defaultPageName?: string
  rls?: RlsValue
  default?: boolean
  workspaceName?: string
  filterPaneEnabled?: boolean
  expandFilterPane?: boolean
  showPageNavigation?: boolean
  type?: string
  selectedColumns?: string
  filters?: string
}

export type ApiGroupReport = ApiReport & {
  groupId: string
  groupName: string
}

export type ReportsFromUser = {
  reportsFromGroups: ApiGroupReport[]
  reportsFromUser: ApiReport[]
}

export type StatusMessageInfo = {
  type: string
  title: string
  message?: string
  reasons?: string[]
}

export type GetReportViewersResponse = {
  users: User[]
  groups: Group[]
}

export type GetAllReportsForUserResponse = {
  reportsFromGroups: ApiGroupReport[]
  reportsFromUser: ApiReport[]
}

export enum ReportConnectionStatus {
  OK = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  RowLevelSecurity = 999,
}
