import { PowerBIShareTheme } from '../models'
import { getAxios, endpoints, getUrl } from './util'

export const themeApi = {
  async createTheme(theme: PowerBIShareTheme) {
    let axios = await getAxios()
    await axios({
      method: 'post',
      url: endpoints.theme,
      data: theme,
    })
  },

  async getTheme(licenseId: string) {
    let axios = await getAxios()
    let url = getUrl(endpoints.theme, { licenseNodeId: licenseId })
    let response = await axios({
      method: 'get',
      url,
    })

    return response.data.payload
  },
}
