import { atom } from 'recoil'
import { Bookmark } from '../models'

export type BookmarkAtomType = {


  selectedBookmark: Bookmark | null //Current selected bookmark
  selectedBookmarkFilters: BookmarkFilters | null //Current selected bookmark filters
  panelOpen: boolean //Toggle this to open the bookmark panel
  resetReportFilters: boolean //Set this to true to reset the report filters

}

export const bookmarkAtom = atom<BookmarkAtomType>({
  key: 'current-bookmark-id',
  default: {
    selectedBookmark: null,
    panelOpen: false,
    resetReportFilters: false,
    selectedBookmarkFilters: null,
  },
})


export type BookmarkFilters = {
  reportFilters: any[]
  pageFilters: { name: string; filters: any[] }[]
  visualFilters: { name: string; filters: any[] }[]
}
