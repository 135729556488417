import {Button, Stack, Typography} from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {CSVLink} from 'react-csv'

const exampleData = [
  ['id', 'firstName', 'lastName', 'userRole', 'groups'],
  ['john@email.com', 'John', 'Doe', 'admin', ''],
  ['jane@email.com', 'Jane', 'Doe', 'viewer', 'Group #1'],
  ['joe@gmail.com', 'Joe', 'Smith', 'viewer', 'Group #1 / Group #2 / Group #3'],
]

type HelpDialogBoxProps = {
  disabled?: boolean
}

export function HelpDialogBox(props: HelpDialogBoxProps) {
  return (
    <Stack direction='column' spacing={2}>
      <Typography
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 1,
        }}
        fontStyle='italic'
        color='#808080'
        fontSize={11}
      >
        Must include column types: 'id', 'firstName', 'lastName', 'userRole',
        'groups' (optional). 'userRole' must be of type 'admin' or 'viewer'.
        'groups' must be separated by '/'.
      </Typography>
      <CSVLink
        style={{textDecoration: 'none', alignSelf: 'flex-end'}}
        data={exampleData}
        filename='example.csv'
      >
        <Button startIcon={<FileDownloadIcon />} disabled={props.disabled}>
          Download example file
        </Button>
        {/* <IconButton
            sx={{
              marginLeft: '6px !important',
              borderRadius: 0,
            }}
            // onClick={onDownload}
            size='small'
          >
            <FileDownloadIcon />
            <Typography
              sx={{width: '100%', display: 'flex', paddingLeft: '4px'}}
              variant='subtitle2'
              color='#808080'
            >
              Download example file
            </Typography>
          </IconButton> */}
      </CSVLink>
    </Stack>
  )
}
