import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {groupApi} from '../../api-interface'
import {Group} from '../../models'
import {licenseAtom} from '../../state'
import {getGroupsKey} from '../queries'

type DeleteGroupContext = {
  previousGroups: Group[]
}

export function useDeleteGroup() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)
  return useMutation(
    (groupId: string) => groupApi.deleteGroup(groupId, licenseId),
    {
      async onMutate(groupId: string): Promise<DeleteGroupContext> {
        await queryClient.cancelQueries([getGroupsKey, licenseId])

        let previousGroups: Group[] = queryClient.getQueryData([
          getGroupsKey,
          licenseId,
        ])

        queryClient.setQueryData([getGroupsKey, licenseId], (old: Group[]) =>
          old.filter((group: Group) => group.id !== groupId)
        )

        return {previousGroups}
      },
      onError(_err, _deletedGroup, context: DeleteGroupContext) {
        queryClient.setQueryData(
          [getGroupsKey, licenseId],
          context.previousGroups
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getGroupsKey, licenseId])
      },
    }
  )
}
