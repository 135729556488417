import { useState } from 'react'

import { Form, FormField, StatusMessage } from '../../shared'
import {
  useCreateAppReg,
  useGetLicense,
  useUpdateAppReg,
} from '../../../../hooks'
import { AppRegistration, StatusMessageInfo } from '../../../../models'

export function AddAppRegistrationForm(props: AddAppRegistrationContentProps) {
  const { onClose, onSave, isEdit, appRegistrationToEdit } = props
  const { mutateAsync: createAppReg, isLoading: isSaving } = useCreateAppReg()
  const { mutateAsync: updateAppReg, isLoading: isUpdating } = useUpdateAppReg()

  const [appRegistrationSecret, setAppRegistrationSecret] = useState(
    isEdit ? 'somereallylongplaceholdervalueforafakepassword' : ''
  )
  const { data: license } = useGetLicense()
  const [status, setStatus] = useState<StatusMessageInfo | null>(null)

  const [appRegistrationId, setAppRegistrationId] = useState(
    appRegistrationToEdit?.appRegistrationId ?? ''
  )

  const [tenantId, setTenantId] = useState(
    appRegistrationToEdit?.tenantId ?? ''
  )

  const [appName, setAppName] = useState(appRegistrationToEdit?.name ?? '')

  async function handleSave() {
    let appReg = {
      licenseNodeId: license.id,
      appRegistrationId,
      tenantId,
      name: appName,
      appRegistrationSecret,
    }

    try {
      if (!isEdit) {
        await createAppReg(appReg)
      } else {
        await updateAppReg({ ...appReg, id: appRegistrationToEdit.id })
      }

      onSave()
      onClose()
    } catch (error) {
      setStatus({
        type: 'error',
        message: `An error occurred while saving the app registration ${error?.response?.data?.errorMessage}`,
        title: 'Error',
      })
    }
  }

  return (
    <Form
      onSave={handleSave}
      onCancel={onClose}
      isSaving={isSaving || isUpdating}
    >
      <FormField
        label='Display Name'
        value={appName}
        helperText=''
        onTextChange={setAppName}
      />
      <FormField
        label='Application (client) ID'
        value={appRegistrationId}
        helperText='Client Id for the app registration'
        onTextChange={setAppRegistrationId}
        disabled={isEdit}
      />
      <FormField
        label='Directory (tenant) ID'
        value={tenantId}
        helperText='The Azure Directory that the service principal belongs to'
        onTextChange={setTenantId}
        disabled={isEdit}
      />
      <FormField
        label='Client Secret'
        value={appRegistrationSecret}
        helperText='This is the client secret (application password)'
        onTextChange={setAppRegistrationSecret}
        type='password'
        // disabled={isEdit}
      />
      {status && <StatusMessage status={status} />}
    </Form>
  )
}

export type AddAppRegistrationContentProps = {
  onClose?: () => void
  onSave?: () => void
  isEdit?: boolean
  appRegistrationToEdit?: AppRegistration
}
