import { Dialog, DialogTitle, Theme } from "@mui/material"
import { useState } from "react"
import { Form, FormField } from "../../admin/shared"
import { createStyles, makeStyles } from "@mui/styles"

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            title: {
                padding: 0,
                margin: '16px 0',
              },
              dialogContent: {
                display: 'block',
                padding: theme.spacing(4),
              },
        }), {name: 'copy-dialog'}
)

export function ReportCopyDialog(props: ReportCopyDialogProps){
    const { isOpen, onClose, setCopyName} = props
  
    const classes = useStyles()
    const [tempCopyName, setTempCopyName] = useState('')
    const [nameError, setNameError] = useState(false)
  
    const handleClose = () => {
      onClose()
      setTempCopyName('')
    }
  
    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        classes={{
          paper: classes.dialogContent
        }}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle className={classes.title}>
          Save a Copy
        </DialogTitle>
          <Form
            onSave={() => {
              if (tempCopyName === '') {
                setNameError(true)
                return
              }
              setCopyName(tempCopyName)
              handleClose()
            }}
            onCancel={handleClose}
          >
            <FormField
              label='Title of Copy'
              value={tempCopyName}
              onTextChange={setTempCopyName}
              errorMessage={nameError ? 'Name is required' : ''}
            />
          </Form>
      </Dialog>
    )
  }
  
  type ReportCopyDialogProps = {
    isOpen: boolean,
    onClose,
    setCopyName
  }