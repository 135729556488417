import {useMutation, useQueryClient} from 'react-query'
import {userApi} from '../../api-interface'
import {GetUserInfoResponse} from '../../models'
import {currentUserInfoKey} from '../queries'

export function useUpdateDefaultLicense() {
  const queryClient = useQueryClient()

  return useMutation(
    (licenseId: string) => userApi.updateDefaultLicenseId(licenseId),
    {
      async onMutate(licenseId): Promise<GetUserInfoResponse> {
        await queryClient.cancelQueries(currentUserInfoKey)

        const previousResponse: GetUserInfoResponse =
          queryClient.getQueryData(currentUserInfoKey)

        queryClient.setQueryData(
          currentUserInfoKey,
          (previous: GetUserInfoResponse) => {
            previous.licenses.forEach(license => (license.default = false))
            const licenseToUpdate = previous.licenses.find(
              license => license.id === licenseId
            )
            const index = previous.licenses.indexOf(licenseToUpdate)
            previous.licenses[index].default = true

            return previous
          }
        )

        return previousResponse
      },
      onError(_err, _updatedInfo, context: GetUserInfoResponse) {
        queryClient.setQueryData(currentUserInfoKey, context)
      },
      onSettled() {
        queryClient.invalidateQueries(currentUserInfoKey)
      },
    }
  )
}
