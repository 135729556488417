import {
  Box,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  TextField,
  Theme,
  Typography,
  alpha,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import cs from 'classnames'
import { Bookmark, Folder, NewBookmark } from '../../../models'
import {
  useDeleteBookmark,
  useGetBookmarkMember,
  useGetFolders,
  useUpdateBookmark,
} from '../../../hooks'
import { bookmarkAtom } from '../../../state/bookmarkAtom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { BookmarksSkeleton } from './bookmarks-skeleton'
import { useEffect, useRef, useState } from 'react'
import { OverflowMenu } from '../../admin/shared/existing-items/overflowMenu'
import { getIconForBookmark } from './Icons'
import { reportStateAtom } from '../../../state'
import { ReplyAll } from '@mui/icons-material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useUpdateFolder } from '../../../hooks/mutations/useUpdateFolder'
import { useCreateFolder } from '../../../hooks/mutations/useCreateFolder'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: '100%',
      overflowY: 'auto',
      height: '100%',
      display: 'flex',
      padding: theme.spacing(1),
      flexDirection: 'column',
    },
    bookmarkSelector: {
      '&:hover': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.4),
      },
      borderRadius: '12px',
      marginBottom: theme.spacing(1.5),
      color: theme.palette.text.primary,
      position: 'relative',
      alignItems: 'center',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      cursor: 'pointer',
      height: '48px',
    },
    bookmarkSelected: {
      backgroundColor: alpha(theme.palette.secondary.main, 0.2),
    },
    bookmarkSelectorIcon: {
      marginRight: theme.spacing(1),
      minWidth: 0,
    },
    selected: {
      color: theme.palette.secondary.main,
    },
    muiBookmarkSelectorIcon: {
      marginRight: theme.spacing(0),
      width: '16px',
      height: '16px',
    },
    bookmarkTitle: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    sharedIcon: {
      minWidth: '16px',
      '& .MuiSvgIcon-root': {
        fontSize: '16px',
      },
      padding: 4,
    },
  })
)

export function BookmarkSelector(props: BookmarkSelectorProps) {
  const {
    bookmarks,
    bookmarksLoading,
    licenseId,
    reportId,
    onAddClick,
    onEdit,
    drawerWidth,
  } = props
  const classes = useStyles()

  const [bookmarkState, setBookmarkState] = useRecoilState(bookmarkAtom)
  const reportState = useRecoilValue(reportStateAtom)

  const { mutateAsync: deleteBookmark, isLoading: isDeleting } =
    useDeleteBookmark(reportId)
  const { mutateAsync: updateBookmark, isLoading: isUpdating } =
    useUpdateBookmark(reportId)

  const { mutateAsync: updateFolder } = useUpdateFolder()
  const { mutateAsync: createFolder } = useCreateFolder()

  if (bookmarks === undefined) return null

  const { licenseBookmarks, ownedBookmarks, sharedBookmarks } = bookmarks

  //filter out duplicates
  const allBookmarks =
    [...licenseBookmarks, ...ownedBookmarks, ...sharedBookmarks]?.filter(
      (bookmark, index, self) => {
        return index === self.findIndex(t => t.id === bookmark.id)
      }
    ) || []

  const allSharedBookmarks =
    [...licenseBookmarks, ...sharedBookmarks]?.filter(
      (bookmark, index, self) => {
        return index === self.findIndex(t => t.id === bookmark.id)
      }
    ) || []

  const setActiveBookmark = (bookmark: Bookmark) => {
    setBookmarkState({
      ...bookmarkState,
      selectedBookmark:
        bookmark === bookmarkState.selectedBookmark
          ? bookmarkState.selectedBookmark
          : bookmark,
      panelOpen: true,
    })
  }

  async function handleUpdate(bookmark) {
    let newBookmark: NewBookmark = {
      id: bookmark.id,
      name: bookmark.name,
      state: reportState,
      description: bookmark.description,
      icon: bookmark.icon,
      reportId: reportId,
      licenseNodeId: licenseId,
    }

    await updateBookmark(newBookmark)
  }

  const handleAddBookmark = () => {
    onAddClick()
  }

  const removeBookmarkId = () => {
    window.history.replaceState({}, '', `/reports/${reportId}`)
  }

  const handleAddToFolder = async (bookmark: Bookmark, folder: Folder) => {
    if (folder?.id === '' && folder?.name === '') {
      return
    }

    if (folder?.name && folder?.id === '') {
      await createFolder({
        folderName: folder.name,
        items: [
          {
            id: bookmark.id,
            type: 'bookmark',
            name: bookmark.name,
            reportId: reportId,
          },
        ],
      })
    }

    if (!folder.id) return

    await updateFolder({
      folderId: folder.id,
      folderName: folder.name,
      items: [
        ...folder?.items,
        {
          id: bookmark.id,
          type: 'bookmark',
          name: bookmark.name,
          reportId: reportId,
        },
      ],
    })
  }

  const handleBookmarkDelete = (bookmarkId: string) => {
    deleteBookmark(bookmarkId)
    if (bookmarkState.selectedBookmark?.id === bookmarkId) {
      removeBookmarkId()
    }
  }

  if (bookmarksLoading || isDeleting || isUpdating) {
    return <BookmarksSkeleton numberOfBookmarks={allBookmarks?.length} />
  }

  return (
    <List className={classes.drawer}>
      {/* {hasUnsavedChanges && (
        <Typography sx={{color: 'red', marginLeft: '12px'}} fontStyle='italic'>
          There are unsaved changes to the current report
        </Typography>
      )} */}
      <AddBookmark key={allBookmarks.length + 1} onClick={handleAddBookmark} />

      {allSharedBookmarks.length > 0 && (
        <Stack direction='column'>
          <Typography variant='overline' className={classes.bookmarkTitle}>
            Shared Bookmarks
          </Typography>
          {allSharedBookmarks.map((bookmark: Bookmark, index: number) => {
            return (
              <BookmarkButton
                key={index}
                isSelected={bookmark.id === bookmarkState.selectedBookmark?.id}
                isOwner={false}
                onClick={() => {
                  setActiveBookmark(bookmark)
                }}
                onEdit={onEdit}
                onDelete={handleBookmarkDelete}
                onUpdate={e => {
                  handleUpdate(bookmark)
                }}
                bookmark={bookmark}
                drawerWidth={drawerWidth || 260}
                onAddToFolder={(bookmark: Bookmark, folder: Folder) => {
                  handleAddToFolder(bookmark, folder)
                }}
              >
                {bookmark.name}
              </BookmarkButton>
            )
          })}
        </Stack>
      )}

      {ownedBookmarks.length > 0 && (
        <Stack direction='column' alignContent='center' sx={{ width: '100%' }}>
          <Typography variant='overline' className={classes.bookmarkTitle}>
            Owned Bookmarks
          </Typography>
          {ownedBookmarks.map((bookmark: Bookmark, index: number) => {
            return (
              <BookmarkButton
                key={index}
                isSelected={bookmark.id === bookmarkState.selectedBookmark?.id}
                isOwner={true}
                onClick={() => {
                  setActiveBookmark(bookmark)
                }}
                onEdit={onEdit}
                onDelete={handleBookmarkDelete}
                onUpdate={e => {
                  handleUpdate(bookmark)
                }}
                bookmark={bookmark}
                drawerWidth={drawerWidth || 260}
                onAddToFolder={(bookmark: Bookmark, folder: Folder) => {
                  handleAddToFolder(bookmark, folder)
                }}
              >
                {bookmark.name}
              </BookmarkButton>
            )
          })}
        </Stack>
      )}
    </List>
  )
}

type AllBookmarks = {
  licenseBookmarks: Bookmark[]
  ownedBookmarks: Bookmark[]
  sharedBookmarks: Bookmark[]
}

export type BookmarkSelectorProps = {
  bookmarks: AllBookmarks
  bookmarksLoading: boolean
  reportId: string
  licenseId: string
  onAddClick: () => void
  onEdit: (bookmark: Bookmark) => void
  drawerWidth: number
}

export function BookmarkButton(props: BookmarkButtonProps) {
  const {
    isSelected,
    isOwner,
    onClick,
    onEdit,
    onDelete,
    onUpdate,
    onAddToFolder,
    children,
    bookmark,
    drawerWidth,
  } = props
  const classes = useStyles()

  let isShared = useGetBookmarkMember(bookmark?.id).data

  const [isHovered, setIsHovered] = useState(false)

  const [isOverflowOpen, setIsOverflowOpen] = useState(false)

  const [showTooltip, setShowTooltip] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    if (isOverflowOpen) {
      setShowTooltip(false)
      return
    }
    let timer
    if (isHovered) {
      timer = setTimeout(() => {
        setShowTooltip(true)
      }, 1000)
    } else {
      clearTimeout(timer)
      setShowTooltip(false)
    }
    return () => clearTimeout(timer)
  }, [isHovered, isOverflowOpen])

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleClick = event => {
    event.preventDefault()
    onClick()
  }

  const handleEdit = () => {
    onEdit(bookmark)
  }

  const handleDelete = () => {
    onDelete(bookmark.id)
  }

  const handleUpdate = () => {
    onUpdate(bookmark)
  }

  const [anchorEl, setAnchorEl] = useState<React.RefObject<HTMLButtonElement>>()

  const [isFolderOpen, setIsFolderOpen] = useState(false)

  const handleFolderClick = (newRef: React.RefObject<HTMLButtonElement>) => {
    setAnchorEl(newRef)
  }
  const handleFolderClose = () => {
    setAnchorEl(null)
    setIsFolderOpen(false)
    setIsOverflowOpen(false)
  }

  const id = isFolderOpen ? 'simple-popover' : undefined

  const icon = getIconForBookmark(bookmark)

  return (
    <>
      <Popover
        id={id}
        open={isFolderOpen}
        anchorEl={anchorEl?.current}
        onClose={handleFolderClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: { sx: { borderRadius: '8px' } },
        }}
      >
        <AddToFolderOptions
          bookmarkId={bookmark?.id}
          handleAddToFolder={(folder: Folder, newFolderName?: string) => {
            onAddToFolder && onAddToFolder(bookmark, folder, newFolderName)
            handleFolderClose()
          }}
          handleFolderClose={() => {
            handleFolderClose()
          }}
        />
      </Popover>
      <ListItem
        ref={ref}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={cs(classes.bookmarkSelector, {
          [classes.bookmarkSelected]: isSelected,
          [classes.selected]: isSelected,
        })}
      >
        <ListItemIcon
          className={cs(classes.bookmarkSelectorIcon, {
            [classes.selected]: isSelected,
          })}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={children}
          sx={{
            width: isSelected ? '144px' : '180px',
            whiteSpace: 'nowrap',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        />
        {isShared && !(isHovered || isSelected) && (
          <ListItemIcon className={cs(classes.sharedIcon)}>
            <ReplyAll />
          </ListItemIcon>
        )}
        {(isHovered || isSelected) && bookmark && (
          <OverflowMenu
            isOpen={isOverflowOpen}
            setIsOpen={setIsOverflowOpen}
            {...(isOwner && {
              onDelete: e => {
                e.stopPropagation()
                handleDelete()
              },
              onEdit: e => {
                e.stopPropagation()
                handleEdit()
              },
              onUpdate: e => {
                e.stopPropagation()
                handleUpdate()
              },
            })}
            onAddToFolderClicked={_e => {
              setIsOverflowOpen(false)
              setIsFolderOpen(true)
            }}
            setRef={handleFolderClick}
            editButtonName={'Edit'}
            deleteButtonName={'Delete'}
            sx={{
              padding: 4,
            }}
          />
        )}
        {bookmark && ref?.current && (
          <Popover
            open={showTooltip}
            anchorEl={ref?.current || null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            sx={{
              pointerEvents: 'none',
              zIndex: 9999,
              color: 'transparent',
            }}
          >
            <Box
              sx={{
                p: 1,
                width: drawerWidth - 30 < 200 ? 200 : drawerWidth - 30,
                overflow: 'wrap',
                backgroundColor: 'rgba(0,0,0,0.6)',
                whiteSpace: 'wrap',
                // borderRadius: '8px',
                color: 'white',
              }}
            >
              {bookmark?.owner && (
                <Typography
                  fontSize={12}
                >{`Created by: ${bookmark?.owner}`}</Typography>
              )}
              {bookmark?.createdDate && (
                <Typography fontSize={12}>{`Created on: ${new Date(
                  bookmark?.createdDate
                ).toLocaleDateString()}`}</Typography>
              )}
              {bookmark?.description && (
                <Typography
                  fontSize={12}
                >{`Description: ${bookmark?.description}`}</Typography>
              )}
            </Box>
          </Popover>
        )}
      </ListItem>
    </>
  )
}

type AddToFolderOptionsProps = {
  handleAddToFolder: (folder: Folder, newFolderName?: string) => void
  handleFolderClose: () => void
  bookmarkId: string
}

function AddToFolderOptions(props: AddToFolderOptionsProps) {
  const { handleAddToFolder, handleFolderClose, bookmarkId } = props
  const [isAddingFolder, setIsAddingFolder] = useState(false)
  const { data: folders } = useGetFolders()
  const [newFolderName, setNewFolderName] = useState('')

  const filteredFolders = folders?.filter(
    folder => !folder?.items?.some(item => item?.id === bookmarkId)
  )

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='flex-start'
      spacing={1}
      sx={{ p: 1, maxHeight: '280px', overflowY: 'auto', minWidth: '240px' }}
    >
      <Typography variant='body2' fontWeight='bold' sx={{ pl: 1 }}>
        Add to Folder
      </Typography>
      <Divider
        sx={{
          width: '100%',
        }}
      />
      <Stack
        onClick={() => {
          setIsAddingFolder(true)
        }}
        direction={'row'}
        justifyContent={'flex-start'}
        sx={{
          cursor: 'pointer',
          borderRadius: '8px',
          width: '100%',
          p: 1,
          '&:hover': {
            backgroundColor: theme => alpha(theme.palette.primary.main, 0.2),
          },
          border: theme =>
            !isAddingFolder
              ? `1px dashed ${theme.palette.primary.main}`
              : 'none',
        }}
      >
        {isAddingFolder ? (
          <>
            <TextField
              autoFocus
              label='Folder Name'
              variant='standard'
              size='small'
              sx={{ width: '100%' }}
              value={newFolderName}
              onChange={e => setNewFolderName(e.target.value)}
              //if enter key is pressed, add folder
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setIsAddingFolder(false)
                  handleAddToFolder(
                    {
                      id: '',
                      name: newFolderName,
                    } as Folder,
                    newFolderName
                  )
                  handleFolderClose()
                }
              }}
              InputProps={{
                endAdornment: (
                  <Typography
                    onClick={() => {
                      setIsAddingFolder(false)
                      handleAddToFolder(
                        {
                          id: '',
                          name: newFolderName,
                        } as Folder,
                        newFolderName
                      )
                      handleFolderClose()
                    }}
                    sx={{
                      fontSize: '14px',
                      mb: 1,
                      cursor: 'pointer',
                      color: theme => theme.palette.primary.main,
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    Confirm
                  </Typography>
                ),
              }}
            />
          </>
        ) : (
          <>
            <Typography
              fontStyle={'italic'}
              sx={{
                cursor: 'pointer',
              }}
            >
              Create New
            </Typography>
            <Icon>
              <AddOutlinedIcon />
            </Icon>
          </>
        )}
      </Stack>

      {filteredFolders?.map((folder, index) => (
        <Box
          key={index}
          onClick={() => {
            handleAddToFolder(folder)
            handleFolderClose()
          }}
          sx={{
            cursor: 'pointer',
            width: '100%',
            borderRadius: '8px',
            p: 1,
            '&:hover': {
              backgroundColor: theme => alpha(theme.palette.primary.main, 0.2),
            },
          }}
        >
          <Typography>{folder.name}</Typography>
        </Box>
      ))}
    </Stack>
  )
}

export type BookmarkButtonProps = {
  isSelected: boolean
  onClick: () => void
  isOwner: boolean
  onEdit?: (bookmark: Bookmark) => void
  onDelete?: (bookmarkId: string) => void
  onUpdate?: (bookmark: Bookmark) => void
  onAddToFolder?: (
    bookmark: Bookmark,
    folder: Folder,
    newFolderName?: string
  ) => void
  children: string
  drawerWidth?: number
  bookmark?: Bookmark
}

export function AddBookmark(props: AddBookmarkProps) {
  const { onClick } = props

  return (
    <Box sx={{ mt: '64px' }}>
      <BookmarkButton isSelected={false} isOwner={true} onClick={onClick}>
        Add Bookmark
      </BookmarkButton>
    </Box>
  )
}

export type AddBookmarkProps = {
  onClick: () => void
}
