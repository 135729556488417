import {useParams} from 'react-router-dom'

export type UrlParams = {
  company: string
}

export const urlParamNames: Array<keyof UrlParams> = ['company']

/** A strongly typed version of useParams() from react-route */
export function useUrlParams(): UrlParams {
  return useParams<UrlParams>()
}
