import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {groupApi} from '../../api-interface'
import {licenseAtom} from '../../state'

export const getGroupsKey = 'get-groups'

export function useGetGroups() {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getGroupsKey, licenseId],
    () => groupApi.getGroups(licenseId),
    {
      enabled: !!licenseId,
    }
  )
}
