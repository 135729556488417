import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import { workspacesApi} from '../../api-interface'
import {licenseAtom} from '../../state'

export const getCapacitiesKey = 'get-capacities'

export function useGetCapacities(
  appRegNodeId: string,
  appClientId: string,
  appRegTenantId: string,
  profileId: string,  
) {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getCapacitiesKey, licenseId],
    () => workspacesApi.getCapacities(licenseId, appRegNodeId, appClientId, appRegTenantId, profileId),
    {
      enabled: !!licenseId,
    }
  )
}
