import { useState } from 'react'
import {
  Box,
  CircularProgress,
  Stack,
  Theme,
  Typography,
  Divider,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { LinkedItemChip, LinkedItemChips } from '../../shared'
import { Workspace } from '../../../../models'
import { NoResultsText } from '../../../../components'
import { useGetWorkspaceUsers, useRemoveWorkspaceUser } from '../../../../hooks'
import { SearchAndPage } from '../../shared/existing-items/search-and-page'

const PAGE_SIZE = 10

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

export function LinkedUsers(props: LinkedUsersProps) {
  const { workspace } = props
  const classes = useStyles()
  const [userSearchQuery, setUserSearchQuery] = useState('')
  const [userPage, setUserPage] = useState(1)

  const { mutate: removeUser } = useRemoveWorkspaceUser()
  const { data: linkedUsers, isLoading: loadingWorkspaces } =
    useGetWorkspaceUsers(workspace.id)

  function handleDelete(userId: string) {
    removeUser({ workspaceId: workspace.id, userId })
  }

  const filteredUsers =
    linkedUsers?.filter(
      user =>
        user.firstName.toLowerCase().includes(userSearchQuery.toLowerCase()) ||
        user.lastName.toLowerCase().includes(userSearchQuery.toLowerCase()) ||
        (user.email &&
          user.email.toLowerCase().includes(userSearchQuery.toLowerCase()))
    ) || []

  const usersTotalPages = Math.ceil(filteredUsers.length / PAGE_SIZE)

  const paginatedUsers = filteredUsers.slice(
    (userPage - 1) * PAGE_SIZE,
    userPage * PAGE_SIZE
  )

  function handleUserNextPage() {
    setUserPage(prevPage => prevPage + 1)
  }

  function handleUserPrevPage() {
    setUserPage(prevPage => Math.max(prevPage - 1, 1))
  }

  if (loadingWorkspaces) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    )
  }

  const handleUserSearch = searchText => {
    setUserSearchQuery(searchText)
    setUserPage(1) // Reset page to 1 when searching
  }

  const renderUserSearchAndPage = () => {
    return (
      <SearchAndPage
        show={linkedUsers.length > 0}
        pageNumber={userPage}
        totalPagesAvailable={usersTotalPages}
        placeHolderText='Search Users'
        onSearch={handleUserSearch}
        onPrevPage={handleUserPrevPage}
        onNextPage={handleUserNextPage}
      />
    )
  }

  return (
    <Stack
      justifyContent='space-between'
      direction='column'
      sx={{ width: '100%' }}
    >
      <Box sx={{ px: 2, mt: 1.5 }}>
        <Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
          Users
        </Typography>
        <Divider sx={{ mb: 1 }} />
        {renderUserSearchAndPage()}
        {linkedUsers.length === 0 ? (
          <NoResultsText>This workspace has no linked users</NoResultsText>
        ) : (
          <LinkedItemChips>
            {paginatedUsers.map(user => (
              <LinkedItemChip
                key={user.id}
                label={`${user.firstName} ${user.lastName}${
                  user.email ? ` (${user.email})` : ''
                }`}
                onDelete={() => handleDelete(user.id)}
              />
            ))}
          </LinkedItemChips>
        )}
      </Box>
    </Stack>
  )
}

export type LinkedUsersProps = {
  workspace: Workspace
}
