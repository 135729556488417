import { CircularProgress, Grid, Stack, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { ReportTile } from './report-tile'
import { useUpdateFolder } from '../../../hooks/mutations/useUpdateFolder'
import { useCreateFolder } from '../../../hooks/mutations/useCreateFolder'
import { Folder } from '../../../models'

const useStyles = makeStyles((theme: Theme) => ({
  noReports: {
    marginTop: theme.spacing(10),
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
}))

export function ReportTiles(props: ReportTileProps) {
  const { isLoading, reports, title, subtitle, folders } = props
  const classes = useStyles()

  const { mutateAsync: updateFolder } = useUpdateFolder()
  const { mutateAsync: createFolder } = useCreateFolder()

  const handleAddToFolder = async (
    folderId: string,
    report: any,
    newFolderName?: string
  ) => {
    if (!report.id) {
      return
    }

    if (newFolderName && folderId === '') {
      await createFolder({
        folderName: newFolderName,
        items: [
          { id: report.id, type: report.type || 'report', name: report.name },
        ],
      })
      return
    }

    const folder = folders.find(folder => folder.id === folderId)
    if (!folder) return
    await updateFolder({
      folderId: folder.id,
      items: [
        ...folder.items,
        { id: report.id, type: report.type || 'report', name: report.name },
      ],
    })
  }

  const handleFavorite = async (
    isFavorite: boolean,
    report: any //TODO: Add type
  ) => {
    const favoritesFolder = folders.find(folder => folder.isFavorites)

    if (favoritesFolder && favoritesFolder.id === favoritesFolder.name) {
      return
    }

    if (favoritesFolder) {
      await updateFolder({
        folderId: favoritesFolder.id,
        items: isFavorite
          ? favoritesFolder?.items?.filter(item => item.id !== report.id)
          : [
              ...favoritesFolder.items,
              {
                id: report.id,
                type: report?.type || 'report',
                name: report.name,
              },
            ],
      })
      return
    }
    await createFolder({
      folderName: 'Favorites',
      isFavorites: true,
      items: [{ id: report.id, type: 'report', name: report.name }],
    })
  }

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    )
  }

  if (!reports || !reports.length) {
    return (
      <Typography className={classes.noReports} variant='body1'>
        No reports to display
      </Typography>
    )
  }

  return (
    <Stack
      sx={{
        width: '100%',
        maxWidth: '1764px',
        mt: 5.5,
      }}
    >
      <Stack direction='row' alignItems='baseline' gap={1} sx={{ mb: 2 }}>
        <Typography variant='h5' sx={{ fontWeight: 600, display: 'block' }}>
          {title}
        </Typography>
        {subtitle && (
          <Typography sx={{ fontWeight: 400 }}>({subtitle})</Typography>
        )}
      </Stack>

      <Grid spacing={6} alignContent={'center'} columns={6} container>
        {reports.map(report => {
          return (
            <Grid
              item
              xs={6}
              md={3}
              lg={2}
              key={`${title}-${report.id + report.name} - grid`}
            >
              <ReportTile
                key={`${title}-${report.id + report.name}`}
                title={report.name}
                description={report.description}
                id={report.id}
                type={report.type}
                isFavorite={folders
                  ?.find(folder => folder.isFavorites)
                  ?.items?.some((item: any) => item.id === report.id)}
                handleFavoritesClicked={() => {
                  handleFavorite(
                    folders
                      ?.find(folder => folder.isFavorites)
                      ?.items?.some((item: any) => item.id === report.id),
                    report
                  )
                }}
                folders={folders?.filter(
                  folder => !folder?.items.some(item => item.id === report.id)
                )}
                handleAddToFolder={(
                  folderId: string,
                  newFolderName?: string
                ) => {
                  handleAddToFolder(folderId, report, newFolderName)
                }}
              />
            </Grid>
          )
        })}
      </Grid>
    </Stack>
  )
}

type ReportTileProps = {
  isLoading: boolean
  title: string
  reports: any[]
  subtitle?: string
  folders: Folder[]
}
