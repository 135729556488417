import {Skeleton, Stack} from '@mui/material'

type bookmarksSkeletonProps = {
  numberOfBookmarks?: number
}

export function BookmarksSkeleton(props: bookmarksSkeletonProps) {
  const {numberOfBookmarks} = props
  return (
    <Stack direction='column' width='240px' gap={3} mt={2} ml={0.5}>
      {Array.from(Array(numberOfBookmarks || 10).keys()).map((_, index) => (
        <Stack
          key={index}
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          sx={{
            height: '40px',
            width: '100%',
            padding: '4px',
            border: '1px solid transparent',
          }}
        >
          <Skeleton
            sx={{
              width: '24px',
              height: '32px',
              borderRadius: '16%',
            }}
          />
          <Skeleton
            sx={{
              ml: 1,
              height: '40px',
              width: '180px',
            }}
          />
        </Stack>
      ))}
    </Stack>
  )
}
