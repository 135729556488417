import {
  AdvancedFilterType,
  BasicFilterType,
  DataColumnWithFilters,
  FILTER_OPTIONS,
  FILTER_TYPES,
} from '../custom-report-table'

export const formatName = (name: string): string => {
  const newName = name.replaceAll(' ', '_20_')
  //if name starts with _ replace it with x_5f_
  if (newName.startsWith('_')) {
    return 'x_5f' + newName
  }
  return newName
}

export const replaceSpacesWithUnderscores = (name: string): string => {
  const newName = name.replaceAll(' ', '_')
  //if name starts with _ replace it with x_5f_
  if (newName.startsWith('_')) {
    return 'x_5f' + newName
  }
  return newName
}

export const basicFilterExists = (filter: BasicFilterType): boolean => {
  return (
    filter?.operator !== '' &&
    (!filterRequiresValue(filter?.operator) || filter?.value?.length > 0)
  )
}

export const advancedFilterExists = (
  filters: AdvancedFilterType[]
): boolean => {
  return (
    filters.length > 0 &&
    filters.some(
      filter =>
        filter.operator !== '' &&
        (!filterRequiresValue(filter.operator) || filter.value)
    )
  )
}

export const filterExists = (filter: DataColumnWithFilters): boolean => {
  switch (filter.filterType) {
    case FILTER_TYPES.BASIC:
      return basicFilterExists(filter.basicFilter)
    case FILTER_TYPES.ADVANCED:
      return advancedFilterExists(filter.advancedFilters)
    default:
      return false
  }
}

const booleanFilterOptions = [
  'is blank',
  'is not blank',
  'is empty',
  'is not empty',
]

export const filterRequiresValue = (filterOption: string) => {
  return !booleanFilterOptions.includes(filterOption)
}

const operatorMap = {
  contains: 'contains',
  'does not contain': 'notcontains',
  'starts with': 'startswith',
  'ends with': 'endswith',
  is: '=',
  'is not': '!=',
  'is blank': 'isblank',
  'is not blank': 'isnotblank',
  'is empty': 'isempty',
  'is not empty': 'isnotempty',
  'is less than': '<',
  'is greater than': '>',
  'is less than or equal to': '<=',
  'is greater than or equal to': '>=',
  'is before': '<',
  'is after': '>',
  'is on or before': '<=',
  'is on or after': '>=',
}

export function getOperatorSymbol(operator: string) {
  if (operatorMap.hasOwnProperty(operator)) {
    return operatorMap[operator]
  }
  throw new Error(`Unsupported operator: ${operator}`)
}

export function mapFilterToDaxClause(
  columnRef: string,
  colValue: any,
  operator: string,
  dataType: number
): string {
  const isString = typeof colValue === 'string'
  const isDateType = dataType === FILTER_OPTIONS.DATE_TIME
  const value = isString && !isDateType ? `"${colValue}"` : colValue

  switch (operator) {
    case 'contains':
      return `SEARCH(${value}, ${columnRef}, 1, 0) >= 1`
    case 'does not contain':
      return `SEARCH(${value}, ${columnRef}, 1, 0) < 1`
    case 'starts with':
      return `LEFT(${columnRef}, LEN(${value})) = ${value}`
    case 'ends with':
      return `RIGHT(${columnRef}, LEN(${value})) = ${value}`
    case 'is':
      return `${columnRef} = ${value}`
    case 'is not':
      return `${columnRef} <> ${value}`
    case 'is blank':
      return `ISBLANK(${columnRef})`
    case 'is not blank':
      return `NOT ISBLANK(${columnRef})`
    case 'is empty':
      return `${columnRef} = ""`
    case 'is not empty':
      return `${columnRef} <> ""`
    case 'is less than':
      return `${columnRef} < ${value}`
    case 'is greater than':
      return `${columnRef} > ${value}`
    case 'is less than or equal to':
      return `${columnRef} <= ${value}`
    case 'is greater than or equal to':
      return `${columnRef} >= ${value}`

    case 'is before':
      return `${columnRef} < ${value}`
    case 'is after':
      return `${columnRef} > ${value}`
    case 'is on or before':
      return `${columnRef} <= ${value}`
    case 'is on or after':
      return `${columnRef} >= ${value}`
    default:
      throw new Error(`Unsupported operator: ${operator}`)
  }
}
