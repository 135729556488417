import React, { useEffect, useState } from 'react'

import { SelectAndAuthenticateReport } from './select-and-authenticate'
import { ProfileAndAppReg } from '../../../admin/tabs/reports/add-report-form/types'
import { useRecoilValue } from 'recoil'
import {
  StatusMessageInfo,
  WorkspaceDatasetInfo,
  WorkspaceInfo,
} from '../../../../models'
import { CircularProgress, Theme } from '@mui/material'
import CustomDialog from '../../../admin/shared/dialog/dialog'
import { makeStyles, createStyles } from '@mui/styles'
import { workspacesApi } from '../../../../api-interface/workspace-api'
import { licenseAtom } from '../../../../state'
import { useCreateReport } from '../../../../hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    saveButton: {
      marginTop: theme.spacing(1),
    },
    cancelButton: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    formContent: {
      width: '100%',
      maxWidth: '1000px',
    },
    error: {
      width: '100%',
      color: 'red',
    },
    formBox: {
      minWidth: '300px',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
  })
)

type AddCustomReportFormProps = {
  onClose: () => void
  open: boolean
  onSave: () => void
}

export function AddCustomReportForm(props: AddCustomReportFormProps) {
  const { onClose, open, onSave } = props

  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [profile, setProfile] = React.useState<ProfileAndAppReg | null>(null)
  const [dataset, setDataset] = React.useState<WorkspaceDatasetInfo | null>(
    null
  )
  const [name, setName] = React.useState<string | null>(null)
  const [description, setDescription] = React.useState<string | null>(null)

  const [status, setStatus] = useState<StatusMessageInfo>(null)

  const licenseId = useRecoilValue(licenseAtom)

  const [workspace, setWorkspace] = React.useState<WorkspaceInfo | null>(null)

  const classes = useStyles()

  const { mutateAsync: createReport, isLoading: creatingReport } =
    useCreateReport()

  const [checkingDataset, setCheckingDataset] = useState(false)

  const [isGrantingAccess, setIsGrantingAccess] = useState(false)

  const isLoadingSomething = isGrantingAccess || checkingDataset || isLoading

  const [perspectives, setPerspectives] = useState<string[]>([])
  const [perspective, setPerspective] = useState<string | null>(null)

  async function datasetIncludesPerspectives() {
    const uniquePerspectives = await getPerspectivesForDataset()
    setPerspectives(uniquePerspectives || [])
    return uniquePerspectives?.length > 0
  }

  const getPerspectivesForDataset = async () => {
    if (!profile || !workspace || !dataset) return

    const perspectives = await workspacesApi.getPerspectives(
      {
        appRegNodeId: profile.appRegNodeId,
        datasetId: dataset.id,
        workspaceId: workspace.id,
      },
      licenseId
    )

    return perspectives
  }

  useEffect(() => {
    const checkDataset = async () => {
      setCheckingDataset(true)
      const datasetHasData = await datasetIncludesPerspectives()
      setCheckingDataset(false)
      if (!datasetHasData) {
        setStatus({
          title: 'No data found',
          type: 'error',
          message:
            'Either the dataset is empty or it has not been formatted correctly',
        })
        return
      }

      setStatus(null)
    }
    checkDataset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset])

  async function handleSave() {
    if (!profile || !workspace || !dataset) return

    const reportId = (
      await createReport({
        pbiWorkspaceId: workspace.id,
        appRegistrationNodeId: profile.appRegNodeId,
        appRegistrationName: profile.appRegName,
        profileId: profile.id,
        profileName: profile.name,
        datasetId: dataset.id,
        licenseNodeId: licenseId,
        name: name,
        description: description || '',
        type: 'custom',
        perspective: perspective,
      })
    )?.id as string

    if (!reportId) {
      return
    }

    window.history.pushState(null, '', `/reports/${reportId}`)
    onClose()
  }

  const renderSelectAndAuthenticateReport = () => {
    return (
      <SelectAndAuthenticateReport
        profile={profile}
        setProfile={setProfile}
        checkingDataset={checkingDataset}
        workspace={workspace}
        setWorkspace={setWorkspace}
        dataset={dataset}
        setDataset={setDataset}
        status={status}
        setStatus={setStatus}
        isGrantingAccess={isGrantingAccess}
        setIsGrantingAccess={setIsGrantingAccess}
        setIsLoading={setIsLoading}
        setIsSaving={setIsSaving}
        onClose={onClose}
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        perspectives={perspectives}
        setPerspective={setPerspective}
        perspective={perspective}
      />
    )
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={`Create Custom Report`}
      primaryButtonProps={{
        children: 'Confirm',
        disabled:
          !profile ||
          !workspace ||
          !dataset ||
          status?.type === 'error' ||
          isLoadingSomething ||
          isSaving ||
          creatingReport ||
          !name ||
          (perspectives?.length > 1 && !perspective),
        endIcon:
          isSaving || creatingReport ? <CircularProgress size={20} /> : null,
        onClick: async () => {
          handleSave()
        },
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault()
          onSave()
        }}
      >
        <div className={classes.formContent}>
          {renderSelectAndAuthenticateReport()}
        </div>
      </form>
    </CustomDialog>
  )
}
