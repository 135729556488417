import { DataGrid } from '@mui/x-data-grid'
import { Box, cardHeaderClasses, Theme } from '@mui/material'
import { DataColumn } from '../../../../models'
import { DataColumnTypes } from '../generate-rdl-file/types'
import { makeStyles, createStyles } from '@mui/styles'

type CustomTableProps = {
  columns: DataColumn[]
  rows: any[]
  onFilterModelChange: (model: any) => void
  onSortModelChange: (model: any) => void
  loading: boolean
  filterOptions: DataColumn[]
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tableHeaders: {
        fontWeight: 'bold',
        fontSize: '14px',
      },
    }),
  { name: 'loading-button' }
)

export default function CustomTable(props: CustomTableProps) {
  const {
    columns,
    rows,
    onFilterModelChange,
    onSortModelChange,
    loading,
    filterOptions,
  } = props

  const classes = useStyles()

  return (
    <Box
      style={{
        width: '100%',
        minWidth: '500px',
        minHeight: 500,
        height: '100%',
      }}
    >
      <DataGrid
        sx={{
          height: '100%',
          minHeight: 500,
        }}
        rowHeight={28}
        columnHeaderHeight={32}
        autosizeOnMount={true}
        columns={columns?.map(col => ({
          field: col.id.replaceAll(/['"]/g, ''),
          headerName: col.name,
          width: 116,
          cardHeaderClasses: cardHeaderClasses,
          sortable: true,
          filterable:
            col.type !== DataColumnTypes.MEASURE &&
            !filterOptions.find(filter => filter.id === col.id),
          //Styles the headers to be bold:
          headerClassName: classes.tableHeaders,
        }))}
        scrollbarSize={12}
        loading={loading}
        disableColumnSelector
        slotProps={{
          loadingOverlay: {
            color: 'rgba(0, 0, 0, 0)',
          },
        }}
        onFilterModelChange={model => {
          onFilterModelChange(model)
        }}
        onSortModelChange={model => {
          onSortModelChange(model)
        }}
        rows={rows}
      />
    </Box>
  )
}

// const exampleCols = [
//   {
//     dataType: 8,
//     id: '[Sum of Gross Sales]',
//     name: 'Sum of Gross Sales',
//     table: 'Fact Sales',
//     type: 'measure',
//   },
//   {
//     dataType: 2,
//     id: "'Dim Date'[Day Name]",
//     name: 'Day Name',
//     table: 'Dim Date',
//     type: 'column',
//   },
//   {
//     dataType: 2,
//     id: "'Dim Product'[Product]",
//     name: 'Product',
//     table: 'Dim Product',
//     type: 'column',
//   },
// ]
