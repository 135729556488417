import {useQuery, UseQueryOptions} from 'react-query'
import {useRecoilValue} from 'recoil'
import {userApi} from '../../api-interface'
import {User} from '../../models'
import {licenseAtom} from '../../state'

export const getOtherUsersOnLicenseKey = 'get-other-users-on-license-key'

export function useGetOtherUsersOnLicense(
  options: UseQueryOptions<User[]> = {}
) {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getOtherUsersOnLicenseKey, licenseId],
    () => userApi.getLicenseUsers(licenseId),
    {
      ...options,
      enabled:
        typeof options.enabled === 'boolean' ? options.enabled : !!licenseId,
    }
  )
}
