import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { folderApi } from '../../api-interface'
import { licenseAtom } from '../../state'

export const getFoldersKey = 'get-folders'

export function useGetFolders() {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getFoldersKey, licenseId],
    () => folderApi.getFoldersForUser(licenseId),
    {
      enabled: !!licenseId,
    }
  )
}
