import React, {useState} from 'react'
import {Form, FormField} from '../../../shared'
import {NewUser, User} from '../../../../../models'
import {useCreateUser, useUpdateUser} from '../../../../../hooks'
import {AddUserContentProps} from '../add-user-form'

export function SingleUserForm(props: SingleUserFormProps) {
  const {
    onClose,
    onSave,
    isEdit,
    userToEdit,
    setToastOpen,
    setToastMessage,
    setToastVariant,
  } = props

  const {mutateAsync: createUser, isLoading: isSaving} = useCreateUser()
  const {mutateAsync: updateUser, isLoading: isSavingEdit} = useUpdateUser()

  const [firstName, setFirstName] = useState(userToEdit?.firstName ?? '')
  const [lastName, setLastName] = useState(userToEdit?.lastName ?? '')
  const [email, setEmail] = useState(userToEdit?.email ?? '')
  const [userRole, setUserRole] = useState<User['userRole']>(
    userToEdit?.userRole ?? 'viewer'
  )
  const [error, setError] = useState(null)

  async function handleSave() {
    try {
      let newUser: NewUser = {firstName, lastName, email, userRole}

      if (!isEdit) {
        let data = {
          users: [newUser],
        }
        await createUser(data)
      } else {
        let data = {
          user: newUser,
        }
        await updateUser(data)
      }
      onSave()
      setToastMessage('User saved successfully')
      setToastVariant('success')
      setToastOpen(true)
    } catch (e) {
      setToastMessage('Error saving user')
      setToastVariant('error')
      setToastOpen(true)
      setError(e.message)
    }
  }

  return (
    <Form
      onSave={handleSave}
      onCancel={onClose}
      isSaving={isSaving || isSavingEdit}
      error={error}
    >
      <FormField
        label='First Name'
        value={firstName}
        onTextChange={setFirstName}
      />
      <FormField
        label='Last Name'
        value={lastName}
        onTextChange={setLastName}
      />
      <FormField
        label='Email Address'
        value={email}
        disabled={isEdit}
        onTextChange={text => setEmail(text.toLowerCase())}
      />
      <FormField
        label='User Role'
        value={userRole}
        onTextChange={setUserRole as any}
        helperText='The level of permission that this user will have on your Intelexos license'
        selectOptions={{viewer: 'Viewer', admin: 'Admin'}}
      />
    </Form>
  )
}

type SingleUserFormProps = AddUserContentProps & {
  userToEdit?: User
  isEdit?: boolean
}
