import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { ApiReport } from '../../../../models'
import { CircularProgress, Skeleton, Theme } from '@mui/material'
import CustomDialog from '../../../admin/shared/dialog/dialog'
import { makeStyles, createStyles } from '@mui/styles'
import { licenseAtom } from '../../../../state'
import { useGetAppRegs, useUpdateReport } from '../../../../hooks'
import { FormField } from '../../shared'
import { ProfileAndAppReg } from './add-report-form/types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    saveButton: {
      marginTop: theme.spacing(1),
    },
    cancelButton: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    formContent: {
      width: '100%',
      maxWidth: '1000px',
    },
    error: {
      width: '100%',
      color: 'red',
    },
    formBox: {
      minWidth: '300px',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
  })
)

type AddCustomReportFormProps = {
  onClose: () => void
  open: boolean
  onSave: () => void
  report?: ApiReport
}

export function AddCustomReportForm(props: AddCustomReportFormProps) {
  const { onClose, open, onSave, report } = props

  const [name, setName] = React.useState<string | null>(report?.name || null)
  //   const [description, setDescription] = React.useState<string | null>(null)
  const [profile, setProfile] = useState<ProfileAndAppReg | null>(null)
  const { data: existingAppRegistrations, isLoading: loadingAppRegs } =
    useGetAppRegs()

  const classes = useStyles()

  useEffect(() => {
    if (report) {
      //get the profile for the report
      const currentAppRegistration = existingAppRegistrations?.find(
        appReg => appReg.id === report.appRegistrationNodeId
      )
      if (currentAppRegistration) {
        setProfile({
          id: report.profileId,
          name: report.profileName,
          appRegId: currentAppRegistration.appRegistrationId,
          appRegNodeId: currentAppRegistration.id,
          appRegName: currentAppRegistration.name,
          tenantId: currentAppRegistration.tenantId,
        })
      }
    }
  }, [existingAppRegistrations, report])

  const licenseId = useRecoilValue(licenseAtom)
  const { mutateAsync: updateReport, isLoading: isSaving } = useUpdateReport()

  async function handleSave() {
    await updateReport({
      ...report,
      licenseNodeId: licenseId,
      appRegistrationNodeId: profile?.appRegNodeId,
      name,
    })
    onSave()
    onClose()
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={(report ? 'Edit' : 'Create') + ` Custom Report`}
      primaryButtonProps={{
        children: 'Confirm',
        disabled: !name,
        endIcon: isSaving || isSaving ? <CircularProgress size={20} /> : null,
        onClick: async () => {
          handleSave()
        },
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault()
          onSave()
        }}
      >
        <div className={classes.formContent}>
          {loadingAppRegs ? (
            <Skeleton variant='rectangular' width={300} height={100} />
          ) : (
            <FormField
              label='Name'
              value={name || ''}
              helperText=''
              onTextChange={value => {
                setName(value)
              }}
            />
          )}
        </div>
      </form>
    </CustomDialog>
  )
}
