import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {groupApi} from '../../api-interface'
import {Group} from '../../models'
import {licenseAtom} from '../../state'
import {getGroupsKey, getReportsByUserKey} from '../queries'
import {getUserGroupsKey} from '../queries/useGetUserGroups'

type AddGroupsToUserPayload = {groupIds: string[]; userId: string}

export function useAddGroupsToUser() {
  const licenseId = useRecoilValue(licenseAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddGroupsToUserPayload) =>
      groupApi.addUserToGroups(payload.groupIds, payload.userId, licenseId),
    {
      async onMutate(payload: AddGroupsToUserPayload): Promise<Group[]> {
        await queryClient.cancelQueries([getUserGroupsKey, payload.userId])

        let allGroups: Group[] = queryClient.getQueryData(getGroupsKey)

        if (!allGroups) {
          allGroups = await queryClient.fetchQuery(getGroupsKey, () =>
            groupApi.getGroups(licenseId)
          )
        }

        const previousGroups: Group[] = queryClient.getQueryData([
          getUserGroupsKey,
          payload.userId,
        ])

        queryClient.setQueryData(
          [getUserGroupsKey, payload.userId],
          (old: Group[]) => {
            const newGroups = payload.groupIds.map(groupId =>
              allGroups.find(group => group.id === groupId)
            )

            return [...old, ...newGroups]
          }
        )

        return previousGroups
      },

      onError(err, variables, previousGroups) {
        queryClient.setQueryData(
          [getUserGroupsKey, variables.userId],
          previousGroups
        )
      },

      // Always refetch after error or success:
      onSettled() {
        queryClient.invalidateQueries(getGroupsKey)
        queryClient.invalidateQueries(getReportsByUserKey)
      },
    }
  )
}
