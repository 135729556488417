import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {bookmarkApi} from '../../api-interface'
import {Bookmark} from '../../models'
import {licenseAtom} from '../../state'
import {} from '../queries'
import { getBookmarkMembersKey } from '../queries/useGetBookmarkMember'

export const removeBookmarkMemberKey = 'remove-bookmark-member'

export type RemoveBookmarkMemberPayload = {bookmarkId: string}

/** Remove a Bookmark from a License */
export function useRemoveBookmarkMember() {
  const licenseId = useRecoilValue(licenseAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: RemoveBookmarkMemberPayload) =>
      bookmarkApi.removeBookmarkMember(
        payload.bookmarkId,
        licenseId
      ),
    {
      async onMutate(
        payload: RemoveBookmarkMemberPayload
      ): Promise<Bookmark[]> {
        await queryClient.cancelQueries([getBookmarkMembersKey, payload.bookmarkId])

        let previousMembers: Bookmark[] =
          queryClient.getQueryData([getBookmarkMembersKey, payload.bookmarkId])

        if (!previousMembers) {
          //
          previousMembers = await queryClient.fetchQuery(
            [getBookmarkMembersKey, payload.bookmarkId],
            () => bookmarkApi.getBookmarkMember(payload.bookmarkId, licenseId)
          )
        }

        queryClient.setQueryData(
          [getBookmarkMembersKey, payload.bookmarkId],
          (old: Bookmark[]) => {
            return old
          }
        )

        return previousMembers
      },
      onError(
        _err,
        payload: RemoveBookmarkMemberPayload,
        context: Bookmark[]
      ) {
        queryClient.setQueryData([getBookmarkMembersKey, payload.bookmarkId], context)
      },
    }
  )
}
