import { useState } from 'react'
import { UserOrGroup } from './types'
import { useGetGroups, useGetOtherUsersOnLicense } from '../../../../../hooks'
import { Divider, Icon, Skeleton, Stack, Typography } from '@mui/material'
import { SearchAndPage } from '../../../shared/existing-items/search-and-page'
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'

export type UserAndGroupSearchProps = {
  selectedItems: UserOrGroup[]
  setSelectedItems: (items: UserOrGroup[]) => void
  reportId: string
}

const PAGE_SIZE = 8

export function UserAndGroupSearch(props: UserAndGroupSearchProps) {
  // const {reportId} = props
  const { selectedItems, setSelectedItems } = props

  const [userSearchQuery, setUserSearchQuery] = useState('')
  const [userPage, setUserPage] = useState(1)
  const { data: users, isLoading: loadingUsers } = useGetOtherUsersOnLicense()
  // const {data: {users: linkedViewers} = {users: []}} =
  //   useGetReportViewers(reportId)

  // const linkedViewerIds = linkedViewers && linkedViewers.map(v => v.id)
  // const usersAvailableToAdd =
  //   users &&
  //   linkedViewerIds &&
  //   users.filter(u => !linkedViewerIds.includes(u.id))

  const { data: groups, isLoading: loadingGroups } = useGetGroups()
  // const {data: {groups: linkedGroups} = {groups: []}} =
  //   useGetReportViewers(reportId)

  // const linkedGroupIds = linkedGroups && linkedGroupIds.map(g => g.id)
  // const groupsAvailableToAdd =
  //   groups &&
  //   linkedGroupIds &&
  //   groups.filter(g => !linkedGroupIds.includes(g.id))

  const combinedUsersAndGroupsAvailableToAdd =
    groups
      ?.map(g => ({
        id: g.id,
        name: g.name,
        type: 'group',
      }))
      ?.concat(
        users?.map(u => ({
          id: u.email,
          name: `${u.firstName} ${u.lastName}`,
          email: u.email,
          type: 'user',
        })) || []
      ) || []

  const filteredUsersAndGroups =
    combinedUsersAndGroupsAvailableToAdd?.filter(userOrGroup =>
      userOrGroup?.name?.toLowerCase().includes(userSearchQuery.toLowerCase())
    ) || []

  const usersTotalPages = Math.ceil(filteredUsersAndGroups.length / PAGE_SIZE)

  const paginatedUsersAndGroups = filteredUsersAndGroups.slice(
    (userPage - 1) * PAGE_SIZE,
    userPage * PAGE_SIZE
  )

  const handleSearch = (searchText: string) => {
    setUserSearchQuery(searchText)
    setUserPage(1) // Reset page to 1 when searching
  }

  function handleUserNextPage() {
    setUserPage(prevPage => prevPage + 1)
  }

  function handleUserPrevPage() {
    setUserPage(prevPage => Math.max(prevPage - 1, 1))
  }

  const toggleSelection = (userOrGroup: UserOrGroup) => {
    if (selectedItems.find(item => item.id === userOrGroup.id)) {
      setSelectedItems(selectedItems.filter(item => item.id !== userOrGroup.id))
    } else {
      setSelectedItems([...selectedItems, userOrGroup])
    }
  }

  const UserSkeletons = Array.from({ length: PAGE_SIZE }).map((_, index) => (
    <Stack
      key={index}
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        height: '40px',
        width: '100%',
        padding: '4px',
        border: '1px solid transparent',
      }}
    >
      <Skeleton
        sx={{
          width: '24px',
          height: '40px',
          borderRadius: '50%',
        }}
      />
      <Skeleton
        sx={{
          ml: 2,
          height: '20px',
          width: '140px',
        }}
      />
    </Stack>
  ))

  const renderUserSearchAndPage = () => {
    return (
      <Stack direction='column'>
        <SearchAndPage
          show={combinedUsersAndGroupsAvailableToAdd.length > 0}
          pageNumber={userPage}
          totalPagesAvailable={usersTotalPages}
          placeHolderText='Search Users'
          onSearch={handleSearch}
          onPrevPage={handleUserPrevPage}
          onNextPage={handleUserNextPage}
        />
        <Divider
          sx={{
            mb: 1,
          }}
        />
        <Stack direction='column' minHeight='300px' gap={1}>
          <>
            {loadingUsers || loadingGroups ? (
              UserSkeletons
            ) : (
              <>
                {paginatedUsersAndGroups.map((userOrGroup: UserOrGroup) => {
                  const isSelected = selectedItems.some(
                    item => item.id === userOrGroup.id
                  )
                  return (
                    <Stack
                      key={userOrGroup.id}
                      direction='row'
                      justifyContent='flex-start'
                      alignItems='center'
                      sx={{
                        height: '36px',
                        width: '100%',
                        padding: '4px',
                        '&:hover': {
                          cursor: 'pointer',
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        },
                        borderRadius: '8px',
                        border: theme =>
                          isSelected
                            ? `1px solid ${theme.palette.secondary.light}`
                            : '1px solid transparent',
                      }}
                      onClick={() => toggleSelection(userOrGroup)}
                    >
                      <Icon
                        sx={{
                          width: '40px',
                          alignSelf: 'center',
                        }}
                      >
                        {userOrGroup.type === 'user' ? (
                          <PersonOutlineOutlinedIcon />
                        ) : (
                          <Groups2OutlinedIcon />
                        )}
                      </Icon>
                      <Typography>{userOrGroup.name}</Typography>
                    </Stack>
                  )
                })}
              </>
            )}
          </>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack
      justifyContent='space-between'
      direction='column'
      sx={{ width: '100%' }}
    >
      {renderUserSearchAndPage()}
    </Stack>
  )
}
