import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { folderApi } from '../../api-interface'
import { Folder } from '../../models'
import { licenseAtom } from '../../state'
import { getFoldersKey } from '../queries'

type DeleteFolderContext = {
  previousFolders: Folder[]
}

export function useDeleteFolder() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)
  return useMutation(
    (folderId: string) => folderApi.deleteFolder(folderId, licenseId),
    {
      async onMutate(folderId: string): Promise<DeleteFolderContext> {
        await queryClient.cancelQueries([getFoldersKey, licenseId])

        let previousFolders: Folder[] = queryClient.getQueryData([
          getFoldersKey,
          licenseId,
        ])

        queryClient.setQueryData([getFoldersKey, licenseId], (old: Folder[]) =>
          old.filter((folder: Folder) => folder.id !== folderId)
        )

        return { previousFolders }
      },
      onError(_err, _deletedFolder, context: DeleteFolderContext) {
        queryClient.setQueryData(
          [getFoldersKey, licenseId],
          context.previousFolders
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getFoldersKey, licenseId])
      },
    }
  )
}
