import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {workspacesApi} from '../../api-interface'
import {User} from '../../models'
import {licenseAtom} from '../../state'
import {getWorkspaceUsersKey} from '../queries'

export const removeWorkspaceUserKey = 'remove-workspace-user'

export type RemoveWorkspaceUserPayload = {workspaceId: string; userId: string}

/** Remove a viewer from a PBI report */
export function useRemoveWorkspaceUser() {
  const licenseId = useRecoilValue(licenseAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: RemoveWorkspaceUserPayload) =>
      workspacesApi.removeWorkspaceUser(payload.workspaceId, payload.userId, licenseId),
    {
      async onMutate(payload: RemoveWorkspaceUserPayload): Promise<User[]> {
        await queryClient.cancelQueries([getWorkspaceUsersKey, payload.workspaceId])

        let previousUsers: User[] = queryClient.getQueryData([
          getWorkspaceUsersKey,
          payload.workspaceId,
        ])

        if (!previousUsers) {
          previousUsers = await queryClient.fetchQuery(
            [getWorkspaceUsersKey, payload.workspaceId],
            () => workspacesApi.getWorkspaceUsers(payload.workspaceId, licenseId)
          )
        }

        queryClient.setQueryData(
          [getWorkspaceUsersKey, payload.workspaceId],
          (old: User[]) =>
            old.filter((oldUser: User) => oldUser.id !== payload.userId)
        )

        return previousUsers
      },
      onError(err, payload: RemoveWorkspaceUserPayload, previousUsers: User[]) {
        queryClient.setQueryData(
          [getWorkspaceUsersKey, payload.workspaceId],
          previousUsers
        )
      },
    }
  )
}
