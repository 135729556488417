import CustomDialog from '../../../shared/dialog/dialog'
import { CircularProgress, Typography } from '@mui/material'
import { Workspace } from '../../../../../models'

function DeleteWorkspaceDialog(props: DeleteWorkspaceDialogProps) {
  const { open, onClose, workspace, handleDelete, isDeleting } = props

  if (!workspace) return null

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={`Delete workspace?`}
      primaryButtonProps={{
        disabled: isDeleting,
        children: 'Delete',
        onClick: async () => {
          await handleDelete()
          onClose()
        },
        endIcon: isDeleting ? <CircularProgress size={20} /> : null,
      }}
      secondaryButtonProps={{
        disabled: isDeleting,
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      {isDeleting ? (
        <Typography>Deleting workspace...</Typography>
      ) : (
        <Typography>
          Are you sure you want to delete the workspace "{workspace.name}"? This
          action cannot be undone.
        </Typography>
      )}
    </CustomDialog>
  )
}

type DeleteWorkspaceDialogProps = {
  open: boolean
  onClose: () => void
  handleDelete: () => Promise<void>
  workspace: Workspace
  isDeleting: boolean
}

export default DeleteWorkspaceDialog
