import {
  alpha,
  Autocomplete,
  Box,
  Icon,
  lighten,
  Stack,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import CustomDialog from '../../admin/shared/dialog/dialog'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { FormField } from '../../admin/shared'
import { Folder, FolderItem } from '../../../models'
import { useMemo, useState } from 'react'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { StrictModeDroppable } from '../create-custom-report/custom-report-table/strict-mode-dropable'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useGetAppSettings, useGetReports } from '../../../hooks'
import { FolderItemTypeToIcon } from './util'
import { useUpdateFolder } from '../../../hooks/mutations/useUpdateFolder'
import { makeStyles, createStyles } from '@mui/styles'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useDeleteFolder } from '../../../hooks/mutations/useDeleteFolder'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      paper: {
        backgroundColor: lighten(theme.palette.primary.main, 0.9),
        boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.25)',
      },
    }),
  { name: 'loading-button' }
)

function ManageFolderDialog(props: ManageFolderDialogProps) {
  const { open, onClose, folder } = props

  const { id, isFavorites } = folder

  const [tempFolder, setTempFolder] = useState<Folder>(folder)
  const { data: appSettings } = useGetAppSettings()
  const { mutateAsync: updateFolder, isLoading: isUpdatingFolder } =
    useUpdateFolder()
  const { mutateAsync: deleteFolder } = useDeleteFolder()

  const handleOnClose = () => {
    onClose()
  }

  const handleDragEnd = async (result: any) => {
    if (!result.destination) return
    const sortableItems = Array.from(tempFolder?.items)
    const [reorderedItem] = sortableItems.splice(result.source.index, 1)
    sortableItems.splice(result.destination.index, 0, reorderedItem)
    setTempFolder({ ...tempFolder, items: sortableItems })
  }

  const folderLimitExists =
    appSettings?.folderLimit && appSettings?.folderLimit > 0

  return (
    <CustomDialog
      open={open}
      onClose={() => {
        handleOnClose()
      }}
      paperSx={{
        minWidth: '600px',
        height: '628px',
      }}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={`Folder Settings`}
      loading={isUpdatingFolder}
      tertiaryButtonProps={{
        children: 'Delete',
        onClick: async () => await deleteFolder(id),
        startIcon: <DeleteOutlineIcon />,
        sx: {
          mt: -8,
          color: theme => theme.palette.error.main,
        },
      }}
      primaryButtonProps={{
        children: 'Confirm',
        startIcon: <CheckOutlinedIcon />,
        disabled: isUpdatingFolder,
        onClick: async () => {
          await updateFolder({
            folderId: id,
            folderName: tempFolder.name,
            folderDescription: tempFolder.description,
            items: tempFolder.items,
          })
          handleOnClose()
        },
        sx: {
          mt: -8,
        },
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: () => {
          handleOnClose()
        },
        variant: 'text',
        startIcon: <CloseOutlinedIcon />,
        sx: {
          mt: -8,
          mr: 1,
        },
      }}
    >
      <Stack direction='column'>
        <FormField
          label='Name'
          value={tempFolder.name}
          helperText=''
          disabled={isFavorites}
          onTextChange={value => setTempFolder({ ...tempFolder, name: value })}
        />
        <FormField
          label='Description'
          value={tempFolder.description}
          helperText=''
          onTextChange={value =>
            setTempFolder({ ...tempFolder, description: value })
          }
        />
        <ItemsSearch
          onAddItem={(item: any) => {
            const newItem: FolderItem = {
              id: item.id,
              name: item.name,
              type: item.type || 'report',
            }
            const newItems = [...tempFolder?.items, newItem]
            setTempFolder({ ...tempFolder, items: newItems })
          }}
          items={tempFolder.items}
          disabled={
            folderLimitExists &&
            tempFolder.items?.length >= appSettings?.folderLimit
          }
        />
        <Stack
          direction='row'
          justifyContent={'space-between'}
          alignItems='center'
          sx={{ mt: 1 }}
        >
          <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
            Folder Items
          </Typography>
          {folderLimitExists && (
            <Typography variant='body2'>{`${tempFolder?.items?.length}/${appSettings?.folderLimit}`}</Typography>
          )}
        </Stack>
        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable droppableId='droppable'>
            {provided => (
              <Stack
                direction='column'
                gap={2}
                sx={{
                  p: 1,
                  my: 1,
                  minWidth: '332px',
                  maxHeight: '240px',
                  overflow: 'hidden',
                  position: 'relative',
                  borderRadius: '8px',
                }}
              >
                <Box
                  sx={{
                    overflowY: 'scroll',
                    maxHeight: '100%',
                    position: 'relative',
                    //hide scroll bar
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    scrollbarWidth: 'none',
                    pr: 1,
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      zIndex: 0,
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {tempFolder?.items?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id + index}
                        index={index}
                      >
                        {provided => (
                          <SingleFolderItem
                            item={item}
                            provided={provided}
                            onRemove={() => {
                              const newItems = tempFolder?.items?.filter(
                                (folderItem: any) => folderItem.id !== item.id
                              )
                              setTempFolder({ ...tempFolder, items: newItems })
                            }}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <Box sx={{ height: '40px' }} />
                  </Box>
                </Box>

                {tempFolder?.items?.length > 5 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: '40px',
                      background: `linear-gradient(to bottom, 
        rgba(255, 255, 255, 0.5) 0%, 
        rgba(255, 255, 255, 1) 100%)`,
                      width: '100%',
                      pointerEvents: 'none',
                      zIndex: 1,
                    }}
                  />
                )}
              </Stack>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </Stack>
    </CustomDialog>
  )
}

type ItemsSearchProps = {
  onAddItem: (item: any) => void
  items: FolderItem[]
  disabled?: boolean
}

function ItemsSearch(props: ItemsSearchProps) {
  const { onAddItem, items, disabled } = props
  const { data: reports } = useGetReports()
  const [clearValueToggle, setClearValueToggle] = useState(false)
  const classes = useStyles()

  const myReports = reports?.ownedReports || []
  const sharedReports = reports?.sharedReports || []
  const allReports = [...myReports, ...sharedReports]

  const filteredReports = useMemo(() => {
    return allReports?.filter(
      report => !items.find(item => item?.id === report?.id)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items, allReports])

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <Stack direction='column' gap={2}>
      <Autocomplete
        disabled={disabled}
        // disablePortal
        id='select-items'
        key={clearValueToggle + 'autocomplete'}
        options={filteredReports}
        getOptionLabel={option => option.name}
        onChange={(_event, newValue) => {
          if (!newValue) return
          onAddItem(newValue)
          setClearValueToggle(prev => !prev)
        }}
        renderOption={(props, option: FolderItem) => (
          <li {...props}>
            <Stack direction='row' gap={2} alignItems='center'>
              <FolderItemTypeToIcon
                type={option?.type}
                sx={{
                  fontSize: '20px',
                }}
              />
              <Typography>{option.name}</Typography>
            </Stack>
          </li>
        )}
        value={undefined}
        fullWidth
        //drop down styles background color:
        sx={{
          height: '60px',
          // backgroundColor: theme => alpha(theme.palette.primary.main, 0.2),
        }}
        classes={{
          paper: classes.paper,
        }}
        onOpen={() => setIsDropdownOpen(true)}
        onClose={() => setIsDropdownOpen(false)}
        disableClearable
        renderInput={params => (
          <TextField
            {...params}
            label={isDropdownOpen ? 'Select Items' : 'Add Items'}
          />
        )}
      />
    </Stack>
  )
}

type SingleFolderItemProps = {
  item: any
  provided: any
  onRemove: () => void
}

function SingleFolderItem({ item, provided, onRemove }: SingleFolderItemProps) {
  return (
    <Stack
      ref={provided.innerRef}
      {...provided.draggableProps}
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{
        p: 1,
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: theme => alpha(theme.palette.primary.main, 0.2),
        },
        // border: '1px solid #E0E0E0',
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        gap={1}
        {...provided.dragHandleProps}
        sx={{
          cursor: 'grab',
        }}
      >
        <FolderItemTypeToIcon type={item?.type} />
        <Typography>{item.name}</Typography>
      </Stack>
      <Tooltip title='Remove'>
        <Icon
          onClick={onRemove}
          sx={{
            '&:hover': {
              color: theme => theme.palette.primary.light,
            },
            cursor: 'pointer',
          }}
        >
          <DeleteOutlineOutlinedIcon />
        </Icon>
      </Tooltip>
    </Stack>
  )
}

type ManageFolderDialogProps = {
  open: boolean
  onClose: () => void
  folder: Folder
}

export default ManageFolderDialog
