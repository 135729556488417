import React from 'react'
import {Chip, Theme, Box} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkedItem: {
      background: theme.palette.grey[100],
      borderRadius: theme.spacing(0.5),
      display: 'flex',
      fontSize: '16px',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      height: theme.spacing(5),
      padding: theme.spacing(0.5),
    },
  })
)

export type LinkedItemChipsProps = {
  children: React.ReactNode
}

//Parent component for LinkedItemChip that is scrollable
export function LinkedItemChips(props: LinkedItemChipsProps) {
  const {children} = props

  return <Box sx={{overflow: 'auto', maxHeight: '200px'}}>{children}</Box>
}

export function LinkedItemChip(props: LinkedItemChipProps) {
  const {label, onDelete} = props

  const classes = useStyles()

  return (
    <Chip label={label} onDelete={onDelete} className={classes.linkedItem} />
  )
}

export type LinkedItemChipProps = {
  label: string
  onDelete?: () => void
}
