import { useState } from 'react'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { DraggableChipProps, SelectedColumnsProps } from './types'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { alpha } from '@mui/material'
import { DataColumn } from '../../../../models'
import { StrictModeDroppable } from './strict-mode-dropable'
import MdcCalculator from '@meronex/icons/mdc/MdcCalculator'
import { DataColumnTypes } from '../generate-rdl-file/types'

export default function SelectedColumns(props: SelectedColumnsProps) {
  const { columns, onDelete, onDragEnd } = props

  const handleDelete = (col: DataColumn) => {
    onDelete(col)
  }

  return (
    <Stack
      direction='row'
      gap={1}
      sx={{
        border: theme => `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        p: theme => theme.spacing(1),
        height: '48px',
      }}
    >
      <DragDropContext
        onDragEnd={e => {
          onDragEnd(e)
        }}
      >
        <StrictModeDroppable droppableId='droppable' direction='horizontal'>
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ display: 'flex', gap: '8px' }}
            >
              {columns.map((col, index) => (
                <DraggableChip
                  key={col.id}
                  label={col.name}
                  onDelete={() => handleDelete(col)}
                  index={index}
                  id={col.id}
                  type={col.type}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </Stack>
  )
}

function DraggableChip(props: DraggableChipProps) {
  const { label, onDelete, index, type } = props
  const [isDragging, setIsDragging] = useState(false)

  return (
    <Draggable key={label} draggableId={label} index={index}>
      {(provided, snapshot) => {
        setIsDragging(snapshot.isDragging)

        return (
          <Chip
            label={label}
            onDelete={onDelete}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className='drag-handle'
            icon={
              type === DataColumnTypes.MEASURE ? (
                <MdcCalculator size={16} />
              ) : null
            }
            sx={{
              '&:hover': {
                backgroundColor: theme =>
                  alpha(theme.palette.primary.main, 0.4),
              },
              backgroundColor: theme =>
                isDragging ? alpha(theme.palette.primary.main, 0.4) : undefined,
            }}
          />
        )
      }}
    </Draggable>
  )
}
