import { useRouteMatch, NavLink } from 'react-router-dom'
import cs from 'classnames'
import {
  alpha,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  Theme,
  Typography,
} from '@mui/material'
import { useGetAppSettings, useGetLicense, useRoutes } from '../../hooks'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import {
  mdiShieldKeyOutline as appRegistrationIcon,
  mdiChartBoxPlusOutline as reportsIcon,
  mdiAccountPlusOutline as usersIcon,
  mdiAccountGroupOutline as groupsIcon,
  mdiPaletteSwatchOutline as palletIcon,
  mdiFolderMultipleOutline as workspacesIcon,
} from '@mdi/js'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { createStyles, makeStyles } from '@mui/styles'
import { Icon as MdiIcon } from '@mdi/react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: '240px',
    },
    navLink: {
      '&:hover': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.4),
      },
      borderRadius: '8px',
      marginBottom: theme.spacing(1.5),
      color: theme.palette.text.primary,
    },
    navLinkSelected: {
      backgroundColor: alpha(theme.palette.secondary.main, 0.2),
    },
    navLinkIcon: {
      marginRight: theme.spacing(1),
      minWidth: 0,
    },
    selected: {
      color: theme.palette.secondary.main,
    },
    muiNavLinkIcon: {
      marginRight: theme.spacing(0),
    },
    navTitle: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  })
)

export function NavigationLinks() {
  const { data: license } = useGetLicense()
  const { data: appSettings } = useGetAppSettings()
  const routes = useRoutes()
  const classes = useStyles()

  const subscriptionNavLinkTitle = `${
    license?.subscriptionStatus ? 'Manage Subscription' : 'License'
  }`

  return (
    <List className={classes.drawer}>
      <NavigationLinkTitle title='Report Dashboard' />
      <NavigationLink path={routes.reports.index} icon={AssessmentOutlinedIcon}>
        Report Dashboard
      </NavigationLink>

      {license && (license.isAdmin || license.isOwner) && (
        <>
          <NavigationLinkTitle title='Settings' />
          <NavigationLink
            path={routes.admin.appReg}
            iconPath={appRegistrationIcon}
          >
            Connection Profiles
          </NavigationLink>
          <NavigationLink path={routes.admin.reports} iconPath={reportsIcon}>
            Reports
          </NavigationLink>
          <NavigationLink
            path={routes.admin.workspaces}
            iconPath={workspacesIcon}
          >
            Workspaces
          </NavigationLink>
          <NavigationLink path={routes.admin.users} iconPath={usersIcon}>
            Users
          </NavigationLink>
          {appSettings?.groupsEnabled && (
            <NavigationLink path={routes.admin.groups} iconPath={groupsIcon}>
              Groups
            </NavigationLink>
          )}
          <NavigationLink path={routes.admin.appearance} iconPath={palletIcon}>
            Appearance
          </NavigationLink>
        </>
      )}
      {license &&
        appSettings?.payPalEnabled &&
        (license.isOwner || license.isAdmin) && (
          <NavigationLink
            path={routes.admin.subscription}
            icon={AdminPanelSettingsOutlinedIcon}
          >
            {subscriptionNavLinkTitle}
          </NavigationLink>
        )}
    </List>
  )
}

function NavigationLinkTitle(props: NavigationLinkTitleProps) {
  const { title } = props
  const classes = useStyles()
  return (
    <>
      <Typography variant='overline' className={classes.navTitle}>
        {title}
      </Typography>
    </>
  )
}

type NavigationLinkTitleProps = {
  title: string
}

function NavigationLink(props: NavLinkProps) {
  const { path, icon, children, iconPath } = props
  const isTabSelected = !!useRouteMatch(path)
  const classes = useStyles()

  return (
    <ListItem
      button
      to={path}
      component={NavLink}
      className={cs(classes.navLink, {
        [classes.navLinkSelected]: isTabSelected,
        [classes.selected]: isTabSelected,
      })}
    >
      <ListItemIcon
        className={cs(classes.navLinkIcon, {
          [classes.selected]: isTabSelected,
        })}
      >
        {iconPath ? (
          <MdiIcon path={iconPath} size={1} />
        ) : (
          <Icon component={icon} className={classes.muiNavLinkIcon} />
        )}
      </ListItemIcon>
      <ListItemText primary={children}></ListItemText>
    </ListItem>
  )
}

type NavLinkProps = {
  path: string
  children: string
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
  iconPath?: string
}
