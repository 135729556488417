import { Drawer, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useCallback } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    boxSizing: 'border-box',
  },
  dragger: {
    width: '10px',
    height: '30px',
    cursor: 'ew-resize',
    position: 'absolute',
    left: 0,
    top: 'calc(50% - 15px)',
    zIndex: 100,
    backgroundColor: '#e8e8e8',
    backgroundImage: `radial-gradient(circle, rgba(0,0,0,0.2) 1px, transparent 1px), radial-gradient(circle, rgba(0,0,0,0.2) 1px, transparent 1px)`,
    backgroundSize: '10px 10px', // Size of the dots
    backgroundPosition: '0 0', // Position of the dots
    backgroundRepeat: 'repeat',
  },
}))

export type ResizeState = {
  isResizing: boolean
  lastDownX: number
  newWidth: { width: number }
}

export type ResizableDrawerProps = {
  open: boolean
  minWidth?: number
  maxWidth?: number
  resizeState: ResizeState
  setResizeState
  children: React.ReactNode
}

export function ResizableDrawer(props: ResizableDrawerProps) {
  const {
    open,
    minWidth = 200,
    maxWidth = 600,
    resizeState,
    setResizeState,
    children,
  } = props
  const classes = useStyles()

  const handleMousedown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setResizeState(prevState => ({
        ...prevState,
        isResizing: true,
        lastDownX: e.clientX,
      }))
    },
    [setResizeState]
  )

  const handleMousemove = useCallback(
    (e: MouseEvent) => {
      setResizeState(prevState => {
        if (!prevState.isResizing) {
          return prevState
        }

        const offsetRight = document.body.offsetWidth - e.clientX
        if (offsetRight > minWidth && offsetRight < maxWidth) {
          return {
            ...prevState,
            newWidth: { width: offsetRight },
          }
        }

        return prevState
      })
    },
    [setResizeState, minWidth, maxWidth]
  )

  const handleMouseup = useCallback(() => {
    //wait for .5 seconds before setting isResizing to false
    setResizeState(prevState => ({
      ...prevState,
      isResizing: false,
    }))
  }, [setResizeState])

  useEffect(() => {
    const handleMousemoveWrapper = (e: MouseEvent) => handleMousemove(e)
    const handleMouseupWrapper = () => handleMouseup()

    document.addEventListener('mousemove', handleMousemoveWrapper)
    document.addEventListener('mouseup', handleMouseupWrapper)

    return () => {
      document.removeEventListener('mousemove', handleMousemoveWrapper)
      document.removeEventListener('mouseup', handleMouseupWrapper)
    }
  }, [handleMousemove, handleMouseup])

  return (
    <Drawer
      variant='permanent'
      open={open}
      anchor='right'
      classes={{
        paper: classes.drawerPaper,
      }}
      PaperProps={{ style: resizeState.newWidth }}
    >
      <div
        id='dragger'
        onMouseDown={handleMousedown}
        className={classes.dragger}
      />
      {children}
    </Drawer>
  )
}
