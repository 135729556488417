import { FC } from 'react'
import { CircularProgress, Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import { StyledOverlayBox, StyledCenteredBox } from './Loader.styles'
import { circularProgressPropsBase } from './constants'

export interface ContentLoaderProps {
  loading: boolean
  sx?: SxProps<Theme>
}

export const ContentLoader: FC<ContentLoaderProps> = (
  props: ContentLoaderProps
) => {
  return (
    <StyledOverlayBox hidden={!props.loading} sx={props.sx}>
      <StyledCenteredBox>
        <CircularProgress {...circularProgressPropsBase} />
      </StyledCenteredBox>
    </StyledOverlayBox>
  )
}
