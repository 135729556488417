import {useQuery, UseQueryOptions} from 'react-query'
import {useRecoilValue} from 'recoil'
import {themeApi} from '../../api-interface'
import {licenseAtom} from '../../state'
import {PowerBITheme} from '../../models'

export const getThemeKey = 'get-theme'

export function useGetTheme(options: UseQueryOptions<PowerBITheme> = {}) {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getThemeKey, licenseId],
    async () => {
      const themeOptions = (await themeApi.getTheme(licenseId)) as PowerBITheme

      return themeOptions
    },
    {...options, enabled: !!licenseId}
  )
}
