import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  debounce,
  Icon,
  IconButton,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

type SearchAndPageProps = {
  show: boolean // when there are 0 items to show, hide the search and page
  pageNumber: number
  totalPagesAvailable: number
  placeHolderText?: string
  onSearch: (searchText: string) => void
  onPrevPage: () => void
  onNextPage: () => void
}

export function SearchAndPage(props: SearchAndPageProps) {
  const {
    show,
    pageNumber,
    totalPagesAvailable,
    placeHolderText,
    onSearch,
    onPrevPage,
    onNextPage,
  } = props

  const debouncedSearch = debounce(onSearch, 300)

  return (
    <>
      {show && (
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <TextField
            variant='standard'
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => debouncedSearch(event.target.value)}
            placeholder={placeHolderText}
            autoFocus
            sx={{'& .MuiInputBase-root': {borderBottom: 0}, ml: 2}}
            InputProps={{
              startAdornment: (
                <Icon
                  sx={{
                    color: (theme: Theme) => theme.palette.text.secondary,
                  }}
                >
                  <SearchIcon fontSize='medium' />
                </Icon>
              ),
              disableUnderline: true,
            }}
          />

          <Stack
            direction='row'
            gap={1}
            alignItems='center'
            justifyContent='center'
          >
            <Typography variant='subtitle2'>
              {pageNumber} of {totalPagesAvailable}
            </Typography>
            <Box>
              <IconButton disabled={pageNumber === 1} onClick={onPrevPage}>
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton
                disabled={pageNumber === totalPagesAvailable}
                onClick={onNextPage}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Stack>
        </Stack>
      )}
    </>
  )
}
