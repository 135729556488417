import { DataColumn } from '../../../../models'
import {
  AdvancedFilterType,
  BasicFilterType,
  DataColumnWithFilters,
  FILTER_OPTIONS,
  FILTER_TYPES,
} from '../custom-report-table'
import { DataColumnTypes, SortColumn } from './types'
import {
  filterExists,
  mapFilterToDaxClause,
  replaceSpacesWithUnderscores,
} from './util'

export const getColumnValuesDAXQuery = (
  columnId: string,
  search?: string
): string => {
  if (!search) return `EVALUATE\nTOPN(25, VALUES(${columnId}))`

  return `EVALUATE\nTOPN(25, FILTER(VALUES(${columnId}), SEARCH("${search}", ${columnId}, 1, 0) >= 1))`
}

export const generateDAXQuery = (
  filters: DataColumnWithFilters[],
  currentColumns: DataColumn[],
  sortCol?: SortColumn
): string => {
  let selectedColumns = [...currentColumns]
  if (selectedColumns.length === 0) return ''

  const dimensions = filters.filter(
    column => column.type === DataColumnTypes.COLUMN
  )
  const selectedDimensions = selectedColumns.filter(
    column => column.type === DataColumnTypes.COLUMN
  )
  const selectedMeasures = selectedColumns.filter(
    column => column.type === DataColumnTypes.MEASURE
  )

  let query = 'DEFINE\n'

  const filterTableNames: string[] = []

  //Add all of the filter tables as variables
  dimensions.forEach((column, index) => {
    if (!filterExists(column)) return
    const filterTableName = `__DS0FilterTable${index}`
    filterTableNames.push(filterTableName)

    query += `VAR ${filterTableName} =`

    // Assume that if it is a basic filter then it is a single filter (array.length === 1)
    if (column.filterType === FILTER_TYPES.BASIC) {
      query += mapBasicFilterToDaxClause(column.id, column.basicFilter)
    } else {
      query += mapAdvancedFilterToDaxClause(
        column.id,
        column.advancedFilters,
        column.filterConnector,
        column.dataType
      )
    }
  })


  //Columns
  query += `\nVAR __DS0Core =\n    SUMMARIZECOLUMNS( ${selectedDimensions
    ?.map(column => {
      if (column.type === DataColumnTypes.MEASURE) {
        return `"${replaceSpacesWithUnderscores(column.name)}", [${
          column.name
        }]`
      }
      return column.id
    })
    ?.join(', ')}`

  //Filters
  const filtersExist = filterTableNames.length > 0
  query += filtersExist ? ', ' + filterTableNames.join(', ') : ''


  query +=
    selectedMeasures?.length > 0 && selectedDimensions?.length > 0 ? ', ' : ''

  query += `${selectedMeasures
    .map(column => {
      if (column.type === DataColumnTypes.MEASURE) {
        return `"${column.name}", [${column.name}]`
      }
      return column.id
    })
    .join(', ')}`

  query += '\n)\n'

  //TOPN
  query += `VAR __DS0PrimaryWindowed = TOPN(501, __DS0Core `
  query += selectedDimensions?.length > 0 ? ', ' : ''
  query += selectedDimensions?.map(column => column.id).join(', 1, ') + ', 1'
  query += ')\n'

  query += `EVALUATE\n__DS0PrimaryWindowed\n`

  //ORDER BY
  // const orderByClause = getOrderByClause(sortCol)
  query += sortCol ? `ORDER BY ${sortCol.id} ${sortCol.sortDirection}\n` : ''

  return query.trim()
}

// FOR NOW WE WILL ASSUME THAT THERE IS A MAX OF 2 FILTERS PER COLUMN
function mapAdvancedFilterToDaxClause(
  columnRef: string,
  filters: AdvancedFilterType[],
  filterConnector: string,
  dataType: number
): string {
  let daxClause = ' FILTER(\n'

  daxClause += `  VALUES(${columnRef}),\n`

  //if there there is more than one filter we need to use either AND or OR to combine them (the filter includes the filterConnector which indicates how to combine with the next filter)
  const isMultiple = filters.length > 1
  if (isMultiple && !filterConnector) {
    throw new Error(
      'filterConnector must be defined when there are multiple filters'
    )
  }
  if (isMultiple) {
    daxClause += '  ' + filterConnector + '(\n'
  }

  filters.forEach((filter, index) => {
    if (index !== 0) {
      daxClause += ',\n'
    }
    const valueToUse =
      dataType === FILTER_OPTIONS.DATE_TIME
        ? formatDateForDAX(filter?.value)
        : filter?.value
    daxClause += `    ${mapFilterToDaxClause(
      columnRef,
      valueToUse,
      filter.operator,
      dataType
    )}`
  })

  if (isMultiple) {
    daxClause += '\n  )\n'
  }
  daxClause += '\n)'

  return daxClause
}

function mapBasicFilterToDaxClause(
  columnRef: string,
  filter: BasicFilterType
): string {
  //If filterValue is not an array or it is an array with 0 elements then throw an error
  return `TREATAS({${filter.value
    ?.map(filter => {
      return typeof filter === 'string' ? `"${filter}"` : filter
    })
    ?.join(', ')}}, ${columnRef})`
}

export function formatDateForDAX(dateString) {
  // Parse the input date string
  const date = new Date(dateString)

  // Extract the year, month, day, hours, minutes, and seconds
  const year = date.getFullYear()
  const month = date.getMonth() + 1 // getMonth() is zero-based
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  // Format the date and time as needed for DAX
  const formattedDate = `DATE(${year}, ${month}, ${day})`
  const formattedTime = `TIME(${hours}, ${minutes}, ${seconds})`

  // Combine date and time for the final format
  const formattedDateTime = `${formattedDate} + ${formattedTime}`

  return formattedDateTime
}
