import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {appRegApi} from '../../api-interface'
import {AppRegistrationState} from '../../models'
import {licenseAtom} from '../../state'
import {getAppRegistrationsKey} from '../queries'

type CreateAppRegContext = {
  previousAppRegs: AppRegistrationState[]
}

export function useCreateAppReg() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(appRegApi.createAppRegistration, {
    async onMutate(newAppReg): Promise<CreateAppRegContext> {
      await queryClient.cancelQueries([getAppRegistrationsKey, licenseId])

      let previousAppRegs: AppRegistrationState[] = queryClient.getQueryData([
        getAppRegistrationsKey,
        licenseId,
      ])

      queryClient.setQueryData(
        [getAppRegistrationsKey, licenseId],
        (old: AppRegistrationState[]) => [...old, newAppReg]
      )

      // Return the context passed on onError
      return {previousAppRegs}
    },
    onError(_err, _newApPreg, context: CreateAppRegContext) {
      queryClient.setQueryData(
        [getAppRegistrationsKey, licenseId],
        context.previousAppRegs
      )
    },
    onSettled() {
      queryClient.invalidateQueries([getAppRegistrationsKey, licenseId])
    },
  })
}
