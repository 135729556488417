import { useState } from 'react'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'

import { AddBulkUsersForm } from './add-bulk-users-form'
import { SingleUserForm } from './single-user-form'

export const AddUserForm = (props: AddUserContentProps) => {
  const { onClose, onSave, setToastOpen, setToastMessage, setToastVariant } =
    props
  const [value, setValue] = useState('1')
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ minHeight: '400px', width: '100%', minWidth: '500px' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label='lab API tabs example'>
            <Tab label='Add User' value='1' />
            {!isMobile && <Tab label='Add Bulk Users' value='2' />}
          </TabList>
        </Box>
        <TabPanel value='1'>
          <SingleUserForm
            onClose={onClose}
            onSave={onSave}
            setToastOpen={setToastOpen}
            setToastMessage={setToastMessage}
            setToastVariant={setToastVariant}
          />
        </TabPanel>
        {!isMobile && (
          <TabPanel value='2' sx={{ width: '100%', height: '100%' }}>
            <AddBulkUsersForm
              onClose={onClose}
              onSave={onSave}
              setToastOpen={setToastOpen}
              setToastMessage={setToastMessage}
              setToastVariant={setToastVariant}
            />
          </TabPanel>
        )}
      </TabContext>
    </Box>
  )
}
export type AddUserContentProps = {
  onClose: () => void
  onSave: () => void
  setToastOpen: (value: boolean) => void
  setToastMessage: (value: string) => void
  setToastVariant: (value: 'primary' | 'success' | 'warning' | 'error') => void
}
