import {useMutation, useQueryClient} from 'react-query'
import {bookmarkApi} from '../../api-interface'
import {AllBookmarks, NewBookmark} from '../../models'
import {getBookmarksKey} from '../queries'
import {useRecoilValue} from 'recoil'
import {licenseAtom} from '../../state'

type CreateBookmarkContext = {
  previousBookmarks: AllBookmarks
}

export function useCreateBookmark(reportId) {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(bookmarkApi.createBookmark, {
    async onMutate(newBookmark: NewBookmark): Promise<CreateBookmarkContext> {

      await queryClient.cancelQueries([getBookmarksKey, licenseId, reportId])

      let previousBookmarks: AllBookmarks = await queryClient.getQueryData([
        getBookmarksKey,
        licenseId,
        reportId
      ])
      //
      // Have to destructure the ApiBookmark out of the BookmarkToCreate
      queryClient.setQueryData(
        [getBookmarksKey, licenseId, reportId],
        (old: AllBookmarks) => {
          if (!old) {
            return {
              ownedBookmarks: [],
              sharedBookmarks: [],
              licenseBookmarks: [],
            }
          }
          return {
            ownedBookmarks: [...old.ownedBookmarks, newBookmark],
            sharedBookmarks: old.sharedBookmarks,
            licenseBookmarks: old.licenseBookmarks,
          }
        }
      )

      // Return the context passed on onError
      return {previousBookmarks}
    },
    onError(err, newUser, context: CreateBookmarkContext) {
      queryClient.setQueryData(
        [getBookmarksKey, licenseId, reportId],
        context.previousBookmarks
      )
    },
    onSettled() {
      queryClient.invalidateQueries([getBookmarksKey, licenseId, reportId])
    },
  })
}
