import {UserWithGroups} from '../../../models'
import {formatDate} from '../../../utils'

export function downloadCSV(
  users: UserWithGroups[],
  fileName: string,
  includeReports: boolean,
  includeCreationDate: boolean
) {
  // groups = ['group1', 'group2', 'group3'] => 'group1 / group2 / group3'

  const usersToDownload = users.map(user => {
    return {
      id: stringifyAll(user.email),
      firstName: stringifyAll(user.firstName),
      lastName: stringifyAll(user.lastName),
      role: stringifyAll(user.userRole),
      groups: user.groups.join(' / '),
      createdDate: stringifyAll(
        formatDate(user.createdDate)?.split(',')?.join(' - ')
      ),
      reports: user.reports.join(' / '),
    }
  })

  const csvContent = csvMaker(
    usersToDownload,
    includeCreationDate,
    includeReports
  )

  const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'})
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', `${fileName}.csv`)
  link.click()
}

function csvMaker(
  data: any,
  includeCreationDate: boolean,
  includeReports: boolean
) {
  const csvData = getCSVData(includeCreationDate, includeReports, data)

  return csvData.map(e => e.join(',')).join('\n')
}

function getCSVData(includeCreationDate: boolean, includeReports, data: any) {
  let columns = ['id', 'firstName', 'lastName', 'groups', 'role']

  if (includeCreationDate) {
    columns = [...columns, 'creationDate']
  }

  if (includeReports) {
    columns = [...columns, 'reports']
  }

  let csvData = []

  if (includeCreationDate && !includeReports) {
    csvData = [
      columns,
      ...data.map((row: any) => {
        return [
          row.id,
          row.firstName,
          row.lastName,
          row.groups,
          row.role,
          row.createdDate,
        ]
      }),
    ]
  } else if (includeReports && !includeCreationDate) {
    csvData = [
      columns,
      ...data.map((row: any) => {
        return [
          row.id,
          row.firstName,
          row.lastName,
          row.groups,
          row.role,
          row.reports,
        ]
      }),
    ]
  } else if (includeReports && includeCreationDate) {
    csvData = [
      columns,
      ...data.map((row: any) => {
        return [
          row.id,
          row.firstName,
          row.lastName,
          row.groups,
          row.role,
          row.createdDate,
          row.reports,
        ]
      }),
    ]
  } else {
    csvData = [
      columns,
      ...data.map((row: any) => {
        return [row.id, row.firstName, row.lastName, row.groups, row.role]
      }),
    ]
  }

  return csvData
}

export function stringifyAll(input) {
  if (input === null || input === undefined) {
    return 'null'
  } else if (typeof input === 'number') {
    return String(input)
  } else if (input === '') {
    return 'null'
  } else {
    return String(input)
  }
}
