import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {bookmarkApi} from '../../api-interface'
import {licenseAtom} from '../../state'
import {
  getBookmarkViewersKey,
  getBookmarksKey,
} from '../queries'

type AddBookmarkMemberPayload = {
  bookmarkId: string
}

/** Add Bookmark to the License as a member */
export function useAddBookmarkMember() {
  const licenseId = useRecoilValue(licenseAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddBookmarkMemberPayload) =>
      bookmarkApi.addBookmarkMember(payload.bookmarkId, licenseId),
    {
      onSettled() {
        queryClient.invalidateQueries(getBookmarkViewersKey)
        queryClient.invalidateQueries(getBookmarksKey)
      },
    }
  )
}
