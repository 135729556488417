import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {bookmarkApi} from '../../api-interface'
import {licenseAtom} from '../../state'

export const getBookmarkViewersKey = 'get-bookmark-viewers'

export function useGetBookmarkViewers(bookmarkId: string) {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery([getBookmarkViewersKey, bookmarkId], () =>
    bookmarkApi.getBookmarkViewers(bookmarkId, licenseId)
  )
}
