import {useQuery} from 'react-query'
import {workspacesApi} from '../../api-interface/workspace-api'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'

export const getWorkspacesKey = 'get-workspaces'

export function useGetWorkspaces(
) {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getWorkspacesKey, licenseId],
    () => workspacesApi.getWorkspaces(licenseId),

  )
}
