import { useEffect, useState } from 'react'
import { TabLayout, TabLayoutModal } from '../../shared'
import { useGetAppRegs } from '../../../../hooks'
import { ExistingItemSkeleton } from '../../shared/existing-items/existing-item-skeleton'
import { ExistingAppRegistration } from './existing-app-registration'
import { AddAppRegistrationForm } from './add-app-registration'

export function AppRegistrationConfig() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { refetch: getAppRegs } = useGetAppRegs()

  function handleClose() {
    setIsModalOpen(false)
  }

  function handleSave() {
    getAppRegs()
  }

  return (
    <>
      <TabLayout
        tabTitle='Connection Profiles'
        primaryButton={{
          buttonText: 'Create new',
          onClick: () => setIsModalOpen(true),
        }}
      >
        <AppRegDisplay />
      </TabLayout>
      <TabLayoutModal
        title='Add Service Principal'
        isOpen={isModalOpen}
        onClose={handleClose}
      >
        <AddAppRegistrationForm onClose={handleClose} onSave={handleSave} />
      </TabLayoutModal>
    </>
  )
}

function AppRegDisplay() {
  const {
    data: appRegs,
    isLoading: loadingAppRegs,
    refetch: refetchAppRegs,
  } = useGetAppRegs()

  const [skeletonNumber, setSkeletonNumber] = useState(5)

  useEffect(
    () => {
      //Check if we have the number of reports in local storage
      const appRegsInLocalStorage = localStorage.getItem('appRegs')
      if (appRegsInLocalStorage) {
        setSkeletonNumber(parseInt(appRegsInLocalStorage))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (!appRegs) return
    //Save the number of reports in local storage
    localStorage.setItem('appRegs', appRegs?.length?.toString())
  }, [appRegs])

  if (!appRegs || loadingAppRegs)
    return <ExistingItemSkeleton numberOfItems={skeletonNumber} />

  return (
    <>
      {appRegs?.map((appReg: any, index: number) => (
        <ExistingAppRegistration
          key={appReg.id + index}
          appRegistration={appReg}
          refetch={async () => {
            await refetchAppRegs()
          }}
        />
      ))}
    </>
  )
}
