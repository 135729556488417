import {styled} from '@mui/material/styles'
import {Box, Snackbar} from '@mui/material/'

export const StyledToastBox = styled(Box)(({theme}) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.grey[600],
  borderRadius: '4px',
  padding: theme.spacing(2),
  display: 'flex',
}))

export const StyledSnackbar = styled(Snackbar)(({theme}) => ({
  [theme.breakpoints.up('xs')]: {
    top: theme.spacing(3),
    width: 'max-content',
    maxWidth: `calc(100% - ${theme.spacing(3)})`,
  },
}))
