import {useCallback, useEffect, useState} from 'react'
import {
  Box,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
  debounce,
} from '@mui/material'
import {
  useGetOtherUsersOnLicense,
  useGetLicense,
  useDeleteUsers,
  useCurrentUserInfo,
} from '../../../../hooks'
import {TabLayout, TabLayoutModal} from '../../shared'
import {AddUserForm} from './add-user-form'
import {User} from '../../../../models'
import {compareString, filterIt} from '../../../../utils/searchAndSort'
import {SortDirection} from '../../shared/sort-controls'
import {UserList} from './user-list'
import IosShareIcon from '@mui/icons-material/IosShare'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import EditUsersDialog from './dialogs/edit-users-dialog'
import DeleteUsersDialog from './dialogs/delete-users-dialog'
import ExportUsersDialog from './dialogs/export-users-dialog'
import {userApi} from '../../../../api-interface'
import {downloadCSV} from '../../shared/csv-downloader'
import {SearchAndSortAndFilter} from './search-sort-bar'
import {filterUsers} from './filter-users-box'
import {ToastNotification} from '../../../../components/common/Toast'
import {ExistingItemSkeleton} from '../../shared/existing-items/existing-item-skeleton'

const pagingThresholds = [25, 50, 100]

export function UsersConfig() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filter, setFilter] = useState<string[]>([])
  const [search, setSearch] = useState('')
  const [editMode, setEditMode] = useState(false)

  const {data: license} = useGetLicense()
  const {
    data: existingUsers,
    refetch: getUsers,
    isLoading: usersLoading,
  } = useGetOtherUsersOnLicense()
  const {mutate: deleteUsers} = useDeleteUsers()
  const {data: currentUserInfo} = useCurrentUserInfo()

  let licenseIsUnlimited = license.allowedUserCount === -1

  async function handleSave() {
    setIsModalOpen(false)
    await getUsers()
  }

  const [sortedUsers, setSortedUsers] = useState<User[]>([])
  const [sortValue, setSortValue] = useState<string>('lastName')
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Ascending
  )
  const [isSaving, setIsSaving] = useState(false)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)

  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [skeletonNumber, setSkeletonNumber] = useState(pagingThresholds[0])

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(pagingThresholds[0])

  const [toastOpen, setToastOpen] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastVariant, setToastVariant] = useState<
    'primary' | 'success' | 'warning' | 'error'
  >('success')

  useEffect(() => {
    if (!editMode) setSelectedUsers([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode])

  useEffect(() => {
    handleSortAndFilter(sortValue, sortDirection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    existingUsers,
    sortValue,
    sortDirection,
    filter,
    currentPage,
    itemsPerPage,
    search,
    isSaving,
  ])

  useEffect(() => {
    //Check if we have the number of reports in local storage
    const usersInLocalStorage = localStorage.getItem('users')
    if (
      usersInLocalStorage &&
      parseInt(usersInLocalStorage) < pagingThresholds[0]
    ) {
      setSkeletonNumber(parseInt(usersInLocalStorage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (usersLoading || !existingUsers) return
    localStorage.setItem('users', JSON.stringify(existingUsers.length))
  }, [existingUsers, usersLoading])

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const paginatedUsers = sortedUsers.slice(startIndex, endIndex)

  //Sort users by name, email, or date created
  function handleSortAndFilter(sortBy: string, direction: SortDirection) {
    const isAsc: boolean = direction === SortDirection.Ascending
    if (!existingUsers) {
      return
    }
    const filteredUsers = filterUsers(existingUsers, filter) //Filter
    const searchedUsers = filterIt(filteredUsers.slice(), search) // Search

    setSortedUsers(
      [...searchedUsers].sort((a, b) => {
        return isAsc
          ? compareString(a[sortBy], b[sortBy])
          : compareString(b[sortBy], a[sortBy])
      })
    ) //Sort
  }

  const handleChangeItemsPerPage = (event: any) => {
    setItemsPerPage(event.target.value as number)
    setCurrentPage(1) // Reset to the first page when changing items per page
  }

  let disableAddUser =
    !existingUsers ||
    !license ||
    (!licenseIsUnlimited &&
      license.currentUserCount >= license.allowedUserCount)

  const isCurrentUserOwner = currentUserInfo?.user.userRole === 'owner'

  const onUserSelected = (id: string, checked: boolean) => {
    if (checked) {
      setSelectedUsers([...selectedUsers, id])
    } else {
      setSelectedUsers(selectedUsers.filter((userId: string) => userId !== id))
    }
  }

  const handleEditUsers = async (role: 'viewer' | 'admin') => {
    setIsSaving(true)
    const selectedUserObjects =
      existingUsers
        ?.filter(user => selectedUsers.includes(user.id))
        .map(user => {
          return {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            userRole: user.userRole,
          }
        }) || []

    for (const user of selectedUserObjects) {
      user.userRole = role
    }

    try {
      await userApi.updateUsers(selectedUserObjects, license.id)
      await getUsers()
    } catch (error) {}

    setSelectedUsers([])
    setShowEditModal(false)
    setIsSaving(false)
    setEditMode(false)
  }

  const handleDeleteUsers = async () => {
    setIsSaving(true)
    deleteUsers({userIds: selectedUsers, licenseId: license.id})
    await getUsers()
    setSelectedUsers([])
    setIsSaving(false)
    setEditMode(false)
  }

  const handleExportUsers = async (
    fileName: string,
    includeOwner: boolean,
    includeCurrentUser: boolean,
    includeCreationDate: boolean,
    includeReports: boolean,
    includeAllUsers: boolean
  ) => {
    setIsSaving(true)

    // Either include all users (minus owner and current user) or only the selected ones
    const filteredSelectedUsers = includeAllUsers
      ? existingUsers.filter(
          user =>
            user.id !== currentUserInfo?.user.id && user.userRole !== 'owner'
        )
      : existingUsers.filter(user => selectedUsers.includes(user.id))

    if (includeCurrentUser) {
      filteredSelectedUsers.unshift(currentUserInfo.user)
    }

    if (includeOwner) {
      filteredSelectedUsers.unshift(
        existingUsers.find(user => user.userRole === 'owner')
      )
    }

    try {
      const users = await userApi.getExportUsers(
        license.id,
        includeReports,
        filteredSelectedUsers?.map(user => user.id) || []
      )

      downloadCSV(users, fileName, includeReports, includeCreationDate)
    } catch (error) {
      setToastMessage(`Error exporting users`)
      setToastVariant('error')
      setToastOpen(true)
    } finally {
      setSelectedUsers([])
      setShowExportModal(false)
      setIsSaving(false)
      setEditMode(false)
    }
  }

  const handleChange = (_e: any, value: number) => {
    setCurrentPage(value)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(
    debounce((text: string) => {
      setSearch(text)
    }, 300),
    [existingUsers, setSortedUsers]
  )

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    delayedSearch(e.target.value)
  }

  useEffect(
    () => {
      if (toastOpen) {
        const timer = setTimeout(() => {
          setToastOpen(false)
        }, 4000)
        return () => clearTimeout(timer)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toastOpen]
  )

  const iconButtons = [
    {
      onClick: () => {
        setShowExportModal(true)
      },
      icon: <IosShareIcon />,
      label: 'Export',
    },
    {
      onClick: () => {
        setShowEditModal(true)
      },
      icon: <EditIcon />,
      label: 'Edit',
    },
    {
      onClick: () => {
        setShowDeleteModal(true)
      },
      icon: <DeleteOutlineIcon />,
      label: 'Delete',
    },
  ]

  return (
    <>
      <ToastNotification
        open={toastOpen}
        onClose={() => {
          setToastOpen(false)
          setToastMessage('')
        }}
        message={toastMessage}
        variant={toastVariant}
      />
      <TabLayout
        tabTitle='Users'
        primaryButton={{
          buttonText: 'Invite user',
          onClick: () => setIsModalOpen(true),
          disabled: disableAddUser || editMode,
        }}
        secondaryButton={{
          buttonText: editMode ? `Done` : 'Select users',
          onClick: () => setEditMode(!editMode),
          variant: editMode ? 'contained' : 'outlined',
        }}
        iconButtons={iconButtons}
        editMode={editMode}
        disableIconButtons={selectedUsers.length === 0}
      >
        {/* Search and sort box */}
        <Stack sx={{height: '100%'}} direction='column' gap={0}>
          {existingUsers && (
            <SearchAndSortAndFilter
              existingUsers={existingUsers || []}
              currentUsersOnPage={paginatedUsers || []}
              currentUser={currentUserInfo?.user}
              sortDirection={sortDirection}
              setSelectedUsers={setSelectedUsers}
              setSortDirection={setSortDirection}
              setSortValue={setSortValue}
              editMode={editMode}
              selectedUsers={selectedUsers || []}
              sortValue={sortValue}
              handleSort={handleSortAndFilter}
              setFilter={setFilter}
              filter={filter}
              handleSearch={handleSearch}
            />
          )}

          {usersLoading && (
            <>
              <Box height='48px' />
              <ExistingItemSkeleton numberOfItems={skeletonNumber} />
            </>
          )}
          {existingUsers && (
            <UserList
              users={paginatedUsers}
              editMode={editMode}
              onUserSelected={onUserSelected}
              selectedUsers={selectedUsers}
              setToastMessage={setToastMessage}
              setToastOpen={setToastOpen}
              setToastVariant={setToastVariant}
            />
          )}
        </Stack>
      </TabLayout>
      <TabLayoutModal
        title='Add New User'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <AddUserForm
          onClose={() => setIsModalOpen(false)}
          onSave={handleSave}
          setToastOpen={setToastOpen}
          setToastMessage={setToastMessage}
          setToastVariant={setToastVariant}
        />
      </TabLayoutModal>
      <EditUsersDialog
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        users={selectedUsers}
        handleEdit={handleEditUsers}
        loading={isSaving}
      />
      <DeleteUsersDialog
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        users={selectedUsers}
        handleDelete={handleDeleteUsers}
      />
      <ExportUsersDialog
        open={showExportModal}
        onClose={() => {
          setShowExportModal(false)
        }}
        users={selectedUsers}
        allUsers={
          existingUsers
            ?.filter(
              user =>
                user.id !== currentUserInfo?.user.id &&
                user.userRole !== 'owner'
            )
            .map(user => {
              return user.id
            }) || []
        }
        handleExport={async (
          fileName: string,
          includeOwner: boolean,
          includeCurrentUser: boolean,
          includeCreationDate: boolean,
          includeReports: boolean,
          includeAllUsers: boolean
        ) =>
          await handleExportUsers(
            fileName,
            includeOwner,
            includeCurrentUser,
            includeCreationDate,
            includeReports,
            includeAllUsers
          )
        }
        defaultFileName={license.company || 'Users'}
        loading={isSaving}
        isCurrentUserOwner={isCurrentUserOwner}
      />
      {existingUsers && existingUsers.length >= pagingThresholds[0] && (
        <Stack
          direction='row'
          justifyContent='center'
          marginTop={2}
          alignItems={'center'}
        >
          <Stack
            direction='row'
            justifyContent='center'
            marginTop={2}
            alignItems={'center'}
          >
            <Select
              value={itemsPerPage}
              onChange={handleChangeItemsPerPage}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': {border: 0},
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
                mt: -2,
              }}
              inputProps={{
                padding: 1,
              }}
              size='small'
            >
              {pagingThresholds.map((threshold: number) => (
                <MenuItem key={threshold} value={threshold}>
                  {threshold}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Typography
            fontSize={12}
            sx={{
              mr: 1,
            }}
          >
            Per page
          </Typography>
          <Pagination
            count={Math.ceil(sortedUsers.length / itemsPerPage)}
            shape='rounded'
            page={currentPage}
            onChange={handleChange}
          />
        </Stack>
      )}
    </>
  )
}
