import { ApiReport } from '../../models'
import { parseBoolean } from '../../utils/parseBoolean'


export function formatReports(reports): ApiReport[] {
  const formattedReports: ApiReport[] =
    reports?.map(report => {
      
      let rlsRoles = []
      if (report?.rlsRoles) 
        rlsRoles = JSON.parse(report.rlsRoles)
      return {
        ...report,
        filterPaneEnabled: parseBoolean(
          report?.filterPaneEnabled || 'false'
        ),
        showPageNavigation: parseBoolean(
          report?.showPageNavigation || 'false'
        ),
        rls: {
          roles: rlsRoles,
          username: report?.rlsUsername
        }
      }
    }) || []

  return formattedReports
}
