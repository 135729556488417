import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {groupApi} from '../../api-interface'
import {Group} from '../../models'
import {licenseAtom} from '../../state'
import {getGroupsKey} from '../queries'

type CreateGroupContext = {
  previousGroups: Group[]
}

type CreateGroupPayload = {
  groupName: string
}

export function useCreateGroup() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (payload: CreateGroupPayload) =>
      groupApi.createGroup({...payload, licenseId}),
    {
      async onMutate(
        newGroup: CreateGroupPayload
      ): Promise<CreateGroupContext> {
        await queryClient.cancelQueries([getGroupsKey, licenseId])

        let previousGroups: Group[] = queryClient.getQueryData([
          getGroupsKey,
          licenseId,
        ])

        queryClient.setQueryData([getGroupsKey, licenseId], (old: Group[]) => {
          if (old)
            return [
              ...old,
              {...newGroup, name: newGroup.groupName, id: newGroup.groupName},
            ]

          return old
        })

        return {previousGroups}
      },
      onError(_err, _newGroup, context: CreateGroupContext) {
        queryClient.setQueryData(
          [getGroupsKey, licenseId],
          context.previousGroups
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getGroupsKey, licenseId])
      },
    }
  )
}
