import { DataColumn } from '../../../../models'
import { DataColumnWithFilters } from '../custom-report-table'
import { generateDAXQuery } from './generate-dax-query'
import { generateReportSectionsObject } from './generate-report-sections'
import { DataColumnTypes, GenerateReportRDLProps, SortColumn } from './types'
import { formatName } from './util'
import { v4 as uuidv4 } from 'uuid'

export const generateCommandTextQueryObject = (
  filters: DataColumnWithFilters[],
  columns: DataColumn[],
  sortCol?: SortColumn
): string => {
  const query = `<CommandText>${replaceSpecialCharacters(
    generateDAXQuery(filters, columns)
  )}</CommandText>
  <wa:PreviewCommandText>EVALUATE ROW(
    ${columns.map(column => column.id).join(',\n')} 
  )</wa:PreviewCommandText>`
  return query
}

export const generateFieldsObject = (columns: DataColumn[]): string => {
  const fields = columns
    .map(
      column => `
    <Field Name="${formatName(column.table) + '_' + formatName(column.name)}">
      <DataField>${getDataFieldName(column)}</DataField>
    </Field>`
    )
    .join('')

  return `<Fields>${fields}</Fields>`
}

export const getDataFieldName = (column: DataColumn): string => {
  if (column.type === DataColumnTypes.MEASURE) {
    return `[${column.name}]`
  }
  return `${column.table}[${column.name}]`
}

export const generateDatasetsObject = (
  filters: DataColumnWithFilters[],
  columns: DataColumn[],
  reportName: string
): string => {
  const datasets = `<DataSets>
    <DataSet Name="DataSet1">
      <Query>
        <DataSourceName>${reportName}</DataSourceName>
        ${generateCommandTextQueryObject(filters, columns)}
      </Query>
      ${generateFieldsObject(columns)}
    </DataSet>
  </DataSets>`

  return datasets
}

export const replaceSpecialCharacters = (str: string): string => {
  return str.replace('<>', '&lt;&gt;').replace('>', '&gt;').replace('<', '&lt;')
}

export const generateReportRDL = ({
  selectedColumns,
  filters,
  datasetId,
  reportName,
}: GenerateReportRDLProps): string => {
  const newReportName = reportName.replace(/ /g, '_') + uuidv4().substring(0, 4)

  const connectionString = `Data Source=pbiazure://api.powerbi.com/;Identity Provider="https://login.microsoftonline.com/common, https://analysis.windows.net/powerbi/api, f0b72488-7082-488a-a7e8-eada97bd842d";Initial Catalog=sobe_wowvirtualserver-${datasetId};Integrated Security=ClaimsToken`

  const dataSources = `<DataSources>
    <DataSource Name="${newReportName}">
      <rd:SecurityType>None</rd:SecurityType>
      <ConnectionProperties>
        <DataProvider>PBIDATASET</DataProvider>
        <ConnectString>${connectionString}</ConnectString>
      </ConnectionProperties>
    </DataSource>
  </DataSources>`

  const datasets = generateDatasetsObject(
    filters,
    selectedColumns,
    newReportName
  )
  const reportSections = generateReportSectionsObject(selectedColumns)

  const xmlTemplate = `
  ${xmlInfo}
  ${reportInfo}
  ${dataSources}
  ${datasets}
  ${reportSections}
  ${reportParamLayout}
  ${variables}
</Report>`

  return xmlTemplate.trim()
}

const reportInfo = `<rd:ReportUnitType>Inch</rd:ReportUnitType>
  <df:DefaultFontFamily>Segoe UI</df:DefaultFontFamily>
  <wa:ThemeName>default</wa:ThemeName>
  <wa:WebAuthoringVersion>1.0</wa:WebAuthoringVersion>
  <wa:DefaultView>Paginated</wa:DefaultView>
    <AutoRefresh>0</AutoRefresh>
      <Language>=User!Language</Language>

`

const variables = `<Variables>
    <Variable Name="Theme_BackGroundColor">
      <Value>#FFFFFF</Value>
    </Variable>
    <Variable Name="Theme_ForeGroundColor">
      <Value>#252423</Value>
    </Variable>
    <Variable Name="Theme_TableAccentColor">
      <Value>#118DFF</Value>
    </Variable>
    <Variable Name="Style_OutlineColor">
      <Value>#118DFF</Value>
    </Variable>
    <Variable Name="Style_GridColor">
      <Value>#E5E5E5</Value>
    </Variable>
    <Variable Name="Style_GridVerticalEnabledTable">
      <Value>false</Value>
    </Variable>
    <Variable Name="Style_GridVerticalWeight">
      <Value>0pt</Value>
    </Variable>
    <Variable Name="Style_GridHorizontalEnabledTable">
      <Value>true</Value>
    </Variable>
    <Variable Name="Style_GridHorizontalWeight">
      <Value>0.5pt</Value>
    </Variable>
    <Variable Name="Style_HeaderEnableBorderBottom">
      <Value>true</Value>
    </Variable>
    <Variable Name="Style_HeaderBorderBottomWeight">
      <Value>1.0pt</Value>
    </Variable>
    <Variable Name="Style_BackColorHeaders">
      <Value>#FFFFFF</Value>
    </Variable>
    <Variable Name="Style_FontColorHeaders">
      <Value>#252423</Value>
    </Variable>
    <Variable Name="Style_BackColorValues1">
      <Value>#FFFFFF</Value>
    </Variable>
    <Variable Name="Style_BackColorValues2">
      <Value>#EEEDED</Value>
    </Variable>
    <Variable Name="Style_FontColorValues2">
      <Value>#252423</Value>
    </Variable>
    <Variable Name="Style_FontColorValues1">
      <Value>#252423</Value>
    </Variable>
    <Variable Name="Style_RowPadding">
      <Value>3pt</Value>
    </Variable>
  </Variables>`

const xmlInfo = `<?xml version="1.0" encoding="utf-8"?>
<Report MustUnderstand="df" xmlns="http://schemas.microsoft.com/sqlserver/reporting/2016/01/reportdefinition" xmlns:rd="http://schemas.microsoft.com/SQLServer/reporting/reportdesigner" xmlns:wa="http://schemas.microsoft.com/sqlserver/reporting/webauthoring" xmlns:df="http://schemas.microsoft.com/sqlserver/reporting/2016/01/reportdefinition/defaultfontfamily" xmlns:am="http://schemas.microsoft.com/sqlserver/reporting/authoringmetadata">`

const reportParamLayout = `<ReportParametersLayout>
    <GridLayoutDefinition>
      <NumberOfColumns>4</NumberOfColumns>
      <NumberOfRows>2</NumberOfRows>
    </GridLayoutDefinition>
  </ReportParametersLayout>`
