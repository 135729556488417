import {useQuery} from 'react-query'
import {bookmarkApi} from '../../api-interface'
import {useRecoilValue} from 'recoil'
import {licenseAtom} from '../../state'

export const getBookmarkMembersKey = 'get-bookmarks-members'

export function useGetBookmarkMember(bookmarkId: string) {
  const licenseId = useRecoilValue(licenseAtom)

  return useQuery(
    [getBookmarkMembersKey, bookmarkId],
    () => bookmarkApi.getBookmarkMember(bookmarkId, licenseId),
    {
      enabled: !!bookmarkId,
    }
  )
}
