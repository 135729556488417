import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {paypalApi} from '../../api-interface'
import {licenseAtom} from '../../state'

export const getPaypalSubscriptionPlanKey = 'get-paypal-subscription-plan'

export function useGetPaypalSubscriptionPlan() {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getPaypalSubscriptionPlanKey, licenseId],
    paypalApi.getPaypalSubscriptionPlan
  )
}
