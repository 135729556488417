import {CircularProgress, Box} from '@mui/material'
import {FC} from 'react'
import {User} from '../../../../models'
import {ExistingUser} from './existing-user'

type UserListProps = {
  users: User[]
  editMode: boolean
  onUserSelected: (id: string, selected: boolean) => void
  selectedUsers: string[]
  setToastOpen: (value: boolean) => void
  setToastMessage: (value: string) => void
  setToastVariant: (value: 'primary' | 'success' | 'warning' | 'error') => void
}

export const UserList: FC<UserListProps> = (props: UserListProps) => {
  const {
    users,
    editMode,
    onUserSelected,
    selectedUsers,
    setToastOpen,
    setToastMessage,
    setToastVariant,
  } = props

  if (!users) return <CircularProgress />

  return (
    <Box>
      {users.map((user, index) => (
        <ExistingUser
          key={`${user.email}_${index}`}
          user={user}
          editMode={editMode}
          onCheck={(id: string, checked: boolean) =>
            onUserSelected(id, checked)
          }
          isSelected={
            !!selectedUsers.find(selectedUser => selectedUser === user.id)
          }
          setToastOpen={setToastOpen}
          setToastMessage={setToastMessage}
          setToastVariant={setToastVariant}
        />
      ))}
    </Box>
  )
}
