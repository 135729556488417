import {License} from '../models'
import {getAxios, endpoints, getUrl} from './util'

export const licenseApi = {
  async getLicense(licenseId: string): Promise<License> {
    const axios = await getAxios()

    const url = getUrl(endpoints.license, {licenseId: licenseId})

    const response = await axios({
      method: 'get',
      url,
    })

    return response?.data?.payload
  },
}
