import { useEffect, useState } from 'react'
import {
  AdvancedFilterType,
  BasicFilterType,
  FILTER_OPTIONS,
} from '../custom-report-table'
import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { filterRequiresValue } from '../generate-rdl-file/util'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'

type SetFilterProps = {
  filter?: BasicFilterType | AdvancedFilterType
  onFilterChange: (filter: BasicFilterType | AdvancedFilterType) => void
  filterOptions: string[]
  dataType: number
}

export function SetFilter(props: SetFilterProps) {
  const { filter, onFilterChange, filterOptions, dataType } = props

  const [showFilterValueOption, setShowFilterValueOption] = useState(true)

  useEffect(() => {
    setShowFilterValueOption(filterRequiresValue(filter?.operator))
  }, [filter, filter?.operator])

  return (
    <>
      <Stack direction={'column'}>
        <Typography fontSize={'12px'}>Condition</Typography>
        <Select
          value={filter?.operator || ''}
          onChange={e => {
            onFilterChange({
              ...filter,
              operator: e.target.value as string,
            })
          }}
          sx={{
            width: '100%',
            height: '40px',
          }}
          inputProps={{
            style: {
              fontSize: '12px',
            },
          }}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {filterOptions.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      {showFilterValueOption && (
        <Stack direction={'column'}>
          <Typography fontSize={'12px'}>Value</Typography>
          <FilterValue
            filter={filter}
            dataType={dataType}
            onFilterValueChange={value => {
              if (dataType === FILTER_OPTIONS.DATE_TIME) {
                value = value.toDate().toISOString().split('T')[0]
              }
              onFilterChange({ ...filter, value: value })
            }}
          />
        </Stack>
      )}
    </>
  )
}

type FilterValueProps = {
  filter: BasicFilterType | AdvancedFilterType
  dataType: number
  onFilterValueChange: (value: any) => void
}

function FilterValue(props: FilterValueProps) {
  const { filter, onFilterValueChange, dataType } = props

  switch (dataType) {
    case FILTER_OPTIONS.TEXT:
      return (
        <StringFilterValue
          filter={filter}
          onFilterValueChange={onFilterValueChange}
          dataType={dataType}
        />
      )
    case FILTER_OPTIONS.INT:
      return (
        <NumberFilterValue
          filter={filter}
          onFilterValueChange={onFilterValueChange}
          dataType={dataType}
        />
      )
    case FILTER_OPTIONS.DOUBLE:
      return (
        <NumberFilterValue
          filter={filter}
          onFilterValueChange={onFilterValueChange}
          dataType={dataType}
        />
      )
    case FILTER_OPTIONS.DATE_TIME:
      return (
        <DateFilterValue
          filter={filter}
          onFilterValueChange={onFilterValueChange}
          dataType={dataType}
        />
      )
    default:
      return null
  }
}

type DateFilterValueProps = FilterValueProps

function DateFilterValue(props: DateFilterValueProps) {
  const { filter, onFilterValueChange } = props

  const value = filter?.value ? dayjs(filter?.value as Date) : null

  const [currentValue, setCurrentValue] = useState<Dayjs>(value)

  return (
    <Box
      sx={{
        width: '200px',
        height: '60px',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            label='Select Date'
            value={currentValue}
            onChange={date => {
              setCurrentValue(date as Dayjs)
              onFilterValueChange(date as Dayjs)
            }}
            slotProps={{
              textField: {
                size: 'small',
                InputProps: {
                  sx: {
                    width: '192px',
                  },
                },
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  )
}

type StringFilterValueProps = FilterValueProps

function StringFilterValue(props: StringFilterValueProps) {
  const { filter, onFilterValueChange } = props
  const [currentValue, setCurrentValue] = useState<string>(
    (filter?.value as string) || ''
  )

  return (
    <TextField
      value={currentValue}
      onChange={e => setCurrentValue(e.target.value)}
      onBlur={() => onFilterValueChange(currentValue)}
      variant='outlined'
      size='small'
      fullWidth
    />
  )
}
type NumberFilterValueProps = FilterValueProps

function NumberFilterValue(props: NumberFilterValueProps) {
  const { filter, onFilterValueChange } = props
  const [currentValue, setCurrentValue] = useState<number>(
    filter?.value as number
  )

  return (
    <TextField
      value={currentValue}
      onChange={e => setCurrentValue(parseFloat(e.target.value))}
      onBlur={() => onFilterValueChange(currentValue)}
      variant='outlined'
      size='small'
      fullWidth
    />
  )
}
