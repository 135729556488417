import {alpha} from '@mui/material/styles'
// import {TrendingFlat} from '@mui/icons-material'
import {
  Typography,
  Theme,
  Card,
  Button,
  Stack,
  useMediaQuery,
  CardContent,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {useEffect} from 'react'
import {useSetRecoilState} from 'recoil'

import {licenseAtom} from '../../state'
const useStyles = makeStyles((theme: Theme) => ({
  page: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: 'linear-gradient(0deg, #e6e6e6 0%, #f4f4f4 100%)',
  },
  bgImage: {
    width: '100%',
    position: 'fixed',
  },
  signUpLogin: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  content: {
    width: '80%',
    height: '100vh',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '400px',
    maxWidth: '1280px',
    margin: 'auto',
    marginTop: theme.spacing(16),
    padding: theme.spacing(4),
  },
  loginButton: {
    height: '48px',

    '&:hover': {
      backgroundColor: '#000000',
      color: '#ffffff',
    },
  },
  secondaryLoginButton: {
    height: '56px',
    border: 'solid black 2px',
    color: 'black',
    '&:hover': {
      backgroundColor: alpha('#000000', 0.2),
      border: 'solid black 2px',
    },
  },

  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    margin: '0',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '2.5rem',
  },

  logo: {
    width: '180px',
    margin: theme.spacing(1),
  },
}))

export function LandingPage() {
  const classes = useStyles()
  const setCurrentLicense = useSetRecoilState(licenseAtom)

  useEffect(() => {
    setCurrentLicense(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <div className={classes.page}>
      {/* <AppBar color='transparent'>
        <Toolbar className={classes.toolBar}>
          <div className={classes.logo}>
            <img src={logo} alt='logo' />
          </div>
          <Typography variant='h5'>Reports</Typography>
          <div className={classes.logo} />
        </Toolbar>
      </AppBar> */}

      <Stack
        sx={{mx: 2, maxWidth: '540px', width: '100%'}}
        justifyContent='center'
      >
        <Card>
          <CardContent>
            <Typography
              sx={{
                fontWeight: 700,
                letterSpacing: '-1.6px',
                lineHeight: isMobile ? '42px' : '48px',
                fontSize: isMobile ? '34px' : '44px',

                mb: 3,
                mt: 3,
              }}
              variant='h3'
              textAlign='center'
            >
              Welcome to Intelexos
              <Typography sx={{mt: 1}}>
                Login to start sharing reports.
              </Typography>
            </Typography>

            <Stack
              sx={{mt: 1}}
              direction={isMobile ? 'column' : 'row'}
              gap={3}
              width='100%'
              alignContent='center'
              justifyContent='center'
            >
              <Button
                disableElevation
                sx={{
                  borderRadius: 0,
                  fontSize: '1rem',
                  display: 'flex',
                  px: 3,
                  justifyContent: 'space-between',
                }}
                variant='contained'
                className={classes.loginButton}
                href={`/login`}
              >
                Login
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Stack>

      {/* <img src={splashImg} className={classes.bgImage} alt='background' /> */}
    </div>
  )
}
