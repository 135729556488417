import React from 'react'
import {Dialog, DialogTitle, Theme} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: 0,
      margin: '16px 0',
    },
    dialogContent: {
      // display: flex won't allow margins to properly collapse,
      // so we use block instead
      display: 'block',
      padding: theme.spacing(4),
    },
  })
)

export function TabLayoutModal(props: TabLayoutModalProps) {
  const {isOpen, onClose, children, title} = props

  const classes = useStyles()

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{
        paper: classes.dialogContent,
      }}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      {children}
    </Dialog>
  )
}

export type ChildProps = {
  onSave: () => void
}

export type TabLayoutModalProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactElement
  title: string
}
