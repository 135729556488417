export type AuthPayload = {
  clientPrincipal: ClientPrincipal
}
export type ClientPrincipal = {
  identityProvider: string
  userId: string
  userDetails: string
  userRoles: string[]
  claims: [
    {
      typ: string
      val: string
    }
  ]
}

export async function getClientPrincipal() {
  // This api will return the ClientPrincipal details for a logged in user.
  // If the user is not logged in it will return null.
  // see https://docs.microsoft.com/en-us/azure/static-web-apps/user-information?tabs=javascript
  const response = await fetch('/.auth/me')
  const payload: AuthPayload = await response.json()

  return payload.clientPrincipal
}

export async function getCurrentUserEmail() {
  return (await getClientPrincipal())?.userDetails.toLowerCase()
}
