import CustomDialog from '../../../shared/dialog/dialog'
import {Typography} from '@mui/material'
import {useState} from 'react'

function DeleteUsersDialog(props: DeleteUsersDialogProps) {
  const {open, onClose, users, handleDelete} = props

  const [deleteLoading, setDeleteLoading] = useState(false)

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={`Delete Users?`}
      primaryButtonProps={{
        disabled: deleteLoading,
        children: 'Delete',
        onClick: async () => {
          setDeleteLoading(true)
          await new Promise(resolve => setTimeout(resolve, 200))
          await handleDelete()
          setDeleteLoading(false)
          onClose()
        },
      }}
      secondaryButtonProps={{
        disabled: deleteLoading,
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      {deleteLoading ? (
        <Typography>Deleting users...</Typography>
      ) : (
        <Typography>
          Are you sure you want to delete {users.length} user
          {users.length > 1 ? 's' : ''}? This action cannot be undone.
        </Typography>
      )}
    </CustomDialog>
  )
}

type DeleteUsersDialogProps = {
  open: boolean
  onClose: () => void
  handleDelete: () => void
  users: string[]
}

export default DeleteUsersDialog
