import CustomDialog from '../../../shared/dialog/dialog'
import {Typography} from '@mui/material'
import {useState} from 'react'
import {Profile} from '../../../../../models'

function DeleteProfileDialog(props: DeleteProfileDialogProps) {
  const {open, onClose, profile, handleDelete} = props

  const [deleteLoading, setDeleteLoading] = useState(false)

  if (!profile) return null

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={`Delete profile?`}
      primaryButtonProps={{
        disabled: deleteLoading,
        children: 'Delete',
        onClick: async () => {
          setDeleteLoading(true)
          await new Promise(resolve => setTimeout(resolve, 2500))
          await handleDelete()
          setDeleteLoading(false)
          onClose()
        },
      }}
      secondaryButtonProps={{
        disabled: deleteLoading,
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      {deleteLoading ? (
        <Typography>Deleting profile...</Typography>
      ) : (
        <Typography>
          Are you sure you want to delete the profile "{profile.name}"? This
          action cannot be undone.
        </Typography>
      )}
    </CustomDialog>
  )
}

type DeleteProfileDialogProps = {
  open: boolean
  onClose: () => void
  handleDelete: () => void
  profile: Profile
}

export default DeleteProfileDialog
