import {AtomEffect} from 'recoil'

export function createSessionStorageEffect<T>(
  storageKey: string,
  storageMechanism: Storage = window.sessionStorage
): AtomEffect<T> {
  return ({setSelf, onSet}) => {
    const storage = storageMechanism
    if (!storage) return

    // On initialization, fetch the value from session storage and
    // set the initial value of the atom
    const storedValue = storage.getItem(storageKey)
    if (storedValue) setSelf(JSON.parse(storedValue))

    // Every time the value of the atom changes, write that new value to session
    // storage for retrieval if the user refreshes their page during this session.
    onSet(newValue => {
      if (newValue) {
        storage.setItem(storageKey, JSON.stringify(newValue))
      } else {
        storage.removeItem(storageKey)
      }
    })
  }
}
