import Papa from 'papaparse'
import {NewUser} from '../../../../../models'

export type UserWithGroups = {
  user: NewUser
  groups: string[]
}

export async function parseFiles(allFiles: File[]): Promise<UserWithGroups[]> {
  let data: any = []

  await Promise.all(
    allFiles.map(async file => {
      await new Promise<any>(resolve => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            results.data.forEach((row: any) => {
              data.push(row)
            })
            resolve(results)
          },
        })
      })
    })
  )

  const formattedUsers: UserWithGroups[] = data.map((user: any) => {
    if (!user.id) throw new Error('User ID is required')
    if (!user.firstName && !user.lastName)
      throw new Error('First or Last Name is required')
    const userRole = user.role || user.userRole || 'viewer'
    const newUser: NewUser = {
      id: user.id as string,
      userId: user.id as string,
      firstName: user.firstName as string,
      lastName: user.lastName as string,
      email: user.id as string,
      userRole: userRole !== 'admin' ? 'viewer' : 'admin', // default to viewer
    }

    const groups: string[] =
      (user.groups
        ?.split('/')
        ?.map((group: string) => group.trim()) as string[]) || []
    return {user: newUser, groups}
  })

  return formattedUsers
}
