import {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useCurrentUserInfo, useGetLicense} from './hooks'
import {getLicenseState} from './models'
import {routes} from './routes'

export const useLicenseRouting = (): void => {
  const history = useHistory()
  const {isFetched: userInfoFetched} = useCurrentUserInfo()
  const {data: license, isFetched: isLicenseFetched} = useGetLicense()

  useEffect(() => {
    if (userInfoFetched && isLicenseFetched && license) {
      const licenseState = getLicenseState(license)

      // Ensure User is re-directed to Subscription Page if their License is no longer valid
      if (!licenseState.valid) {
        if (licenseState.status === 'SUSPENDED') {
          history.replace(routes.admin.subscription)
        } else if (licenseState.status === 'CANCELLED') {
          history.replace(routes.reports.index)
        }
      }

      // If User has an invalid License, re-direct them to Subscriptions page to handle License
      return history.listen(() => {
        if (history.action !== 'REPLACE' && !licenseState.valid) {
          if (licenseState.status === 'SUSPENDED') {
            history.replace(routes.admin.subscription)
          } else if (licenseState.status === 'CANCELLED') {
            history.replace(routes.reports.index)
          }
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoFetched, isLicenseFetched, license])
}
