import { useQuery } from 'react-query'
import { workspacesApi } from '../../api-interface/workspace-api'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'

export const getProfileWorkspacesKey = 'get-profile-workspaces'

export function useGetProfileWorkspaces(
  appRegNodeId: string,

) {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery([getProfileWorkspacesKey, licenseId], () =>
    workspacesApi.getWorkspacesForAppReg(
      appRegNodeId,
     
      licenseId
    )
  )
}
