import axios, {AxiosInstance} from 'axios'

let customAxios: AxiosInstance

export async function getAxios(): Promise<AxiosInstance> {
  if (customAxios) return customAxios
  customAxios = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return customAxios
}
