import React, {useState} from 'react'
import {Typography, Box, Button, TextField, Popover, Theme} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'
import {HexColorPicker} from 'react-colorful'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    picker: {
      margin: theme.spacing(2),
      '& .react-colorful': {
        width: theme.spacing(8),
        height: theme.spacing(8),
      },
      '& .react-colorful__saturation': {
        borderRadius: '4px 4px 0 0',
      },
      '& .react-colorful__hue-pointer': {
        width: '20px',
        height: '20px',
      },
      '& .react-colorful__hue': {
        height: '16px',
        borderRadius: '0 0 4px 4px',
      },
    },
  })
)

export type ColorPickerFieldProps = {
  selectedColor: string
  title: string
  onColorChanged: (string) => void
}

export function ColorPickerField(props: ColorPickerFieldProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null)
  const {selectedColor, title, onColorChanged} = props

  const classes = useStyles()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handlePaletteOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePaletteClose = () => {
    setAnchorEl(null)
  }
  return (
    <Box sx={{my: 1}}>
      <Typography variant='subtitle2'>{title}</Typography>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Button
          aria-describedby={id}
          style={{
            backgroundColor: selectedColor,
            height: '32px',
            minWidth: '32px',

            margin: '8px',
            border: 'solid 1px #e3e3e3',
          }}
          onClick={handlePaletteOpen}
          children={''}
        />
        <Popover
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePaletteClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <HexColorPicker
            className={classes.picker}
            color={selectedColor}
            onChange={onColorChanged}
          />
        </Popover>

        <TextField
          value={selectedColor}
          onChange={e => onColorChanged(e.target.value)}
          color='secondary'
          size='small'
          variant='filled'
          hiddenLabel
          sx={{width: '100%'}}

          // helperText={'This sets color of top app bar and other primary items'}
        />
      </Box>
    </Box>
  )
}
