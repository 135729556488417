import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { profileApi } from '../../api-interface'
import { Profile } from '../../models'
import { licenseAtom } from '../../state'
import { getAppRegistrationsKey } from '../queries'

type CreateProfileContext = {
  previousProfiles?: Profile[]
}

type CreateProfilePayload = {
  profile: Profile
  appRegistrationNodeId: string
  appRegistrationTenantId: string
  appClientId: string
}

export function useCreateProfile() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (payload: CreateProfilePayload) =>
      profileApi.createProfileConnection({
        profile: payload.profile,
        appRegistrationNodeId: payload.appRegistrationNodeId,
        appClientId: payload.appClientId,
        appRegistrationTenantId: payload.appRegistrationTenantId,
        licenseId,
      }),
    {
      async onMutate(newProfile): Promise<CreateProfileContext> {
        await queryClient.cancelQueries([getAppRegistrationsKey, licenseId])

        let previousProfiles: Profile[] | undefined = queryClient.getQueryData([
          getAppRegistrationsKey,
          licenseId,
        ])

        if (!Array.isArray(previousProfiles)) {
          previousProfiles = []
        }

        queryClient.setQueryData(
          [getAppRegistrationsKey, licenseId],
          (old: Profile[] | undefined) => [...(old ?? []), newProfile]
        )

        // Return the context passed on onError
        return { previousProfiles }
      },
      onError(_err, _newProfile, context: CreateProfileContext) {
        if (context.previousProfiles) {
          queryClient.setQueryData(
            [getAppRegistrationsKey, licenseId],
            context.previousProfiles
          )
        }
      },
      onSettled() {
        queryClient.invalidateQueries([getAppRegistrationsKey, licenseId])
      },
    }
  )
}
