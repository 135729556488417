import {getAxios, endpoints} from './util'
import {EmbedAccess} from '../models'

export const powerBiApi = {
  async embedAccess(reportId: string) {
    let axios = await getAxios()
    let response
    try {
      response = await axios({
        method: 'post',
        url: `${endpoints.embedAccess}`,
        data: {reportId},
      })
    } catch (e) {
      response = e.response
      throw new Error(response.data.errorMessage)
    }
    return response.data.payload as EmbedAccess
  },
}
