import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {reportApi} from '../../api-interface'
import {licenseAtom} from '../../state'

export const getViewersKey = 'get-report-viewers'

export function useGetReportViewers(reportId: string) {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery([getViewersKey, reportId], () =>
    reportApi.getViewers(reportId, licenseId)
  )
}
