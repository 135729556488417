import React, {useState} from 'react'
import {
  Typography,
  TextField,
  Chip,
  Theme,
  Button,
  Autocomplete,
} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justify: 'flex-start',
      width: '100%',
    },
    chip: {
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '4px',
      width: '100%',
      height: '40px',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    buttonContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },
    button: {
      width: '80px',
      right: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
)
export type AddMultipleProps = {
  label: string
  options: string[]
  values: string[]
  onAdd: (value: string) => void
  onRemove: (value: string) => void
}
export function AddMultiple(props: AddMultipleProps) {
  const {label, options, values, onAdd, onRemove} = props
  const classes = useStyles()
  const [valueToAdd, setValueToAdd] = useState('')

  return (
    <div className={classes.container}>
      <Typography align='left' variant='subtitle1'>
        {label}
      </Typography>
      <Autocomplete
        options={options}
        autoComplete
        onChange={(event: any, newValue: string) => {
          setValueToAdd(newValue)
        }}
        inputValue={valueToAdd}
        onInputChange={(event, newInputValue) => {
          setValueToAdd(newInputValue)
        }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              className={classes.textField}
              size='small'
              fullWidth
              variant='filled'
              label='Add User or Group'
            />
          )
        }}
      />
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          disabled={!valueToAdd}
          onClick={() => {
            onAdd(valueToAdd)
            setValueToAdd('')
          }}
        >
          Add
        </Button>
      </div>
      {values &&
        values.map(value => {
          return (
            <Chip
              className={classes.chip}
              onDelete={() => {
                onRemove(value)
              }}
              label={value}
            ></Chip>
          )
        })}
    </div>
  )
}
