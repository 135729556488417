import {CircularProgress} from '@mui/material'

export function FullPageCentered(
  props: React.HtmlHTMLAttributes<HTMLDivElement>
) {
  return (
    <div
      {...props}
      style={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  )
}

export function FullPageLoader() {
  return (
    <FullPageCentered>
      <CircularProgress />
    </FullPageCentered>
  )
}
