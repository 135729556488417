import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {workspacesApi} from '../../api-interface'
import {Workspace} from '../../models'
import {licenseAtom} from '../../state'
import {getWorkspacesKey} from '../queries'

type DeleteWorkspaceContext = {
  previousWorkspaces: Workspace[]
}

export function useDeleteWorkspace() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)
  return useMutation(
    (workspaceId: string) => workspacesApi.deleteWorkspace(workspaceId, licenseId),
    {
      async onMutate(workspaceId: string): Promise<DeleteWorkspaceContext> {
        await queryClient.cancelQueries([getWorkspacesKey, licenseId])

        let previousWorkspaces: Workspace[] = queryClient.getQueryData([
          getWorkspacesKey,
          licenseId,
        ])

        queryClient.setQueryData([getWorkspacesKey, licenseId], (old: Workspace[]) =>
          old.filter((workspace: Workspace) => workspace.id !== workspaceId)
        )

        return {previousWorkspaces}
      },
      onError(_err, _deletedWorkspace, context: DeleteWorkspaceContext) {
        queryClient.setQueryData(
          [getWorkspacesKey, licenseId],
          context.previousWorkspaces
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getWorkspacesKey, licenseId])
      },
    }
  )
}
