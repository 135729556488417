import {Checkbox, Stack, TextField} from '@mui/material'
import {User} from '../../../../models'
import {useCallback, useEffect, useState} from 'react'
import {SortControls, SortDirection} from '../../shared/sort-controls'
import {FilterBox} from './filter-users-box'

export type SearchAndSortProps = {
  existingUsers: User[]
  currentUsersOnPage: User[]
  currentUser: User
  sortDirection: SortDirection
  setSortDirection: (direction: SortDirection) => void
  sortValue: string
  selectedUsers: string[]
  setSelectedUsers: (users: string[]) => void
  handleSort: (sortBy: string, direction: SortDirection) => void
  editMode: boolean
  setSortValue: (value: string) => void
  setFilter: (filter: string[]) => void
  filter: string[]
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function SearchAndSortAndFilter(props: SearchAndSortProps) {
  const {
    existingUsers,
    currentUsersOnPage,
    currentUser,
    sortDirection,
    setSortDirection,
    sortValue,
    selectedUsers,
    setSelectedUsers,
    editMode,
    handleSearch,
    setSortValue,
    setFilter,
    filter,
  } = props

  const SearchBox = useCallback(() => {
    return (
      <TextField
        id='standard'
        variant='outlined'
        fullWidth
        label='Search'
        onChange={handleSearch}
        sx={{width: '20%'}}
        size='small'
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingUsers])

  return (
    <>
      {existingUsers && (
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{mb: 1}}
        >
          <Stack
            direction='row'
            alignItems='center'
            gap={1}
            sx={{
              width: '100%',
            }}
          >
            <SearchBox />
            <SortControls
              sortValue={sortValue}
              onSortValueChange={sortBy => {
                setSortValue(sortBy)
              }}
              sortDirection={sortDirection}
              onSortDirectionChange={direction => {
                setSortDirection(direction)
              }}
              sortColumns={[
                {label: 'First Name', value: 'firstName'},
                {label: 'Last Name', value: 'lastName'},
                {label: 'Email', value: 'email'},
                {label: 'Role', value: 'userRole'},
                {label: 'Date Created', value: 'createdDate'},
              ]}
            />
          </Stack>

          <Stack direction='row'>
            <FilterBox setFilters={setFilter} filters={filter} />

            {editMode && (
              <SelectAllBox
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                users={currentUsersOnPage}
                currentUser={currentUser}
              />
            )}
          </Stack>
        </Stack>
      )}
    </>
  )
}

const CHECKBOX_STATES = {
  Checked: 'Checked',
  Indeterminate: 'Indeterminate',
  Empty: 'Empty',
}

type SelectAllBoxProps = {
  selectedUsers: string[]
  setSelectedUsers: (selectedUsers: string[]) => void
  users: User[]
  currentUser: User
}

function SelectAllBox(props: SelectAllBoxProps) {
  const {selectedUsers, setSelectedUsers, users, currentUser} = props

  const [checkboxState, setCheckboxState] = useState(CHECKBOX_STATES.Empty)

  const isCurrentUserOwner = currentUser.userRole === 'owner'
  const numberOfPossibleUsersToSelect =
    users.length - 1 - (isCurrentUserOwner ? 0 : 1)

  useEffect(() => {
    if (selectedUsers.length === 0) {
      setCheckboxState(CHECKBOX_STATES.Empty)
    } else if (selectedUsers.length === numberOfPossibleUsersToSelect) {
      setCheckboxState(CHECKBOX_STATES.Checked)
    } else {
      setCheckboxState(CHECKBOX_STATES.Indeterminate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, users])

  return (
    <Checkbox
      checked={checkboxState === CHECKBOX_STATES.Checked}
      indeterminate={checkboxState === CHECKBOX_STATES.Indeterminate}
      onChange={event => {
        if (checkboxState === CHECKBOX_STATES.Empty) {
          setCheckboxState(CHECKBOX_STATES.Checked)
          setSelectedUsers(
            users
              .filter(
                user =>
                  user.userRole !== 'owner' && user.email !== currentUser.email
              )
              .map(user => user.id)
          )
        } else {
          setCheckboxState(CHECKBOX_STATES.Empty)
          setSelectedUsers([])
        }
      }}
      sx={{
        marginRight: '16px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    />
  )
}
