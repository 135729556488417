import { useCallback, useEffect, useState } from 'react'
import {
  ApiReport,
  StatusMessageInfo,
  ReportToCreate,
} from '../../../../../models'
import {
  useCreateReport,
  useUpdateReport,
  useGetLicense,
  useAddViewersToReport,
  useGetAppRegs,
} from '../../../../../hooks'
import { StepsDialog } from './steps-dialog'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { ReportForm } from './report-form'
import {
  AddReportContentProps,
  ProfileAndAppReg,
  ReportSettingsUpdate,
  UserOrGroup,
} from './types'
import { ConfigureReportSettings } from './configure-report-settings'
import { SelectAndAuthenticateReport } from './select-and-authenticate'
import { UserAndGroupSearch } from './user-and-group-search'

export function AddReportForm(props: AddReportContentProps) {
  const { onClose, reportToEdit, open, onSave, isEdit } = props

  const steps = [
    {
      label: 'Select Report',
      icon: <AssessmentOutlinedIcon />,
    },

    {
      label: 'Configure',
      icon: <SettingsOutlinedIcon />,
    },
    ...(!isEdit || !reportToEdit
      ? [
          {
            label: 'Share',
            icon: <PeopleAltOutlinedIcon />,
          },
        ]
      : []),
  ]

  const { data: existingAppRegistrations } = useGetAppRegs()
  const [activeStep, setActiveStep] = useState(0)
  const [selectedItems, setSelectedItems] = useState<UserOrGroup[]>([])
  // const [isLoading, setIsLoading] = useState(false)
  // const [isSaving, setIsSaving] = useState(false)
  const [report, setReport] = useState<ApiReport>(
    reportToEdit ?? {
      pbiReportId: '',
      pbiWorkspaceId: '',
      name: '',
      pbiReportName: '',
      appRegistrationName: '',
      appRegistrationNodeId: '',
      owner: '',
      description: '',
      defaultPageName: '',
      filterPaneEnabled: false,
      showPageNavigation: false,
    }
  )

  const [profile, setProfile] = useState<ProfileAndAppReg | null>(null)
  const [status, setStatus] = useState<StatusMessageInfo>(null)

  const { data: license } = useGetLicense()
  const { mutateAsync: createReport, isLoading: isSavingReport } =
    useCreateReport()
  const { mutateAsync: updateReport, isLoading: isUpdating } = useUpdateReport()
  const { mutateAsync: addViewers, isLoading: isAddingViewers } =
    useAddViewersToReport()

  const [isGrantingAccess, setIsGrantingAccess] = useState(false)

  const updateReportSettings = useCallback((settings: ReportSettingsUpdate) => {
    setReport(prevReport => ({ ...prevReport, ...settings }))
  }, [])

  useEffect(() => {
    if (reportToEdit) {
      //get the profile for the report
      const currentAppRegistration = existingAppRegistrations?.find(
        appReg => appReg.id === reportToEdit.appRegistrationNodeId
      )
      if (currentAppRegistration) {
        setProfile({
          id: reportToEdit.profileId,
          name: reportToEdit.profileName,
          appRegId: currentAppRegistration.appRegistrationId,
          appRegNodeId: currentAppRegistration.id,
          appRegName: currentAppRegistration.name,
          tenantId: currentAppRegistration.tenantId,
        })
      }
    }
  }, [existingAppRegistrations, reportToEdit])

  async function handleSave() {
    let reportData: ReportToCreate = {
      id: reportToEdit?.id || null,
      name: report.name,
      pbiReportName: report.pbiReportName,
      description: report?.description || '',
      pbiReportId: report.pbiReportId,
      pbiWorkspaceId: report.pbiWorkspaceId,
      appRegistrationNodeId: profile?.appRegNodeId,
      appRegistrationName: profile.appRegName,
      defaultPageName: report.defaultPageName,
      filterPaneEnabled: report.filterPaneEnabled,
      showPageNavigation: report.showPageNavigation,
      profileName: profile?.name,
      profileId: profile.id,
      licenseNodeId: license.id,
      url: report.url,
      rls: report.rls,
    }

    if (!reportToEdit) {
      const reportNodeId = (await createReport(reportData))?.id || ''
      setReport(prevReport => ({ ...prevReport, id: reportNodeId }))
    } else {
      await updateReport(reportData)
    }
  }

  const handleConfirm = async () => {
    if (activeStep === 0) {
      setActiveStep(1)
      return
    }

    if (activeStep === 1) {
      await handleSave()
      if (reportToEdit) {
        onSave()
        return
      }
      setActiveStep(2)
      return
    }

    if (activeStep === 2) {
      const viewerIds = selectedItems
        .filter(item => item.type === 'user')
        .map(item => item.id)
      const groupIds = selectedItems
        .filter(item => item.type === 'group')
        .map(item => item.id)

      if (!report.id) return

      await addViewers({
        reportId: report.id,
        viewerIds: viewerIds,
        viewerType: 'user',
      })
      await addViewers({
        reportId: report.id,
        viewerIds: groupIds,
        viewerType: 'group',
      })
    }
    onSave()
  }

  const renderConfigureReportSettings = () => {
    return (
      <ConfigureReportSettings
        profile={profile}
        report={report}
        isEdit={isEdit}
        updateReportSettings={updateReportSettings}
      />
    )
  }

  const renderSelectAndAuthenticateReport = () => {
    return (
      <SelectAndAuthenticateReport
        report={report}
        setReport={setReport}
        profile={profile}
        setProfile={setProfile}
        status={status}
        setStatus={setStatus}
        isGrantingAccess={isGrantingAccess}
        setIsGrantingAccess={setIsGrantingAccess}
        isEdit={isEdit}
      />
    )
  }

  const renderShareReport = () => {
    if (!report.pbiReportId) return null

    return (
      <UserAndGroupSearch
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        reportId={report.pbiReportId}
      />
    )
  }

  //prettier-ignore
  const confirmButtonText = activeStep === 0 ? 'Next' : 
                            activeStep === 2 ? `Share (${selectedItems.length})`:
                            reportToEdit     ? 'Save' : 'Next'
  const disableSave =
    !profile ||
    !report.pbiReportId ||
    !status ||
    status.type === 'error' ||
    !report.name

  return (
    <StepsDialog
      isOpen={open}
      onClose={onClose}
      steps={steps}
      activeStep={activeStep}
    >
      <ReportForm
        onSave={handleConfirm}
        isSaving={isSavingReport || isUpdating || isAddingViewers}
        disableSave={disableSave}
        onBack={() => setActiveStep(activeStep === 0 ? 0 : activeStep - 1)}
        showBack={activeStep === 1}
        confirmationButtonLabel={confirmButtonText}
      >
        {activeStep === 0 && renderSelectAndAuthenticateReport()}
        {activeStep === 1 && renderConfigureReportSettings()}
        {activeStep === 2 && renderShareReport()}
      </ReportForm>
    </StepsDialog>
  )
}
