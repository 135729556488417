import {useMutation, useQueryClient} from 'react-query'
import {paypalApi} from '../../api-interface'

type CancelPaypalSubscriptionPayload = {
  subscriptionId: string
}
export function useCancelPaypalSubscription() {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: CancelPaypalSubscriptionPayload) =>
      paypalApi.cancelPaypalLicense(payload.subscriptionId),
    {
      onSettled() {
        queryClient.invalidateQueries()
      },
    }
  )
}
