import {useState, FC} from 'react'
import {v4 as uuidv4} from 'uuid'
import {useDeleteGroup} from '../../../../hooks/mutations/useDeleteGroup'
import {Group} from '../../../../models'
import {ExistingItem, TabLayoutModal} from '../../shared'
import {GroupForm} from './group-form'
import {LinkUsersMenu} from './link-users-menu'
import {LinkedUsers} from './linked-users'

type ExistingGroupProps = {
  group: Group
}

export const ExistingGroup: FC<ExistingGroupProps> = (props: ExistingGroupProps) => {
  const {group} = props

  const {mutate: deleteGroup} = useDeleteGroup()
  const [editModalOpen, setEditModalOpen] = useState(false)

  return (
    <>
      <ExistingItem
        key={`${group.id}_${uuidv4()}`}
        name={group.name}
        onDelete={() => deleteGroup(group.id)}
        onEdit={() => setEditModalOpen(true)}
      >
        <>
          <LinkUsersMenu group={group} />
          <LinkedUsers group={group} />
        </>
      </ExistingItem>

      <TabLayoutModal
        title='Edit Group'
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
      >
        <GroupForm
          onClose={() => setEditModalOpen(false)}
          group={group}
          editMode={true}
        />
      </TabLayoutModal>
    </>
  )
}
