export function parseBoolean(value: boolean | string): boolean {
  if (typeof value === 'boolean') {
    return value
  } else if (typeof value === 'string') {
    return value.toLowerCase() === 'true'
  }
 
  throw new Error(
    "Invalid input: expected a boolean or a string 'true'/'false'"
  )
}
