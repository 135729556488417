import React from 'react'
import {Theme, Typography} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        opacity: 0.5,
      },
    }),
  {name: 'noResultsText'}
)

export function NoResultsText(props: NoResultsTextProps) {
  const {children} = props
  const classes = useStyles()

  return (
    <Typography variant='caption' className={classes.root}>
      {children}
    </Typography>
  )
}

type NoResultsTextProps = {
  children: React.ReactNode
}
