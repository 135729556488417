import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {reportApi} from '../../api-interface'
import {GetReportViewersResponse, Group, User} from '../../models'
import {licenseAtom} from '../../state'
import {getViewersKey} from '../queries'

export const removeViewersKey = 'remove-viewers'

export type RemoveViewerPayload = {reportId: string; viewerId: string}

/** Remove a viewer from a PBI report */
export function useRemoveViewer() {
  const licenseId = useRecoilValue(licenseAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: RemoveViewerPayload) =>
      reportApi.removeViewers(payload.reportId, payload.viewerId, licenseId),
    {
      async onMutate(
        payload: RemoveViewerPayload
      ): Promise<GetReportViewersResponse> {
        await queryClient.cancelQueries([getViewersKey, payload.reportId])

        let previousViewers: GetReportViewersResponse =
          queryClient.getQueryData([getViewersKey, payload.reportId])

        if (!previousViewers) {
          previousViewers = await queryClient.fetchQuery(
            [getViewersKey, payload.reportId],
            () => reportApi.getViewers(payload.reportId, licenseId)
          )
        }


        queryClient.setQueryData(
          [getViewersKey, payload.reportId],
          (old: GetReportViewersResponse) => {
            const users = old.users.filter(
              (oldUser: User) => oldUser.id !== payload.viewerId
            )
            const groups = old.groups.filter(
              (oldGroup: Group) => oldGroup.id !== payload.viewerId
            )
            return {
              users,
              groups,
            }
          }
        )

        return previousViewers
      },
      onError(
        _err,
        payload: RemoveViewerPayload,
        context: GetReportViewersResponse
      ) {
        queryClient.setQueryData([getViewersKey, payload.reportId], context)
      },
    }
  )
}
