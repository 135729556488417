import {Bookmark} from '../models'
import {getAxios, endpoints, getUrl} from './util'

type BookmarkResponse = {
  licenseBookmarks: Bookmark[]
  sharedBookmarks: Bookmark[]
  ownedBookmarks: Bookmark[]
}

export const bookmarkApi = {
  async getBookmarks(licenseId: string, reportId: string): Promise<BookmarkResponse>{
    let axios = await getAxios()

    const url = getUrl(endpoints.bookmarks, {
      licenseId: licenseId,
      reportId: reportId,
    })

    
    const response = await axios({
      method: 'get',
      url: url,
    })

  //const { licenseBookmarks, ownedBookmarks, sharedBookmarks} = response.data
  // Combine all bookmark arrays into one array
  //const allBookmarks = [...licenseBookmarks, ...ownedBookmarks, ...sharedBookmarks];
  
  return response.data
  },

  async createBookmark(bookmark) {
    let axios = await getAxios()

    const response = await axios({
      method: 'post',
      url: endpoints.bookmarks,
      data: bookmark,
    })

    return response.data
  },

  async deleteBookmark(bookmarkId: string) {
    let deleteData = {
      bookmarkId
    }
    let axios = await getAxios()
    await axios({
      method: 'delete',
      url: endpoints.bookmarks,
      data: deleteData,
    })
    // TODO: perhaps we need to return the response or at least look at the response.
    return bookmarkId
  },

  async updateBookmark(bookmark) {
    let axios = await getAxios()

    await axios({
      method: 'patch',
      url: endpoints.bookmarks,
      data: bookmark,
    })
  },

  async getBookmarkViewers(bookmarkId: string, licenseId: string) {
    let axios = await getAxios()

    const url = getUrl(endpoints.bookmarkViewers, {bookmarkId: bookmarkId})
    const response = await axios({
      method: 'get',
      url: url,
    })

    const viewers: string[] = response.data.payload
    return viewers
  },

  async addBookmarkViewers(
    bookmarkId: string,
    viewerIds: string[],
    licenseId: string
  ) {
    let axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.bookmarkViewers,
      data: {bookmarkId: bookmarkId, viewers: viewerIds},
    })
  },

  async removeBookmarkViewer(
    bookmarkId: string,
    viewerId: string,
    licenseId: string
  ) {
    let axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.bookmarkViewers,
      data: {bookmarkId: bookmarkId, viewer: viewerId},
    })
  },

  async addBookmarkMember(
    bookmarkId: string,
    licenseId: string
  ) {
    let axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.bookmarkMembers,
      data: {bookmarkId, licenseId}
    })
  },

  async removeBookmarkMember(
    bookmarkId: string,
    licenseId: string
  ) {
    let axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.bookmarkMembers,
      data: {bookmarkId, licenseId}
    })
  },

  async getBookmarkMember(
    bookmarkId,
    licenseId
  ){
    let axios = await getAxios()

    const url = getUrl(endpoints.bookmarkMembers, {bookmarkId, licenseId})

    const response = await axios({
      method: 'get',
      url: url
    })

    return response.data.payload
  }
}
