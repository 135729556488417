import { DataColumn } from '../../../../models'

export type SelectedColumnsProps = {
  columns: DataColumn[]
  onDelete: (column: DataColumn) => void
  onDragEnd: (result: any) => void // Add onDragEnd prop
}

export const FILTER_TYPES = {
  ADVANCED: 'advanced',
  BASIC: 'basic',
  RELATIVE_DATE: 'relative_date',
  RELATIVE_TIME: 'relative_time',
}



export const FILTER_OPTIONS = {
  TEXT: 2,
  INT: 6,
  DOUBLE: 8,
  DATE_TIME: 9,
}

export type DataColumnWithFilters = DataColumn & {
  basicFilter?: BasicFilterType
  advancedFilters?: AdvancedFilterType[]
  filterConnector?: string // AND || OR
  filterType: string //'advanced' | 'basic' 
}

export type BasicFilterType = {
  value: number[] | string[] | Date[]
  operator: string // is, is less than, is greater than, etc.
}

export type AdvancedFilterType = {
  value: number | string | Date 
  operator: string // is, is less than, is greater than, etc.
}

// export type Filter =  {
//   filterType?: (typeof FILTER_TYPES)[number] // advanced, basic, topN, etc.
//   filterValue?: string | number | string[] | number[] | Date 
//   filterOperator?: string // is, is less than, is greater than, etc.
//   filterConnector?: string // AND || OR 
// }

export type CustomReportFiltersProps = {
  filterOptions: DataColumnWithFilters[]
  setFilters: (filters: DataColumnWithFilters[]) => void
  getRowValues:  (column: DataColumn, search?: string) => Promise<string[]>
  gettingRowValues: boolean
}

export type DraggableChipProps = {
  label: string
  id: string
  onDelete: () => void
  index: number
  type: string
}

export type DataColumnsProps = {
  title: string
  dataColumns: DataColumn[]
  setSelectedColumns: (selectedColumns: DataColumn[]) => void
  selectedColumns: DataColumn[]
  loading: boolean
  icon?: React.ReactNode
  showHelperText?: boolean
}

export type SingleDataColumnProps = {
  column: DataColumn
  handleColumnSelection: (column: DataColumn, selected: boolean) => void
  selected: boolean
}

export type OnDragEndType = {
  destination: {
    droppableId: string
    index: number
  }
  source: {
    droppableId: string
    index: number
  }
}
