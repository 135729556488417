import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {workspacesApi} from '../../api-interface'
import {licenseAtom} from '../../state'

export const getWorkspaceUsersKey = 'get-workspace-users'

export function useGetWorkspaceUsers(workspaceId: string) {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery([getWorkspaceUsersKey, workspaceId], () =>
    workspacesApi.getWorkspaceUsers(workspaceId, licenseId)
  )
}
