import {ThemeOptions} from '@mui/material'

// TODO: move this to another file later
export const defaultTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#2b2b2b',
    },
    secondary: {
      main: '#CB3757',
    },
    background: {
      default: '#F2F2F2',
    },
  },
  typography: {
    button: {
      textTransform: 'capitalize',
    },
  },
}
