import cs from 'classnames'
import {
  Card,
  CardActionArea,
  CardContent,
  Skeleton,
  Stack,
  Theme,
  useMediaQuery,
} from '@mui/material'
import {makeStyles} from '@mui/styles'

type ReportDashboardSkeletonProps = {
  numberOfItems?: number
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 'auto',
      width: '100%',
      position: 'relative',
    },
    tile: {
      minWidth: '300px',
      width: 'calc((100% - 48px) / 3)',
      minHeight: '120px',
    },
    listItem: {
      width: '100%',
      borderRadius: '8px',
    },
    actions: {
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      height: '100%',
      paddingBottom: theme.spacing(1) + 'px!important',
      paddingRight: theme.spacing(1),
    },
    bottomContent: {
      display: 'flex',
      flexGrow: 1,
    },
    description: {
      flexGrow: 1,
    },
    openBtn: {
      alignSelf: 'flex-end',
    },
  }),
  {name: 'ReportTile'}
)

export function ReportDashboardSkeleton(props: ReportDashboardSkeletonProps) {
  const {numberOfItems} = props
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles()

  const numberOfSkeletons = numberOfItems || 10

  return (
    <Stack
      sx={{m: 'auto', mb: 8, mt: 8, maxWidth: '1280px', px: {xs: 3.5, sm: 4.5}}}
      gap={4}
      alignItems='center'
    >
      <Stack direction='row' flexWrap='wrap' sx={{mt: 3}} gap={3}>
        {Array.from(Array(numberOfSkeletons).keys()).map((_, index) => (
          <Card
            elevation={4}
            className={cs(classes.root, {
              [classes.tile]: !isMobile,
              [classes.listItem]: isMobile,
            })}
            key={index}
          >
            <CardActionArea className={classes.cardContent}>
              <CardContent>
                <Skeleton
                  variant='text'
                  height={40}
                  sx={{
                    width: '200px',
                    mb: 1,
                  }}
                />
                <div className={classes.bottomContent}>
                  <div className={classes.description}>
                    <Skeleton
                      variant='text'
                      width='100%'
                      height={18}
                      sx={{
                        width: '180px',
                        mb: 1,
                      }}
                    />
                  </div>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    </Stack>
  )
}
