import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {groupApi} from '../../api-interface'
import {User} from '../../models'
import {licenseAtom} from '../../state'
import {getGroupUsersKey, getOtherUsersOnLicenseKey} from '../queries'

type AddUsersToGroupPayload = {groupId: string; userIds: string[]}

export function useAddUsersToGroup() {
  const licenseId = useRecoilValue(licenseAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddUsersToGroupPayload) =>
      groupApi.addGroupUsers(payload.groupId, payload.userIds, licenseId),
    {
      async onMutate(payload: AddUsersToGroupPayload): Promise<User[]> {
        await queryClient.cancelQueries([getGroupUsersKey, payload.groupId])

        const allUsers: User[] = queryClient.getQueryData([
          getOtherUsersOnLicenseKey,
          licenseId,
        ])

        const previousUsers: User[] = queryClient.getQueryData([
          getGroupUsersKey,
          payload.groupId,
        ])

        const updatedUsers = allUsers
          .filter((u: User) => payload.userIds.includes(u.id))
          .concat(previousUsers)
          .sort((a: User, b: User) => a.firstName.localeCompare(b.firstName))

        queryClient.setQueryData(
          [getGroupUsersKey, payload.groupId],
          updatedUsers
        )

        return previousUsers
      },
    }
  )
}
