import { Box, Stack, Typography } from '@mui/material'
import { ApiReport } from '../../../models'
import CustomDialog from '../../admin/shared/dialog/dialog'
import { DataColumnWithFilters } from '../create-custom-report/custom-report-table'
import { Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tableHeaders: {
        fontWeight: 600,
        fontSize: '16px',
      },
    }),
  { name: 'loading-button' }
)

type ReportInfoDialogProps = {
  open: boolean
  onClose: () => void
  report: ApiReport
}

export function ReportInfoDialog(props: ReportInfoDialogProps) {
  const { report, open, onClose } = props

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      paperSx={{
        minWidth: '620px',
      }}
      allowBackdropClickClose
      allowEscapeKeyClose
      title={
        <Typography
          variant='h6'
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            color: theme => theme.palette.primary.main,
          }}
        >
          {report.name}
        </Typography>
      }
    >
      <Stack
        direction='column'
        gap={2}
        sx={{
          width: '100%',
        }}
      >
        <Typography>{report.description}</Typography>
        {report.filters ? (
          <ReportInfoFilters filters={report.filters} />
        ) : (
          <Typography>No Filters Applied</Typography>
        )}
      </Stack>
    </CustomDialog>
  )
}

type ReportInfoFiltersProps = {
  filters: string
}

function ReportInfoFilters(props: ReportInfoFiltersProps) {
  const formattedFilters = JSON.parse(props.filters) as DataColumnWithFilters[]

  const nonNullFilters = formattedFilters?.filter(
    filter => filter?.basicFilter || filter?.advancedFilters?.length > 0
  )
  const classes = useStyles()

  const columns = ['Name', 'Operator', 'Value']

  let allFilters = []
  nonNullFilters?.forEach(filter => {
    if (filter.basicFilter) {
      allFilters.push({
        Name: filter.name,
        Type: 'Basic',
        Operator: '',
        Value: filter.basicFilter.value,
      })
    } else {
      filter.advancedFilters?.forEach(advFilter => {
        allFilters.push({
          Name: filter.name,
          Type: 'Advanced',
          Operator: `${advFilter.operator} ${filter.advancedFilters?.length > 1 ? `(${filter?.filterConnector})` : ''}`,
          Value: advFilter.value,
        })
      })
    }
  })

  const rows = allFilters.map((filter, index) => {
    return {
      id: index,
      ...filter,
    }
  })

  return (
    <Box
      style={{
        width: '100%',
        minHeight: 500,
        height: '100%',
      }}
    >
      <DataGrid
        sx={{
          height: '100%',
          minHeight: 500,
        }}
        rowHeight={28}
        columnHeaderHeight={32}
        // autosizeOnMount={true}
        columns={columns?.map(col => ({
          field: col,
          headerName: col,
          width: col === 'Value' ? 220 : col === 'Type' ? 88 : 160,
          wordWrap: true,
          sortable: false,
          filterable: false,
          headerClassName: classes.tableHeaders,
        }))}
        scrollbarSize={12}
        disableColumnSelector
        slotProps={{
          loadingOverlay: {
            color: 'rgba(0, 0, 0, 0)',
          },
        }}
        rows={rows}
      />
    </Box>
  )
}
