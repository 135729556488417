import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {groupApi} from '../../api-interface'
import {Group} from '../../models'
import {licenseAtom} from '../../state'
import {getGroupsKey} from '../queries'

type CreateGroupContext = {
  previousGroups: Group[]
}

type CreateGroupPayload = {
  groupId: string
  groupName: string
}

export function useUpdateGroup() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (payload: CreateGroupPayload) =>
      groupApi.editGroup({...payload, licenseId}),
    {
      async onMutate(
        newGroup: CreateGroupPayload
      ): Promise<CreateGroupContext> {
        await queryClient.cancelQueries([getGroupsKey, licenseId])

        let previousGroups: Group[] = queryClient.getQueryData([
          getGroupsKey,
          licenseId,
        ])

        queryClient.setQueryData([getGroupsKey, licenseId], (old: Group[]) =>
          old.map((group: Group) => {
            if (group.id === newGroup.groupId) {
              return {
                ...newGroup,
                name: newGroup.groupName,
                id: newGroup.groupId,
              }
            }
            return group
          })
        )

        return {previousGroups}
      },
      onError(_err, _newGroup, context: CreateGroupContext) {
        queryClient.setQueryData(
          [getGroupsKey, licenseId],
          context.previousGroups
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getGroupsKey, licenseId])
      },
    }
  )
}
