import {Skeleton} from '@mui/material'

export function SkeletonFormField() {
  return (
    <Skeleton
      variant='rectangular'
      width='100%'
      height='48px'
      animation='wave'
      sx={{
        marginBottom: '16px',
        mt: 1,
      }}
    />
  )
}
