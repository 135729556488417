import {Box, Divider, Paper, Skeleton, Stack, Theme} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'

type ExistingItemSkeletonProps = {
  numberOfItems?: number
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      hoverActive: {
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: theme.palette.grey[100],
        },
      },
      card: {
        width: '100%',

        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
      },
      text: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
      cardHeader: {
        width: '100%',
        display: 'flex',
        borderRadius: '4px',
        alignItems: 'center',
        cursor: 'pointer',
        minHeight: '48px',
      },
      nameAndStatus: {
        display: 'flex',
        flexDirection: 'row',
        width: '400px',
        marginRight: theme.spacing(4),
        alignItems: 'center',
      },
      id: {
        color: 'gray',
        textAlign: 'right',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'left',
        },
      },
      default: {
        paddingRight: theme.spacing(2),
      },
    }),
  {classNamePrefix: 'ExistingItem'}
)

export function ExistingItemSkeleton(props: ExistingItemSkeletonProps) {
  const {numberOfItems} = props
  const classes = useStyles()

  return (
    <Stack direction='column'>
      {Array.from(Array(numberOfItems || 10).keys()).map((_, index) => (
        <Box key={index + 'skeleton - box'}>
          <Paper
            key={index + 'skeleton'}
            elevation={0}
            square
            className={classes.card}
            sx={{
              position: 'relative',
              '::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 1,
                width: '4px',
                height: '100%',
              },
            }}
          >
            <div className={classes.cardHeader}>
              <div className={classes.text}>
                <Stack direction='row' alignItems='center'>
                  <Skeleton
                    variant='text'
                    width={200}
                    height={28}
                    animation='wave'
                    sx={{
                      fontSize: '28px',
                    }}
                  />
                </Stack>
                <Skeleton
                  variant='text'
                  width={200}
                  height={14}
                  animation='wave'
                  sx={{
                    fontSize: '12px',
                    mr: 8,
                  }}
                />
              </div>
            </div>
          </Paper>
          <Divider key={index + 'divider'} />
        </Box>
      ))}
    </Stack>
  )
}

// export function ExistingItemSkeleton(props: ExistingItemSkeletonProps) {
//   const {numberOfItems} = props

//   const numberOfSkeletons = numberOfItems || 10
//   return (
//     <Stack direction={'column'} gap={0.5}>
//       {Array.from(Array(numberOfSkeletons).keys()).map((_, index) => (
//         <Skeleton
//           variant='rectangular'
//           //wavey
//           animation='wave'
//           width={'100%'}
//           height={'60px'}
//           key={index}
//         />
//       ))}
//     </Stack>
//   )
// }
