import {ArrowDropDown} from '@mui/icons-material'
import {
  Stack,
  Typography,
  Button,
  Popover,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
} from '@mui/material'
import {useState, useRef} from 'react'

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'dsc',
}
export type SortColumn = {
  label: string
  value: string
}

export function SortControls({
  sortValue,
  onSortValueChange,
  sortDirection,
  onSortDirectionChange,
  sortColumns,
}: {
  sortValue: string
  onSortValueChange: (value: string) => void
  sortDirection: SortDirection
  onSortDirectionChange: (direction: SortDirection) => void
  sortColumns: SortColumn[]
}) {
  const [isOpen, setIsOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>()
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setIsOpen(false)
    }
  }
  return (
    <Stack direction='row' alignItems='center'>
      <Stack direction='row' alignItems='baseline'>
        <Typography variant='body2'>Sort by:</Typography>
        <Button
          ref={anchorRef}
          color='secondary'
          onClick={e => {
            e.stopPropagation()
            if (sortColumns.length > 1) setIsOpen(open => !open)
          }}
          sx={{
            ml: 1,
            color: 'palette.secondary.main !important',
            opacity: '1 !important',
          }}
          aria-haspopup='true'
          size='small'
          data-testid='open-overflow-menu'
        >
          {sortColumns.filter(s => s.value === sortValue)[0].label}
        </Button>
      </Stack>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        anchorEl={anchorRef.current}
      >
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <MenuList
            autoFocusItem={isOpen}
            id='menu-list-grow'
            onKeyDown={handleListKeyDown}
          >
            {sortColumns.map(column => (
              <MenuItem
                onClick={e => {
                  onSortValueChange(column.value)
                  setIsOpen(false)
                }}
                key={column.value}
                value={column.value}
              >
                {column.label}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Popover>

      <IconButton
        onClick={() => {
          let newSortDirection =
            sortDirection === SortDirection.Ascending
              ? SortDirection.Descending
              : SortDirection.Ascending
          onSortDirectionChange(newSortDirection)
        }}
      >
        <ArrowDropDown
          sx={{
            transform:
              sortDirection === SortDirection.Ascending
                ? 'rotate(0deg)'
                : 'rotate(180deg)',
            transition: 'transform 0.2s ease-in-out',
          }}
        />
      </IconButton>
    </Stack>
  )
}
