import CustomDialog from '../../../shared/dialog/dialog'
import {
  Box,
  TextField,
  Typography,
  Stack,
  Checkbox,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import {useEffect, useState} from 'react'
import {useGetAppSettings} from '../../../../../hooks'
import {ExportUsersProgressBar} from './export-users-progress-bar'

function ExportUsersDialog(props: ExportUsersDialogProps) {
  const {
    open,
    onClose,
    users,
    allUsers,
    loading,
    handleExport,
    defaultFileName,
    isCurrentUserOwner,
  } = props

  const [fileName, setFileName] = useState(defaultFileName)

  const [includeOwner, setIncludeOwner] = useState(false)
  const [includeCurrentUser, setIncludeCurrentUser] = useState(false)
  const [includeCreationDate, setIncludeCreationDate] = useState(false)
  const [includeReports, setIncludeReports] = useState(false)
  const [alignment, setAlignment] = useState('some')

  const illegalCharacters = ['/', '\\', ':', '*', '?', '"', '<', '>', '|']
  const {data: appSettings} = useGetAppSettings()

  const isIllegal = illegalCharacters.some(char => fileName.includes(char))
  const isTooLong = fileName.length > 20
  const isEmpty = fileName.length === 0

  if (fileName.includes(' ')) setFileName(fileName.replace(' ', '_'))

  const [numberOfUsers, setNumberOfUsers] = useState(0)

  useEffect(() => {
    setNumberOfUsers(
      (alignment === 'all' ? allUsers.length : users.length) +
        (includeOwner ? 1 : 0) +
        (includeCurrentUser ? 1 : 0)
    )
  }, [users, includeOwner, includeCurrentUser, alignment, allUsers])

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment)

    if (newAlignment === 'all') {
      setIncludeOwner(!isCurrentUserOwner && appSettings.payPalEnabled)
      setIncludeCurrentUser(true)
      return
    }
  }

  const errorMessage = isIllegal
    ? 'File name cannot contain any of the following characters: / \\ : * ? " < > |'
    : (isTooLong && 'File name cannot be longer than 20 characters') ||
      (isEmpty && 'File name cannot be empty') ||
      ''

  const handleOnClose = () => {
    setFileName(defaultFileName)
    setIncludeCreationDate(false)
    setIncludeReports(false)
    setIncludeOwner(false)
    setIncludeCurrentUser(false)
    onClose()
  }

  return (
    <CustomDialog
      open={open}
      onClose={() => {
        handleOnClose()
      }}
      allowEscapeKeyClose
      allowBackdropClickClose
      // loading={loading}
      title={`Download ${numberOfUsers} user${numberOfUsers > 1 ? 's' : ''}?`}
      primaryButtonProps={{
        children: 'Download',
        disabled: loading,
        onClick: async () => {
          await handleExport(
            fileName,
            includeOwner,
            includeCurrentUser,
            includeCreationDate,
            includeReports,
            alignment === 'all'
          )
          handleOnClose()
        },
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: () => {
          setFileName(defaultFileName)
          handleOnClose()
        },
      }}
      error={errorMessage}
    >
      <Box>
        <Stack direction='column'>
          <ExportUsersProgressBar
            userCount={numberOfUsers}
            reports={includeReports}
            loading={loading}
          />
          <ToggleButtonGroup
            color='primary'
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label='Platform'
            sx={{
              mb: 2,
              mt: 1,
              height: '40px',
              //change the text
            }}
          >
            <ToggleButton value='some' color={'secondary'}>
              Selected Users
            </ToggleButton>
            <ToggleButton value='all' color={'secondary'}>
              All Users
            </ToggleButton>
          </ToggleButtonGroup>

          <Stack direction='row' alignItems='center' sx={{height: '70px'}}>
            <TextField
              label='File Name'
              value={fileName}
              onChange={e => setFileName(e.target.value)}
            />
            <Typography variant='h6'>.csv</Typography>
          </Stack>
          <OptionalFields
            {...{
              isCurrentUserOwner,
              setIncludeOwner,
              setIncludeCurrentUser,
              setIncludeCreationDate,
              setIncludeReports,
              includeOwner,
              includeCurrentUser,
              includeCreationDate,
              includeReports,
            }}
          />
        </Stack>
      </Box>
    </CustomDialog>
  )
}

type OptionalFieldsProps = {
  isCurrentUserOwner: boolean
  setIncludeOwner: (includeOwner: boolean) => void
  setIncludeCurrentUser: (includeCurrentUser: boolean) => void
  setIncludeCreationDate: (includeCreationDate: boolean) => void
  setIncludeReports: (includeReports: boolean) => void
  includeOwner: boolean
  includeCurrentUser: boolean
  includeCreationDate: boolean
  includeReports: boolean
}

function OptionalFields(props: OptionalFieldsProps) {
  const {
    isCurrentUserOwner,
    setIncludeOwner,
    setIncludeCurrentUser,
    setIncludeCreationDate,
    setIncludeReports,
    includeCurrentUser,
    includeCreationDate,
    includeReports,
    includeOwner,
  } = props
  const {data: appSettings} = useGetAppSettings()

  return (
    <>
      {!isCurrentUserOwner && appSettings.payPalEnabled && (
        <OptionalCheckbox
          onChange={setIncludeOwner}
          text='Include Owner'
          checked={includeOwner}
        />
      )}
      <OptionalCheckbox
        onChange={setIncludeCurrentUser}
        text='Include Myself'
        checked={includeCurrentUser}
      />
      <OptionalCheckbox
        onChange={setIncludeCreationDate}
        text='Include Creation Date'
        checked={includeCreationDate}
      />
      <OptionalCheckbox
        onChange={setIncludeReports}
        text='Include Reports'
        checked={includeReports}
      />
    </>
  )
}

type OptionalCheckboxProps = {
  onChange: (include: boolean) => void
  text: string
  checked: boolean
}
function OptionalCheckbox(props: OptionalCheckboxProps) {
  const {onChange, text, checked} = props

  return (
    <Stack direction='row' alignItems='center'>
      <Checkbox
        onChange={e => {
          onChange(e.target.checked)
        }}
        size='small'
        checked={checked}
        sx={{
          p: 0,
          mr: 1,
        }}
      />
      <Typography fontSize={12}>{text}</Typography>
    </Stack>
  )
}

type ExportUsersDialogProps = {
  open: boolean
  onClose: () => void
  handleExport: (
    fileName: string,
    includeOwner: boolean,
    includeCurrentUser: boolean,
    includeCreationDate: boolean,
    includeReports: boolean,
    includeAll: boolean
  ) => void
  users: string[]
  allUsers: string[]
  loading?: boolean
  defaultFileName: string
  isCurrentUserOwner: boolean
}

export default ExportUsersDialog
