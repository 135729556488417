import {
  Box,
  Dialog,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'

export function StepsDialog(props: StepsDialogProps) {
  const {isOpen, onClose, children, steps, activeStep} = props

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={false} // Disable the default max width to allow custom sizing
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px',
        width: '90%', // Set dialog width to 90% of the viewport width
        height: '90%', // Set dialog height to 90% of the viewport height
        maxWidth: 'none !important', // Ensure max width is disabled
        maxHeight: 'none !important', // Ensure max height is disabled
      }}
    >
      <Stack
        direction='column'
        sx={{
          p: 4,
          width: '100%', // Ensure the Stack takes full width
          height: '100%', // Ensure the Stack takes full height
        }}
      >
        <Stepper
          activeStep={activeStep}
          sx={{
            padding: 0,
            marginBottom: theme => theme.spacing(4),
            //set the width of the stepper to 100% of the parent container
            minWidth: '500px',
          }}
        >
          {steps.map(step => (
            <Step key={step.label}>
              <StepLabel
                icon={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      backgroundColor:
                        steps.indexOf(step) === activeStep
                          ? 'secondary.main'
                          : '#e0e0e0',
                      color: theme => theme.palette.primary.contrastText,
                    }}
                  >
                    {step.icon}
                  </Box>
                }
              >
                <Typography
                  variant='body1'
                  sx={{color: 'black'}}
                  fontSize='14px'
                  fontWeight={
                    steps.indexOf(step) === activeStep ? 'bold' : 'normal'
                  }
                >
                  {step.label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {children}
      </Stack>
    </Dialog>
  )
}

export type DialogStep = {
  label: string
  icon: React.ReactElement
}

export type StepsDialogProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactElement
  steps: DialogStep[]
  activeStep: number
}
