import cs from 'classnames'
import {
  Typography,
  Theme,
  Stack,
  Box,
  IconButton,
  Tooltip,
  Paper,
  alpha,
  Popover,
  Icon,
  TextField,
  Divider,
  Button,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined' // Paginated report icon
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined' //Custom report builder icon
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined' // Regular report icon
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { useState } from 'react'
import { Folder } from '../../../models'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useRoutes } from '../../../hooks'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      borderRadius: '8px',
      backgroundColor: '#f5f3f3',
      padding: theme.spacing(2),
      // '&:hover': {
      //   backgroundColor: alpha(theme.palette.primary.main, 0.2),
      // },
      '&:hover $reportTileButtons': {
        opacity: 1,
        visibility: 'visible !important',
      },

      '&:hover $overlay': {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },

      alignItems: 'flex-start',
      height: '100%',
      paddingRight: theme.spacing(1),
    },

    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      zIndex: 1,
      transition: 'background-color 0.3s',
      borderRadius: '8px',
    },

    reportTileButtons: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: '4px',
      zIndex: 2,
      // zIndex: 2000,
      visibility: 'hidden',
      opacity: 1,
      transition: 'visibility 0.3s, opacity 0.3s',
    },

    actions: {
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      height: '100%',
      // paddingBottom: theme.spacing(1) + 'px!important',
      paddingRight: theme.spacing(1),
    },
    bottomContent: {
      display: 'flex',
      flexGrow: 1,
    },
    description: {
      flexGrow: 1,
    },
    openBtn: {
      alignSelf: 'flex-end',
    },
  }),
  { name: 'ReportTile' }
)

export function ReportTile(props: ReportTileProps) {
  const {
    title,
    description,
    type,
    isFavorite,
    handleFavoritesClicked,
    handleAddToFolder,
    folders,
  } = props
  // const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const classes = useStyles()
  const routes = useRoutes()

  const [tempFavorite, setTempFavorite] = useState(isFavorite)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleFolderClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleFolderClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  function ReportTileCircle() {
    return (
      <Box
        sx={{
          borderRadius: '50%',
          backgroundColor: '#e9e7e8',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '80px',
          height: '80px',
        }}
      >
        {type === 'custom' ? (
          <DashboardCustomizeOutlinedIcon />
        ) : type === 'paginated' ? (
          <TableChartOutlinedIcon />
        ) : (
          <BarChartOutlinedIcon />
        )}
      </Box>
    )
  }

  function AddToFolderOptions() {
    const [isAddingFolder, setIsAddingFolder] = useState(false)

    const [newFolderName, setNewFolderName] = useState('')

    return (
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='flex-start'
        spacing={1}
        sx={{ p: 1, maxHeight: '280px', overflowY: 'auto', minWidth: '240px' }}
      >
        <Typography variant='body2' fontWeight='bold' sx={{ pl: 1 }}>
          Add to Folder
        </Typography>
        <Divider
          sx={{
            width: '100%',
          }}
        />
        <Stack
          onClick={() => {
            setIsAddingFolder(true)
          }}
          direction={'row'}
          justifyContent={'flex-start'}
          sx={{
            cursor: 'pointer',
            borderRadius: '8px',
            width: '100%',
            p: 1,
            '&:hover': {
              backgroundColor: theme => alpha(theme.palette.primary.main, 0.2),
            },
            border: theme =>
              !isAddingFolder
                ? `1px dashed ${theme.palette.primary.main}`
                : 'none',
          }}
        >
          {isAddingFolder ? (
            <>
              <TextField
                autoFocus
                label='Folder Name'
                variant='standard'
                size='small'
                sx={{ width: '100%' }}
                value={newFolderName}
                onChange={e => setNewFolderName(e.target.value)}
                //if enter key is pressed, add folder
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setIsAddingFolder(false)
                    handleAddToFolder('', newFolderName)
                    handleFolderClose()
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <Typography
                      onClick={() => {
                        setIsAddingFolder(false)
                        handleAddToFolder('', newFolderName)
                        handleFolderClose()
                      }}
                      sx={{
                        fontSize: '14px',
                        mb: 1,
                        cursor: 'pointer',
                        color: theme => theme.palette.primary.main,
                        '&:hover': { textDecoration: 'underline' },
                      }}
                    >
                      Confirm
                    </Typography>
                  ),
                }}
              />
            </>
          ) : (
            <>
              <Typography
                fontStyle={'italic'}
                sx={{
                  cursor: 'pointer',
                }}
              >
                Create New
              </Typography>
              <Icon>
                <AddOutlinedIcon />
              </Icon>
            </>
          )}
        </Stack>

        {folders?.map((folder, index) => (
          <Box
            key={index}
            onClick={() => {
              handleAddToFolder(folder.id)
              handleFolderClose()
            }}
            sx={{
              cursor: 'pointer',
              width: '100%',
              borderRadius: '8px',
              p: 1,
              '&:hover': {
                backgroundColor: theme =>
                  alpha(theme.palette.primary.main, 0.2),
              },
            }}
          >
            <Typography>{folder.name}</Typography>
          </Box>
        ))}
      </Stack>
    )
  }

  function Overlay() {
    return <Button className={cs(classes.overlay)} />
  }

  return (
    <Paper
      className={cs(classes.root)}
      elevation={2}
      onClick={() => {
        window.location.href = routes.reports.report({ id: props.id })
      }}
    >
      <Overlay />
      <Stack direction='row' className={classes.reportTileButtons}>
        <Tooltip
          title={tempFavorite ? 'Unfavorite' : 'Favorite'}
          placement='top'
        >
          <IconButton
            aria-label='favorite'
            size='small'
            sx={{
              color: tempFavorite && 'red',
            }}
            onClick={e => {
              e.stopPropagation()
              setTempFavorite(!tempFavorite)
              handleFavoritesClicked()
            }}
          >
            <FavoriteBorderIcon
              sx={{
                fontSize: '20px',
              }}
            />
          </IconButton>
        </Tooltip>

        <Tooltip title='Add To Folder' placement='top'>
          <IconButton
            aria-label='add-to-folder'
            size='small'
            onClick={e => {
              e.stopPropagation()
              handleFolderClick(e)
            }}
          >
            <FolderOutlinedIcon
              sx={{
                fontSize: '20px',
              }}
            />
          </IconButton>
        </Tooltip>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleFolderClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          slotProps={{
            paper: { sx: { borderRadius: '8px' } },
          }}
        >
          <AddToFolderOptions />
        </Popover>
      </Stack>

      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        gap={2}
      >
        <ReportTileCircle />
        <Stack direction='column' justifyContent='center'>
          <Stack
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            sx={{ width: '100%', mb: description ? 0 : 2 }}
          >
            <Typography
              gutterBottom
              variant='body2'
              fontWeight={'bold'}
              component='h2'
              display={'inline'}
            >
              {title}{' '}
            </Typography>
          </Stack>
          <Box className={classes.bottomContent}>
            <Typography
              variant='body2'
              color='textSecondary'
              component='p'
              className={classes.description}
            >
              {description}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  )
}

type CardTypeBarProps = {
  type?: string
}

export function CardTypeBar(props: CardTypeBarProps) {
  const { type } = props

  if (!type || type.length === 0) return null

  const typeName = type.toUpperCase()

  return (
    <Stack
      direction='row'
      spacing={1}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: theme => getTypeColor(theme, type),
        opacity: 0.9,
        width: 'fit-content',
        px: 0.6,
        pt: 0.2,
        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)',
        borderRadius: '0px 0px 0px 8px',
        zIndex: 1,
        height: '20px',
      }}
    >
      <Typography
        color='textSecondary'
        fontSize={11}
        sx={{
          color: 'white',
        }}
      >
        {typeName}
      </Typography>
    </Stack>
  )
}

// function getTypeName(type: string) {
//   switch (type) {
//     case 'custom':
//       return 'Custom Report Builder'
//     case 'paginated':
//       return 'Custom Report'
//     default:
//       return 'Custom'
//   }
// }

function getTypeColor(theme: Theme, type: string) {
  switch (type) {
    case 'custom':
      return theme.palette.primary.main
    case 'paginated':
      return theme.palette.primary.light
    default:
      return theme.palette.primary.main
  }
}

type ReportTileProps = {
  title: string
  description: string
  id: string
  type?: string
  isFavorite?: boolean
  handleFavoritesClicked: () => void
  folders: Folder[]
  handleAddToFolder: (folderId: string, newFolderName?: string) => void
}
