import {useMutation, useQueryClient} from 'react-query'
import {useRecoilValue} from 'recoil'
import {reportApi} from '../../api-interface'
import {ApiReport, GetAllReportsForUserResponse} from '../../models'
import {licenseAtom} from '../../state'
import {getReportsByUserKey} from '../queries'

export const removeReportFromUserKey = 'remove-report-from-user'

export type RemoveReportFromUserPayload = {reportId: string; viewerId: string}

/** Remove a viewer from a PBI report */
export function useRemoveReportFromViewer() {
  const licenseId = useRecoilValue(licenseAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: RemoveReportFromUserPayload) =>
      reportApi.removeViewers(payload.reportId, payload.viewerId, licenseId),
    {
      async onMutate(
        payload: RemoveReportFromUserPayload
      ): Promise<GetAllReportsForUserResponse> {
        await queryClient.cancelQueries([getReportsByUserKey, payload.viewerId])

        let previousReports: GetAllReportsForUserResponse =
          queryClient.getQueryData([getReportsByUserKey, payload.viewerId])

        if (!previousReports) {
          previousReports = await queryClient.fetchQuery(
            [getReportsByUserKey, payload.viewerId],
            () => reportApi.getReportsByUser(licenseId, payload.viewerId)
          )
        }

        queryClient.setQueryData(
          [getReportsByUserKey, payload.viewerId],
          (old: GetAllReportsForUserResponse) => {
            const userReports = old.reportsFromUser.filter(
              (oldReport: ApiReport) => oldReport.id !== payload.reportId
            )
            return {
              reportsFromGroups: old.reportsFromGroups,
              reportsFromUser: userReports,
            }
          }
        )

        return previousReports
      },
      onError(
        _err,
        payload: RemoveReportFromUserPayload,
        context: GetAllReportsForUserResponse
      ) {
        queryClient.setQueryData(
          [getReportsByUserKey, payload.viewerId],
          context
        )
      },
    }
  )
}
