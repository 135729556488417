import { Collapse } from '@mui/material'
import { RlsFields } from '../rls-fields'
import { FormField, StatusMessage } from '../../../shared'
import { AccessLoadingBar } from './access-loading-bar'
import { SkeletonFormField } from './skeleton-form-field'
import {
  ApiReport,
  ReportConnectionStatus,
  StatusMessageInfo,
} from '../../../../../models'
import { createStyles, makeStyles } from '@mui/styles'
import { ProfileAndAppReg } from './types'
import { useEffect, useState } from 'react'
import {
  useGetAppRegs,
  useTestReportConnection,
  useGetWorkspaceReports,
  useGetProfileWorkspaces,
} from '../../../../../hooks'
import { connectionStatusInfo } from '../test-connection-responses'
import { useAddProfileToWorkspace } from '../../../../../hooks/mutations/useAddProfileToWorkspace'

export type SelectAndAuthenticateReportProps = {
  report: ApiReport
  setReport: (report: ApiReport) => void
  profile: ProfileAndAppReg
  setProfile: (profile: ProfileAndAppReg) => void
  status: StatusMessageInfo | null
  setStatus: (status: StatusMessageInfo | null) => void
  isGrantingAccess: boolean
  setIsGrantingAccess: (value: boolean) => void
  isEdit?: boolean
}

const useStyles = makeStyles(
  () =>
    createStyles({
      statusMessage: {
        width: '100%',
      },
      rlsSection: {
        width: '100%',
      },
    }),
  { name: 'add-report-form' }
)

export function SelectAndAuthenticateReport(
  props: SelectAndAuthenticateReportProps
) {
  const {
    report,
    setReport,
    profile,
    setProfile,
    status,
    setStatus,
    isGrantingAccess,
    setIsGrantingAccess,
  } = props

  const classes = useStyles()

  const { mutateAsync: testReportConnection, isLoading: isTestingConnection } =
    useTestReportConnection()
  const { data: existingAppRegistrations, isLoading: loadingAppRegs } =
    useGetAppRegs()
  const {
    data: workspaces,
    isLoading: loadingWorkspaces,
    refetch: refetchProfileWorkspaces,
    isRefetching: isRefetchingProfileWorkspaces,
  } = useGetProfileWorkspaces(profile?.appRegNodeId)
  const {
    mutateAsync: addProfileToWorkspace,
    isLoading: isAddingProfile,
    isError,
  } = useAddProfileToWorkspace()

  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState('')

  const {
    data: workspaceReports,
    isLoading: loadingWorkspaceReports,
    refetch: refetchWorkspaceReports,
    isRefetching: isRefetchingWorkspaceReports,
  } = useGetWorkspaceReports(
    profile?.appRegNodeId,
    profile?.appRegId,
    profile?.tenantId,
    report?.pbiWorkspaceId
  )
  const [existingProfiles, setExistingProfiles] = useState<ProfileAndAppReg[]>(
    []
  )

  const [isRlsRequired, setIsRlsRequired] = useState(false)

  async function testConnection() {
    if (!report.pbiReportId || !report.pbiWorkspaceId || !profile) return
    let status = await testReportConnection({
      pbiWorkspaceId: report.pbiWorkspaceId,
      pbiReportId: report.pbiReportId,
      appRegistrationNodeId: profile.appRegNodeId,
      profileId: profile.id,
    })

    setStatus(connectionStatusInfo[status])

    const rlsRequired = status === ReportConnectionStatus.RowLevelSecurity
    setIsRlsRequired(rlsRequired)

    if (rlsRequired && !report.rls) {
      setReport({
        ...report,
        rls: { roles: [''], username: 'email' },
      })
    }
  }

  useEffect(() => {
    if (profile) {
      refetchProfileWorkspaces()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  useEffect(() => {
    if (!existingAppRegistrations) return

    let profiles: ProfileAndAppReg[] = []
    existingAppRegistrations.forEach(appReg => {
      appReg.profiles.forEach(p => {
        profiles.push({
          ...p,
          appRegId: appReg.appRegistrationId,
          appRegName: appReg.name,
          appRegNodeId: appReg.id,
          tenantId: appReg.tenantId,
        })
      })
    })
    setExistingProfiles(profiles)
  }, [existingAppRegistrations])

  useEffect(() => {
    const workspaceName = workspaces?.find(
      ws => ws.id === report.pbiWorkspaceId
    )?.name
    setSelectedWorkspaceName(workspaceName)
  }, [workspaces, report.pbiWorkspaceId])

  useEffect(() => {
    refetchWorkspaceReports()
    setStatus(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspaceName, profile])

  useEffect(() => {
    if (profile && report.pbiReportId) {
      testConnection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, report?.pbiWorkspaceId, report?.pbiReportId])

  const ConnectionProfileFormField = () => {
    if (loadingAppRegs) {
      return <SkeletonFormField />
    }

    return (
      <FormField
        label={
          !existingProfiles || existingProfiles.length === 0
            ? 'No profiles found'
            : 'Select Connection Profile'
        }
        value={profile?.name || ''}
        onTextChange={value => {
          setStatus(null)
          setIsRlsRequired(false)
          let selectedProfile = existingProfiles?.find(
            profile => profile?.name === value
          )
          if (!selectedProfile) return
          setProfile(selectedProfile)
        }}
        disabled={!existingProfiles || existingProfiles.length === 0}
        selectOptions={existingProfiles?.map(profile => profile?.name) || []}
      />
    )
  }

  const reportName =
    workspaceReports?.find(r => r.id === report.pbiReportId)?.name || ''

  const WorkspaceReportsFormField = () => {
    if (loadingWorkspaceReports || isRefetchingWorkspaceReports) {
      return <SkeletonFormField />
    }

    return (
      <FormField
        label='Report'
        value={reportName || ''}
        helperText=''
        disabled={!selectedWorkspaceName || !profile}
        selectOptions={workspaceReports?.map(r => r.name) || []}
        onTextChange={value => {
          const selectedReport = workspaceReports?.find(r => r.name === value)
          if (!selectedReport) return
          const updatedReport = {
            ...report,
            name: selectedReport.name,
            displayName: selectedReport.name,
            url: selectedReport.webUrl,
            pbiReportId: selectedReport.id,
            pbiReportName: selectedReport.name,
          }
          setReport(updatedReport)
        }}
      />
    )
  }

  const WorkspaceFormField = () => {
    if (loadingWorkspaces || isRefetchingProfileWorkspaces) {
      return <SkeletonFormField />
    }

    return (
      <FormField
        label='Workspace'
        disabled={!profile && !selectedWorkspaceName}
        value={selectedWorkspaceName || ''}
        helperText=''
        selectOptions={workspaces?.map(ws => ws.name) || []}
        onTextChange={value => {
          const selectedWorkspace = workspaces?.find(ws => ws.name === value)
          if (!selectedWorkspace) return
          setSelectedWorkspaceName(selectedWorkspace.name)
          setReport({
            ...report,
            pbiWorkspaceId: selectedWorkspace.id,
            name: '',
            pbiReportId: '',
          })
        }}
      />
    )
  }

  return (
    <>
      <ConnectionProfileFormField />
      <WorkspaceFormField />
      <WorkspaceReportsFormField />
      {!isError && (
        <AccessLoadingBar
          isGrantingAccess={isGrantingAccess}
          isTestingConnection={isTestingConnection}
          isAddingProfile={isAddingProfile}
        />
      )}

      {profile &&
        selectedWorkspaceName &&
        report.pbiReportId &&
        ((!isGrantingAccess && !isTestingConnection) || isError) &&
        workspaceReports?.find(r => r.id === report.pbiReportId) && (
          <Collapse className={classes.statusMessage} in={!!status || isError}>
            <StatusMessage
              status={
                status || {
                  type: 'error',
                  message:
                    'The app registration and/or profile does not have access to the workspace',
                  title: 'Unauthorized',
                }
              }
              selfFix={{
                isError: isError,
                callback: async () => {
                  setIsGrantingAccess(true)
                  setStatus(null)

                  await addProfileToWorkspace({
                    profileId: profile.id,
                    workspaceId: report.pbiWorkspaceId,
                    appRegistrationNodeId: profile.appRegNodeId,
                    appClientId: profile.appRegId,
                    appRegistrationTenantId: profile.tenantId,
                  })
                  setIsGrantingAccess(false)
                  await testConnection()
                },
                isLoading: isAddingProfile,
              }}
            />
          </Collapse>
        )}
      <Collapse className={classes.rlsSection} in={isRlsRequired}>
        <RlsFields
          roles={report?.rls?.roles}
          username={report?.rls?.username}
          onRolesChange={r => {
            setReport({ ...report, rls: { roles: r, username: 'email' } })
          }}
        />
      </Collapse>
    </>
  )
}
