import React, {useState} from 'react'
import {
  Button,
  IconButton,
  List,
  ListItem,
  Theme,
  Typography,
  Stack,
  Icon,
  alpha,
} from '@mui/material'
import {createStyles, makeStyles} from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import {Form} from '../../../shared'
import {HelpDialogBox} from './user-dialog-box'
import {parseFiles} from './parse-files'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {AddUserContentProps} from '../add-user-form'
import {useCreateUser, useGetGroups} from '../../../../../hooks'
import {useAddUsersToGroup} from '../../../../../hooks/mutations/useAddUsersToGroup'
import {BulkUserProgressBar} from './bulk-user-progress-bar'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      dropZone: {
        width: '100%',
        overflowY: 'auto',
        backgroundColor: alpha(`${theme.palette.primary.main}`, 0.1),
        border: 'dashed rgba(0,0,0,0.2) 1px',
        borderRadius: '4px',
      },
      fileListItem: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(1),
        borderRadius: 4,
      },
    }),
  {name: 'add-user-file'}
)

function mapFileList(fileList: FileList): File[] {
  let files: File[] = []
  for (let i = 0; i < fileList.length; i++) {
    let item = fileList.item(i)
    if (item.type === 'text/csv') {
      files.push(item)
    }
  }
  return files
}

export function AddBulkUsersForm(props: AddUserContentProps) {
  const {onClose, onSave, setToastOpen, setToastMessage, setToastVariant} =
    props
  // const {data: reports, isLoading: reportsLoading} = useGetReports()
  const classes = useStyles()
  const [isDragActive, setIsDragActive] = useState(false)
  const [filesToLoad, setFilesToLoad] = useState<File[]>([])
  // const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([])
  const {mutateAsync: addUsersToGroup, isLoading: isAddUsersToGroupSaving} =
    useAddUsersToGroup()
  const {data: groups} = useGetGroups()
  // const [reportsToLink, setReportsToLink] = useState<ApiReport[]>([])
  // useEffect(() => {
  //   if (reports?.licenseReports && reports?.licenseReports.length > 0)
  //     setReportsToLink(reports.licenseReports)
  // }, [reports])

  const {mutateAsync: createUser, isLoading: isSaving} = useCreateUser()

  const [bulkUsersSaving, setBulkUsersSaving] = useState(false)
  const [delayedBulkUsersSaving, setDelayedBulkUsersSaving] = useState(false)
  const [numUsers, setNumUsers] = useState(0)

  function onFilesDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault()
    setIsDragActive(false)
    // Use DataTransfer interface to access the file(s)
    const files = mapFileList(event.dataTransfer.files)

    setFilesToLoad(f => [...f, ...files])
  }

  function onFilesDrag(event: any) {
    event.preventDefault()
    setIsDragActive(true)
  }

  function handleFileClickUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const files = mapFileList(event.target.files)
    setFilesToLoad(f => [...f, ...files])
  }

  async function handleSave() {
    setBulkUsersSaving(true)
    setDelayedBulkUsersSaving(true)
    try {
      const usersWithGroups = await parseFiles(filesToLoad)
      const users = usersWithGroups.map(u => {
        return u.user
      })
      setNumUsers(users.length)
      const userGroupMap = new Map<string, string[]>() // Map< groupName, [userIds] >
      usersWithGroups.forEach(userWithGroup => {
        const groupNames = userWithGroup.groups.map(g => g.trim())
        const userId = userWithGroup.user.email
        groupNames.forEach(groupName => {
          const usersIdsToAdd = [...(userGroupMap.get(groupName) || []), userId]
          userGroupMap.set(groupName, usersIdsToAdd)
        })
      })
      const groupMapWithGroupIds = new Map<string, string[]>() // Map< groupId, [userIds] >
      userGroupMap.forEach((userIds, groupName) => {
        const groupId =
          groups?.find(g => g.name.toLowerCase() === groupName.toLowerCase())
            ?.id || ''
        if (groupId) {
          groupMapWithGroupIds.set(groupId, userIds)
        }
      })

      const data = {
        users: users,
        reportIds: [],
      }
      await createUser(data)

      const promises = []
      groupMapWithGroupIds.forEach((userIds, groupId) => {
        if (!groupId || userIds.length === 0) return
        promises.push(addUsersToGroup({groupId, userIds}))
      })

      await Promise.all(promises)
      //wait for 1.2 seconds to allow the progress bar to show completion
      setBulkUsersSaving(false)
      await new Promise(resolve => setTimeout(resolve, 1300))
      setDelayedBulkUsersSaving(false)

      setToastOpen(true)
      setToastMessage('Users added successfully')
      setToastVariant('success')
    } catch (e) {
      setToastOpen(true)
      setToastMessage('Error adding users: ' + e.message || 'Unknown error')
      setToastVariant('error')
      onClose()
      return
    }
    onSave()
    setNumUsers(0)
  }

  return (
    <Form
      onCancel={onClose}
      isSaving={
        isSaving ||
        isAddUsersToGroupSaving ||
        bulkUsersSaving ||
        delayedBulkUsersSaving
      }
      onSave={async () => {
        await handleSave()
      }}
    >
      {numUsers > 0 && (
        <BulkUserProgressBar
          loading={bulkUsersSaving}
          userCount={numUsers}
          reports={false}
        />
      )}
      <Stack
        sx={{
          opacity: isDragActive ? 0.6 : 1,
        }}
        className={classes.dropZone}
        alignItems='center'
        onDrop={onFilesDrop}
        onDragOver={onFilesDrag}
        width='100%'
        padding={2}
      >
        {filesToLoad.length < 1 ? (
          <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
              mb: 1,
              color: theme =>
                bulkUsersSaving
                  ? theme.palette.text.disabled
                  : theme.palette.text.primary,
            }}
            onClick={(e: any) => {
              if (bulkUsersSaving) {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
          >
            <Icon>
              <CloudUploadIcon fontSize='medium' />
            </Icon>
            <Typography variant='inherit' fontSize={16}>
              Drag and drop your files here
            </Typography>
            <Typography variant='inherit' fontSize={10} fontStyle='italic'>
              only .csv files are supported
            </Typography>
          </Stack>
        ) : (
          <List
            sx={{width: '100%'}}
            onClick={(e: any) => {
              if (bulkUsersSaving) {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
          >
            {filesToLoad.map((file, i) => (
              <ListItem key={file.name + i} className={classes.fileListItem}>
                {file.name}

                <IconButton
                  disabled={delayedBulkUsersSaving}
                  onClick={() => {
                    setFilesToLoad(
                      filesToLoad.filter(curFile => curFile.name !== file.name)
                    )
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}

        <Button component='label' disabled={delayedBulkUsersSaving}>
          Click to upload
          <input
            accept='.csv'
            multiple
            onChange={handleFileClickUpload}
            type='file'
            hidden
          />
        </Button>
      </Stack>

      <HelpDialogBox disabled={delayedBulkUsersSaving} />

      {/* {reportsLoading ? (
        <Skeleton
          variant='rectangular'
          width='100%'
          sx={{
            height: '64px !important',
          }}
          animation='wave'
        />
      ) : (
        <>
          {reportsToLink && reportsToLink.length > 0 && (
            <MultiSelect
              options={reportsToLink.map((r: ApiReport) => {
                return {id: r.id, name: r.name}
              })}
              inputLabel='Report(s)'
              helperText='Optionally assign new users to report(s)'
              onChange={setSelectedOptions}
              disabled={delayedBulkUsersSaving}
            />
          )}
        </>
      )} */}
    </Form>
  )
}
