import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined' // Paginated report icon
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined' //Custom report builder icon
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined' // Regular report icon
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import { Theme } from '@mui/material'
import { SxProps } from '@mui/system'

type FolderItemTypeToIconProps = {
  type: string
  sx?: SxProps<Theme>
}
export function FolderItemTypeToIcon(
  props: FolderItemTypeToIconProps
): JSX.Element {
  const { type, sx } = props
  const icon =
    type === 'report' ? (
      <BarChartOutlinedIcon sx={sx} />
    ) : type === 'bookmark' ? (
      <BookmarkBorderOutlinedIcon sx={sx} />
    ) : type === 'paginated' ? (
      <TableChartOutlinedIcon sx={sx} />
    ) : (
      <DashboardCustomizeOutlinedIcon sx={sx} />
    )

  return icon
}
