import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {groupApi} from '../../api-interface'
import {licenseAtom} from '../../state'

export const getGroupUsersKey = 'get-group-users'

export function useGetGroupUsers(groupId: string) {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery([getGroupUsersKey, groupId], () =>
    groupApi.getGroupUsers(groupId, licenseId)
  )
}
