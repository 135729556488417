import {useMutation, useQueryClient} from 'react-query'
import {profileApi} from '../../api-interface'
import {getAppRegistrationsKey} from '../queries'
import {Profile} from '../../models'
import {useRecoilValue} from 'recoil'
import {licenseAtom} from '../../state'

type DeleteProfileContext = {
  previousProfiles: Profile[]
}

type DeleteProfileProps = {
  profileId: string
  appRegistrationNodeId: string
  appClientId: string
  appRegistrationTenantId: string
}

export function useDeleteProfile() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    //payload is deleteprofileprops
    (payload: DeleteProfileProps) => {
      return profileApi.deleteProfile(
        payload.profileId,
        licenseId,
        payload.appRegistrationNodeId,
        payload.appClientId,
        payload.appRegistrationTenantId
      )
    },

    {
      async onMutate(
        payload: DeleteProfileProps
      ): Promise<DeleteProfileContext> {
        await queryClient.cancelQueries([getAppRegistrationsKey, licenseId])

        let previousProfiles: Profile[] = queryClient.getQueryData([
          getAppRegistrationsKey,
          licenseId,
        ])
        queryClient.setQueryData(
          [getAppRegistrationsKey, licenseId],
          (old: Profile[]) => {
            if (!old) return []

            return old?.filter(appReg => appReg.id !== payload.profileId)
          }
        )

        return {previousProfiles}
      },
      onError(_err, _newProfileId, context: DeleteProfileContext) {
        queryClient.setQueryData(
          [getAppRegistrationsKey, licenseId],
          context.previousProfiles
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getAppRegistrationsKey, licenseId])
      },
    }
  )
}
