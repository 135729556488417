import {useMutation, useQueryClient} from 'react-query'
import {reportApi} from '../../api-interface'
import {AllReports} from '../../models'
import {getReportsKey} from '../queries'

type CreateReportContext = {
  previousReports: AllReports
}

export function useCloneReport() {
  const queryClient = useQueryClient()

  return useMutation(reportApi.cloneReport, {
    async onMutate(cloneProps): Promise<CreateReportContext> {
      await queryClient.cancelQueries(getReportsKey)


      let previousReports: AllReports = await queryClient.getQueryData(
        getReportsKey
      )

      // Return the context passed on onError
      return {previousReports}
    },
    onError(err, newUser, context: CreateReportContext) {
      queryClient.setQueryData(getReportsKey, context.previousReports)
    },
    onSettled() {
      queryClient.invalidateQueries(getReportsKey)
    },
  })
}
