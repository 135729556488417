import {FC, useState} from 'react'
import {useCreateGroup} from '../../../../hooks/mutations/useCreateGroup'
import {useUpdateGroup} from '../../../../hooks/mutations/useUpdateGroup'
import {Group} from '../../../../models'
import {Form, FormField} from '../../shared'
import {useGetGroups} from '../../../../hooks'

export type GroupFormProps = {
  onClose: () => void
  editMode?: boolean
  group?: Group
}

const illegalCharsRegex = /[/\\?%*:|"<>.']/g

export const GroupForm: FC<GroupFormProps> = (props: GroupFormProps) => {
  const {onClose, editMode, group} = props

  const {mutateAsync: createGroup, isLoading: isSaving} = useCreateGroup()
  const {mutateAsync: updateGroup, isLoading: isUpdating} = useUpdateGroup()

  const {data: currentGroups} = useGetGroups()

  const [groupName, setGroupName] = useState<string>(group?.name || '')
  const [formError, setFormError] = useState<string>('')

  const groupNames = currentGroups?.map(g => g.name.toLowerCase())

  const onGroupNameChange = (name: string) => {
    setGroupName(name)

    //if group name contains any illegal characters, set error
    const illegalChars = name.match(illegalCharsRegex)
    if (illegalChars) {
      setFormError(
        'Name cannot contain any of the following characters: / \\ ? % * : | " \' < > .'
      )
      return
    }

    //if group name already exists, set error
    if (
      groupNames?.includes(name.trim().toLowerCase()) &&
      name !== group?.name.toLowerCase()
    ) {
      setFormError('Group name already exists')
      return
    }

    setFormError('')
  }

  const handleSave = async (): Promise<void> => {
    if (formError) return
    const trimmedGroupName = groupName.trim()

    if (!trimmedGroupName) {
      setFormError('Group name is required')
      return
    }

    editMode
      ? await updateGroup({groupName: trimmedGroupName, groupId: group.id})
      : createGroup({groupName: trimmedGroupName})

    onClose()
  }

  return (
    <Form
      error={formError}
      onSave={handleSave}
      onCancel={onClose}
      isSaving={isSaving || isUpdating}
    >
      <FormField
        label='Group name'
        onTextChange={onGroupNameChange}
        value={groupName}
      ></FormField>
    </Form>
  )
}
