import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {reportApi} from '../../api-interface'
import {licenseAtom} from '../../state'

export const getReportsByUserKey = 'get-reports-by-user'

export function useGetReportsByUser(userId: string) {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getReportsByUserKey, userId],
    () => reportApi.getReportsByUser(licenseId, userId),
    {
      enabled: !!licenseId,
    }
  )
}
