import { useEffect, useState, useRef } from 'react'
import { DataColumn } from '../../../../models'
import { DataColumnWithFilters } from '../custom-report-table'
import {
  Checkbox,
  debounce,
  Divider,
  Icon,
  MenuItem,
  Popover,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

type BasicFilterProps = {
  dataColumnWithFilters: DataColumnWithFilters
  getRowValues: (column: DataColumn, search?: string) => Promise<string[]>
  selectedValues: string[]
  setSelectedValues: (values: string[]) => void
  gettingRowValues: boolean
}

export function BasicFilter(props: BasicFilterProps) {
  const {
    dataColumnWithFilters,
    getRowValues,
    selectedValues,
    setSelectedValues,
    gettingRowValues,
  } = props

  const [rowValues, setRowValues] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [rowValuesToShow, setRowValuesToShow] = useState<string[]>([])
  const [valuesToShow, setValuesToShow] = useState<string[]>([])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const getUniqueRowValues = async (
    column: DataColumnWithFilters,
    search?: string
  ) => {
    const values = await getRowValues(column, search)
    setRowValues(values)
  }

  useEffect(() => {
    const debouncedSearch = debounce(async (value: string) => {
      await getUniqueRowValues(dataColumnWithFilters, value)
    }, 300)
    debouncedSearch(inputValue)
    // Clean up the debounce function
    return () => {
      debouncedSearch.clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, dataColumnWithFilters])

  useEffect(() => {
    getUniqueRowValues(dataColumnWithFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataColumnWithFilters])

  const inputRef = useRef<any>()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!inputRef?.current) return
      inputRef.current.focus()
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef, gettingRowValues, selectedValues, rowValues, inputValue])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setRowValuesToShow(gettingRowValues ? [] : rowValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowValues, gettingRowValues])

  useEffect(() => {
    setValuesToShow([
      ...rowValuesToShow,
      ...selectedValues?.filter(v => !rowValues.includes(v)),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowValuesToShow, selectedValues])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Stack direction={'column'}>
      <Typography fontSize={'12px'}>Values</Typography>
      <Stack
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          position: 'relative',
          border: theme => `1px solid ${theme.palette.text.primary}`,
          borderRadius: '4px',
          height: '32px',
          p: 1,
          '&:hover': {
            cursor: 'pointer',
            borderColor: theme => theme.palette.primary.main,
          },
        }}
        alignItems={'center'}
        justifyContent={'space-between'}
        direction={'row'}
      >
        {selectedValues.length === 0 ? (
          <Typography
            sx={{
              fontSize: '12px',
              color: theme => theme.palette.text.secondary,
              fontStyle: 'italic',
            }}
          >
            Select Values
          </Typography>
        ) : (
          <Typography
            fontSize={'14px'}
            sx={{
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '88%',
            }}
          >
            {selectedValues.join(', ')}
          </Typography>
        )}
        <Icon
          sx={{
            color: theme => theme.palette.primary.main,
            width: '12%',
            pb: 2,
            '&:hover': {
              cursor: 'pointer',
              color: theme => theme.palette.text.secondary,
            },
            //Don't rotate, flip on the y-axis
            transform: open ? 'scaleY(-1)' : 'scaleY(1)',

            transition: 'transform 0.3s',
          }}
        >
          <KeyboardArrowDownIcon />
        </Icon>
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack
          direction={'column'}
          sx={{ px: 2, pb: 2, maxHeight: 300, overflow: 'auto', width: 280 }}
        >
          <Stack
            direction='column'
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'white',
            }}
          >
            <TextField
              variant='standard'
              value={inputValue}
              onChange={onSearchChange}
              inputRef={inputRef}
              placeholder={'Search'}
              autoFocus
              fullWidth
              sx={{
                '& .MuiInputBase-root': { borderBottom: 0 },
                ml: 1,
                pt: 1,
                alignSelf: 'center',
                justifyContent: 'flex-start',
                display: 'flex',
                width: '100%',
              }}
              InputProps={{
                endAdornment: (
                  <SearchIcon
                    fontSize='small'
                    sx={{ color: theme => theme.palette.text.secondary }}
                  />
                ),
                disableUnderline: true,
              }}
            />
            <Divider />
          </Stack>
          {valuesToShow?.map(value => (
            <MenuItem
              key={value}
              value={value}
              sx={{
                p: 0,
              }}
              onClick={e => {
                const newSelectedValues = selectedValues.includes(value)
                  ? selectedValues.filter(v => v !== value)
                  : [...selectedValues, value]
                setSelectedValues(newSelectedValues)
              }}
            >
              <Checkbox
                key={value}
                value={value}
                checked={selectedValues.includes(value)}
              />
              {value}
            </MenuItem>
          ))}
          {gettingRowValues && <SkeletonListbox numItems={5} />}
        </Stack>
      </Popover>
    </Stack>
  )
}

function SkeletonListbox({ numItems = 5 }) {
  let numberOfItems = numItems < 0 ? 0 : numItems
  return (
    <>
      {Array.from({ length: numberOfItems }).map((_, i) => (
        <MenuItem key={i}>
          <Skeleton
            variant='text'
            width={'100%'}
            height='32px'
            sx={{
              ml: 1,
            }}
          />
        </MenuItem>
      ))}
    </>
  )
}
