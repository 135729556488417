import {useQuery} from 'react-query'
import {useRecoilValue} from 'recoil'
import {appRegApi} from '../../api-interface'
import {licenseAtom} from '../../state'

export const getAppRegistrationsKey = 'get-app-registrations'

export function useGetAppRegs() {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getAppRegistrationsKey, licenseId],
    () => appRegApi.getAppRegistrations(licenseId),
    {
      enabled: !!licenseId,
    }
  )
}
