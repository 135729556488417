import React from 'react'
import { Theme, Button, Stack, Box } from '@mui/material'
import { LoadingButton } from '../../../../../components'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    saveButton: {
      marginTop: theme.spacing(1),
    },
    cancelButton: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    formContent: {
      width: '100%',
      maxWidth: '1000px',
    },
    error: {
      width: '100%',
      color: 'red',
    },
    formBox: {
      minWidth: '300px',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
  })
)

export function ReportForm(props: FormProps) {
  const {
    children,
    onSave,
    isSaving,
    error,
    disableSave,
    confirmationButtonLabel,
    onBack,
    showBack,
  } = props
  const classes = useStyles()

  return (
    <Box className={classes.formBox}>
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault()
          onSave()
        }}
      >
        <div className={classes.formContent}>
          {children}
          {error && <div className={classes.error}>{error}</div>}
        </div>
      </form>
      <Stack
        direction='row'
        justifyContent={showBack ? 'space-between' : 'flex-end'}
        mt={2}
      >
        {showBack && (
          <Button className={classes.cancelButton} onClick={onBack}>
            Back
          </Button>
        )}

        <LoadingButton
          className={classes.saveButton}
          onClick={onSave}
          color='secondary'
          variant='contained'
          isLoading={isSaving}
          disabled={disableSave}
        >
          {confirmationButtonLabel ? confirmationButtonLabel : 'Save'}
        </LoadingButton>
      </Stack>
    </Box>
  )
}

export type FieldObject = {
  label: string
  value: string
  helperText: string
}

export type FormProps = {
  children: React.ReactNode
  isSaving?: boolean
  confirmationButtonLabel?: string
  onSave: () => void
  error?: string
  disableSave?: boolean
  onBack?: () => void
  showBack?: boolean
}
