// 1. GlobalStyles.js
import {createStyles, makeStyles} from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      html: {
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '*, *::before, *::after': {
        boxSizing: 'border-box',
      },
    },
  })
)

export function GlobalStyles() {
  useStyles()
  return null
}
